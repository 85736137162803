<div
  id="layout-megamenu"
  class="col-12 layout-megamenu fadeInDown"
  [ngClass]="{
    'layout-megamenu-active': appMain.megaMenuActive,
    'layout-megamenu-dark': app.megaMenuMode !== 'light',
    'layout-megamenu-gradient': app.megaMenuMode === 'gradient'
  }"
  (click)="appMain.onMegaMenuClick()"
>
  <div class="grid">
    @for (item of things; track item.id) {
      <div class="col-12 md:col-6 lg:col-4 layout-megamenu-submenu">
        <ul class="cursorPointer">
          <li [routerLink]="'/asset/' + item['type']" routerLinkActive="active">
            <a class="things-item" pRipple>
              @if (item?.icon) {
                <span>
                  <img [ngSrc]="item['icon']" alt="things-img" [ngClass]="{
                                                                                            'asset-icon-color': app.megaMenuMode !== 'light',
                                                                                            'light-asset-icon-color': app.megaMenuMode === 'light',
                                                                                            'icon-align': true
                                                                                          }" height="18" width="18" />
                </span>
              }@else{
                <i class="pi pi-circle"></i>
              }
              <p-badge [value]="item.count.toString()" styleClass="mr-1"/>
              <span>{{ item.label | translate }}</span> &nbsp;
            </a>
          </li>
        </ul>
      </div>
    }
  </div>
</div>
