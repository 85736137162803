import {createReducer, on} from '@ngrx/store';
import {TimelineActions} from '.';
import * as _ from 'lodash';
import {forEach} from 'lodash';
import moment from 'moment-timezone';
import {NamingConventionFilterPipe} from '../../pipes/namingConvention.pipe';
import {CONSTANT} from '../../config/constant';
import {TimelineData, TimelineEventData} from './interfaces/timeline.modal';


const MMD_MODULE_NAME = CONSTANT.DMS_MODULES.MMD.MODULE;


export interface TimelineSate {
  error: any,
  timelineEventData:Record<string,TimelineEventData[]>,
  timelineData: TimelineData[],
  timelineLoading: boolean,
  isTrackingEnabled: boolean,
  isDataFiltered: boolean,
  assignedCarrierData: any,

}

export const initialState = {
  timelineEventData: null,
  timelineData: null,
  error: null,
  timelineLoading: false,
  assignedCarrierData: null,

};

export const TimelineReducer = createReducer(
  initialState,

  on(TimelineActions.getTimelineContentDataSuccess, (state, {response}) => {
    const key = response?.[0]?.orderId || response?.[0]?.deliveryGrpId;
    const updatedEventData = {
      ...state.timelineEventData
    }
    if (key)
    updatedEventData[key] = response;
    return {
      ...state,
      timelineEventData:updatedEventData,
      timelineLoading: false,
    };
  }),

  on(TimelineActions.getTimelineContentDataFailure, (state, {error}) => {
    return {
      ...state,
      error: error,
      timelineLoading: false
    };
  }),

  on(TimelineActions.buildFullTimelineData, (state, {timelineAsset, trackingEnabled, timelineParam}) => {

    if (timelineParam?.type) {
      return {
        ...state,
        isDataFiltered: true,
        timelineData: buildFullTimelineContentArray(timelineAsset),
        timelineLoading: false,
        isTrackingEnabled: trackingEnabled,


      };
    } else {
      return {
        ...state,
        isDataFiltered: false,
        timelineData: buildFullTimelineContentArray(timelineAsset),
        timelineLoading: false,
        isTrackingEnabled: trackingEnabled,


      };
    }
  }),

  //   on(TimelineActions.buildFullTimelineData, (state, {timelineAsset,trackingEnabled})=>{
  //     return {
  //         ...state,
  //         timelineData: buildFullTimelineContentArray(timelineAsset),
  //         timelineLoading: false,
  //         isTrackingEnabled:trackingEnabled

  //     }
  // }),

  on(TimelineActions.getTimelineContentDataFailure, (state, {error}) => {
    return {
      ...state,
      error: error,
      timelineLoading: false,
      isTrackingEnabled: false

    };
  }),


  on(TimelineActions.getTimelineContentData, (state) => {
    return {
      ...state,
      timelineLoading: true

    };
  }),
  on(TimelineActions.updateTrackableLpOrder, (state) => {
    return {
      ...state,
      timelineLoading: true

    };
  }),

  on(TimelineActions.updateTrackableLpOrderSuccess, (state) => {
    return {
      ...state,
      timelineLoading: false

    };
  }),


  on(TimelineActions.updateTrackableLpOrderFailure, (state, {error}) => {
    return {
      ...state,
      timelineLoading: false,
      error: error

    };
  }),

  on(TimelineActions.toggleTimelineSelection, (state, {selected,selectedIndex}) => {
    return {
      ...state,
      timelineLoading: false,
      timelineData:state?.timelineData?.map((v,idx)=>{
        if(selectedIndex===-1){
          return {...v,selected:selected}
        }
        else{
          if(idx===selectedIndex){
            return {...v,selected:selected}

        }
        return {...v}
      }})

    };
  }),
  // on(TimelineActions.getAssignedCarrierSuccess, (state, { data }) => {
  //   return {
  //     ...state,
  //     assignedCarrierData: data
  //   };
  // }),
);

const buildFullTimelineContentArray = (rowData) => {
  // this._timelineTab.timelineContentArray = [];
  const timelineContentArrayCopy: any = [];
  const modifyTimeLine = [];
  if (rowData.timeLine && rowData.timeLine.length > 0) {
    const timeLineCopy = rowData.timeLine.filter(d => d !== null);
    forEach(timeLineCopy, function (value) {
      const deliveryStatus =
        value?.deliveryStatusObj && value?.deliveryStatusObj.date
          ? value?.deliveryStatusObj
          : value?.deliveryStatus && value?.deliveryStatus?.date
            ? value?.deliveryStatus
            : null;
      const eventStatus =
        value?.event && value?.event?.date
          ? value?.event
          : value?.event && value?.event?.date
            ? value?.event
            : null;
      let timeLineData = {};
      if (deliveryStatus || eventStatus) {
        if (deliveryStatus) {
          timeLineData = _.merge(timeLineData, deliveryStatus);
          timeLineData['status'] = deliveryStatus?.deliveryStatus;
        } else if (eventStatus) {
          timeLineData = _.merge(timeLineData, eventStatus);
          timeLineData['deliveryStatus'] = eventStatus?.status;
        }
        if (value?.agent && value?.agent?.name) {
          timeLineData['agentName'] = value?.agent?.name;
          if (value?.agent?.mobileNumber) {
            timeLineData['agentName'] = timeLineData['agentName'] + ' (' + 'Mobile : ' + value?.agent?.mobileNumber + ')';
          } else if (value.agent.mobileNo) {
            timeLineData['agentName'] = timeLineData['agentName'] + ' (' + 'Mobile : ' + value?.agent?.mobileNo + ')';
          }
        }
        if (value?.agent) {
          value?.agent?.otp ? timeLineData['deliveryOtp'] = (value?.agent?.otp).toString() : value?.agent?.deliveryOtp ? timeLineData['deliveryOtp'] = (value?.agent?.deliveryOtp).toString() : '';

        }
        if (value.logisticProvider && value.logisticProvider.name) {
          timeLineData['logisticProvider'] = value?.logisticProvider?.name;
          timeLineData['lpType'] = value?.logisticProvider?.type;
          timeLineData['lpId'] = value?.logisticProvider?._id;
        }
        if (value?.deliveryStatusObj && value?.deliveryStatusObj['location']) {
          timeLineData['location'] = value?.deliveryStatusObj['location'];
        }


        modifyTimeLine.push(timeLineData);
      }
      // console.log(timeLineData);
    });
    let timeLine = [];
    timeLine = _.orderBy(modifyTimeLine, ['date'], ['desc']);
    for (let i = 0; i < timeLine.length; i++) {
      const value = timeLine[i];
      const header = value.status;
      const filterPipe = new NamingConventionFilterPipe();
      const fiteredString = filterPipe.transform(header ? header : '');
      let content = '';
      const contents: any = [];
      const displayData: any = [];
      let imageUrls: string[] = [];
      let description = '';
      let reason = '';
      let location: any = null;
      if (value['agentName']) {
        let label = 'Agent : ';
        if (rowData.moduleType && rowData.moduleType === MMD_MODULE_NAME) {
          label = 'Carrier : ';
        }
        content = `${label}${value['agentName']}`;
        contents.push(content);
      }
      if (value['logisticProvider']) {
        content = `Lp : ${value['logisticProvider']}`;
        contents.push(content);
      }
      if (value['boxTemp'] && value['boxTemp'] !== 'NA') {
        let label = 'Box Temp : ';
        if (rowData.moduleType && rowData.moduleType === MMD_MODULE_NAME) {
          label = 'Temp : ';
        }
        content = `${label} ${parseFloat(value['boxTemp'].toFixed(2))} °C`;
        contents.push(content);
      }

      if (value['deliveryOtp']) {
        content = `Delivery OTP : ${value['deliveryOtp']}`;
        contents.push(content);
      }
      if (value['description']) {
        description = value['description'];
      }
      if (value['desc']){
        description = value['desc'];
      }
      if (value['reason']) {
        reason = value['reason'];
      }

      if (value.location) {
        location = value.location;
      }
      if (value?.displayData) {
        displayData.push(value.displayData[0]);
      }
      if (value?.imageUrls) {
        imageUrls = value.imageUrls;
      }
      const timelineDate = value.date==='NA' ? 'NA' : moment(value.date).format('MMMM Do YYYY, hh:mm:ss a')
      if(i===0){
      timelineContentArrayCopy.push(
        buildTimelineObject(
          value.imageId,
          '',
          fiteredString,
          contents,
          timelineDate,
          description,
          reason,
          location,
          displayData,
          true,
          i,
          imageUrls
        )
      );
        }
        else{
          timelineContentArrayCopy.push(
            buildTimelineObject(
              value.imageId,
              '',
              fiteredString,
              contents,
              timelineDate,
              description,
              reason,
              location,
              displayData,
              false,
              i,
              imageUrls
            )
          );
        }
    }
  }
  return timelineContentArrayCopy;
};

const buildTimelineObject = (
  imageId,
  iconToUse,
  header,
  contents,
  timestamp?,
  description?,
  reason?,
  location?,
  displayData?,
  selected?,
  index?,
  imageUrls?
) => {
  const obj = {
    icon: '',
    header: '',
    contents: [],
    iconType: '',
    image: '',
    timestamp: '',
    eventDescription: '',
    eventReason: '',
    location: {},
    displayData: [],
    selected:selected,
    index:0,
    imageUrls:[]
  };
  obj.icon = iconToUse;
  obj.header = header;
  obj.contents = contents;
  obj.image = imageId;
  obj.timestamp = timestamp ?? null;
  obj.eventDescription = description ?? null;
  obj.eventReason = reason ?? null;
  obj.index = index;
  obj.selected = selected;
  obj.location = location ?? null;
  obj.displayData = displayData ?? null;
  obj.imageUrls = imageUrls ?? [];
  return obj;
};

