import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { GeoFenceObject, TrackerIcons } from '../../../state/Fleet/models/fleet.models';
import { ConsignmentEventGeofenceMapComponent, IComplianceEvent, ILocationPayload } from '../consignment-event-geofence-map/consignment-event-geofence-map.component';
import { DialogModule } from 'primeng/dialog';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { LoaderComponent } from '../loader/loader.component';
import { TranslateModule } from '@ngx-translate/core';
import { NonComplianceEventsTableComponent } from '../non-compliance-events-table/non-compliance-events-table.component';
import { SharedModule } from '../../../app/shared/shared.module';
import { ButtonModule } from 'primeng/button';
import { SpeedDialModule } from 'primeng/speeddial';
import { TableModule } from 'primeng/table';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { SliderModule } from 'primeng/slider';
import { InputTextModule } from 'primeng/inputtext';
import { InputNumberModule } from 'primeng/inputnumber';

import { GeoFenceFilterPipe } from '../../../pipes/geoFence.filter.pipe';
import { EventsFilterPipe } from '../../../pipes/events.filter.pipe';
import { ToggleButtonModule } from 'primeng/togglebutton';
import moment from 'moment';
import { EChartDataType, EChartSeriesUpdate } from '../../../app/shared/e-charts/e-charts-data-type';
import { ConsignmentEventGeofenceGraphService } from '../../../app/delivery-managenment-system/services/consignment-event-geofence-graph.service';
import { visualMaps } from '../../../state/dashboard/dashboard/dashboard.helper.service';
import { cloneDeep } from 'lodash';


@Component({
  selector: 'app-non-compliance-events-dialog', 
  standalone: true,
  imports: [
    DialogModule,
    CommonModule,
    FormsModule,
    SharedModule,
    LoaderComponent,
    TranslateModule,
    ConsignmentEventGeofenceMapComponent,
    NonComplianceEventsTableComponent,
    ButtonModule,
    SpeedDialModule,
    TableModule,
    CommonModule,
    FormsModule,
    SharedModule,
    ConfirmPopupModule, 
    SliderModule,
    InputTextModule,
    InputNumberModule,
    SpeedDialModule,
    LoaderComponent,
    GeoFenceFilterPipe,
    EventsFilterPipe,
    ToggleButtonModule,
  ],
  templateUrl: './non-compliance-events-dialog.component.html',
  styleUrl: './non-compliance-events-dialog.component.scss'
})
export class NonComplianceEventsDialogComponent implements OnChanges{

  @Input() routesDetails: any = null;
  @Input() mapMarkers: any = [];
  @Input() liveDataFromSocket: any = {};
  @Input() rowData: any;
  @Input() agentDetails: any;
  @Input() data
  @Input() header
  @Input() displayPills = true;
  @Input() pageName = '';
  @Input() selectedOrderDeliveryGroup: any;
  @Input() carGpsHistoricalData: any
  @Input() geoFences: GeoFenceObject[];
  @Input() trackIcons: TrackerIcons;
  @Input() reloadMapNumber: number;
  @Input() changeDialogVisibilityNumber: number;
  @Input() eventsSubTypes
  @Input() temperatureEvents
  @Input() selectedNonComplianceEvent
  @Input() resetGraph


  @Output() emittGetEventAction: EventEmitter<any> = new EventEmitter();
  @Output() emittCurrentEvent: EventEmitter<any> = new EventEmitter();
  @Output() activeRouteChanged: EventEmitter<any> = new EventEmitter();
  @Output() emittRightButtonsAction: EventEmitter<any> = new EventEmitter();
  @Output() routeLegendChanged: EventEmitter<any> = new EventEmitter();
  @Output() sendModalClosed: EventEmitter<any> = new EventEmitter();
  @Output() sendSideBarClosedEvent: EventEmitter<any> = new EventEmitter();
  @Output() sendLatLngValues: EventEmitter<ILocationPayload> = new EventEmitter();
  @Output() emittGeoFenceRadiusPayload: EventEmitter<ILocationPayload> = new EventEmitter();
  @Output() onDialogClose: EventEmitter<number> = new EventEmitter();

  isEventFocusedView: boolean
  currentNonComplianceEvent: IComplianceEvent
  showLoader
  public graphData: EChartDataType = {
    series: [],
    legend: [],
    type: 'line',
    title: 'Stats',
    xAxis: [],
    visualMap: visualMaps,
    isDataZoomRequired: true
  };
  
  public graphUpdateValue: EChartSeriesUpdate = {
    index: null,
    label: null,
    value: null,
    labelIndex: 0,
    values: []
  };

  moduleName

  public boxStats = {
    show: false,
    showLoader: true,
  };

  constructor(
    private consignmentEventGeofenceGraphService:ConsignmentEventGeofenceGraphService
  ){}

  ngOnChanges(changes: SimpleChanges) {
    try {
      for (const propName of Object.keys(changes)) {
        const change = changes[propName];

        if (propName === 'changeDialogVisibilityNumber') {
          if (change.currentValue !== change.previousValue) {
            if (this.changeDialogVisibilityNumber > 0) {
              this.showLoader = true
              this.isEventFocusedView = true
            }
          }
        }

        if (propName === 'rowData') {
          if (change.currentValue !== change.previousValue) {
            if (this.rowData) {
              this.moduleName = this.rowData?.moduleType;
              this.boxStats.show = true;
              this.graphData = this.consignmentEventGeofenceGraphService.buildGraph(this.rowData?.assetObj?.deliveryPerson?._id,this.carGpsHistoricalData,this.moduleName,true);
              this.boxStats.showLoader = false;

            }
          }
        }
        if (propName === 'carGpsHistoricalData') {
          if (change.currentValue !== change.previousValue) {
            if (this.carGpsHistoricalData?.length) {
              this.boxStats.show = true;
              this.graphData = this.consignmentEventGeofenceGraphService.buildGraph(this.rowData?.assetObj?.deliveryPerson?._id,this.carGpsHistoricalData,this.moduleName,true);
              this.boxStats.showLoader = false;

            }
          }
        }
        if (propName === 'resetGraph') {
          if (change.currentValue !== change.previousValue) {
      
            if (this.resetGraph > 0) {
              this.graphData = this.consignmentEventGeofenceGraphService.plotEmptyGraph(this.moduleName,true);
            }
          }
        }
        if (propName === 'liveDataFromSocket') {
          if (change.currentValue !== change.previousValue) {
            
            if (this.liveDataFromSocket && this.liveDataFromSocket.sensorData) {
              this.plotSocketDataGraph(this.liveDataFromSocket);
            }
          }
        }
        if (propName === 'selectedNonComplianceEvent') {
          if (change.currentValue !== change.previousValue) {
            
           console.log(this.selectedNonComplianceEvent,'selectedNonComplianceEvent')
          }
        }

      }
    } catch (e) {
      console.error(e);//
    }
  }

  triggerRowClick(complianceEvent:IComplianceEvent){
    this.currentNonComplianceEvent = complianceEvent;
    const timeValue = this.dateFormator(complianceEvent?.generatedTimeStamp, 'lll');
    const temperatureValue = complianceEvent?.sensorData.temperature


    const modTempSeries = {
      ...(this.graphData?.series?.[0] || {}),
      markPoint: {
        label: {
          fontSize: 11,
          color: 'white'
        },
        itemStyle: {
          color: '#4B6D7E',
        },
        data: [
          { coord: [timeValue, temperatureValue], symbol: 'pin', symbolSize: 40, value: 'O' },
        ],
      },

    }

    const temp: any = {
      ...this.graphData,
      series: [modTempSeries],
    }
    this.graphData = temp
  }


  plotSocketDataGraph(result) {
    if (result.generatedTimeStamp) {

      const update: EChartSeriesUpdate = {
        index: null,
        label: null,
        value: null,
        labelIndex: 0,
        values: []
      };

      update.label = (this.dateFormator(result.generatedTimeStamp, 'lll'));
      result.sensorData.temperature || result.sensorData.temperature == 0 ? update.values.push((result.sensorData.temperature).toFixed(2)) : update.values.push(null);
      result.sensorData.humidity || result.sensorData.humidity == 0 ? update.values.push((result.sensorData.humidity).toFixed(2)) : update.values.push(null);
      this.graphUpdateValue = cloneDeep(update);

    }
  }

  dateFormator(date, format?) {
    let dateFormate = moment(date).format('MMMM Do YYYY, h:mm a');
    if (format) {
      if (format === 'date') {
        dateFormate = moment(date).format('MMMM Do YYYY, h:mm a');
      } else if (format === 'time') {
        dateFormate = moment(date).format('h:mm:ss a');
      } else if (format === 'lll') {
        dateFormate = moment(date).format('LLL');
      }
    }
    return dateFormate;
  }

  sendDataToPlotMarker(_){}
  recieveRightButtonsAction(_){}
  onViewChangeOfEventsDialog(status){
    if(!status){
      this.showLoader = false
    }else{
      this.onDialogClose.emit(Math.random())
    }
  }

}
