<div class="relative-data-view-in-table">
    @for (parent of data(); track parent.label)
    {
    <div>
        <div class="parent">
            <span class="parent-label" [ngStyle]="{'color':parent.color}">
                {{parent.label}}
            </span>
            <div class="childrens">
                @for (children of parent.children; track children.label)
                {
                <div class="child">
                    <div class="dot"></div>
                    <span class="child-label">
                        {{ children.label}}
                    </span>
                </div>
                }
            </div>
        </div>
    </div>
    }
</div>