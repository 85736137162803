import { Component, OnInit } from '@angular/core';
//import { ActivatedRoute } from '@angular/router';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ApiService } from '../../services/api.service';
import { MessageService} from 'primeng/api'

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrl: './reset-password.component.scss'
})
export class ResetPasswordComponent implements OnInit{
  resetForm: FormGroup;
  token: string;
  iotZenLogoLight = 'assets/IotZen Logo/IoTZEN_NewLogo_white.png';
  iotZenLogoDark = 'assets/IotZen Logo/IoTZEN_NewLogo_black.png';
  ZDMainLogo = 'assets/IotZen Logo/ZD_Logo_Transparent.png';
  typeOfPwd='password'
  bulletPointsArray: string[] = [
    'Enterprise platform flexible and modular',
    'Improve operational excellence and customer experience',
    'Integrated, simple to use, stylish and functional',
    'In-built analytics, visualization and alert engines ',
  ];

  constructor(
    private fb: UntypedFormBuilder,
    private authService: ApiService,
    private messageService: MessageService,
  ) {
  }

  ngOnInit() {

    this.buildForm()
    // Get token from URL
    try {
      const fullUrl = window.location.href;
      const tokenMatch = fullUrl.match(/[?&]token=([^&#]*)/);
      if (tokenMatch && tokenMatch[1]) {
        this.token = decodeURIComponent(tokenMatch[1]);
      } else {
        console.error('Token not found in URL');
        // Handle missing token case
      }
    } catch (error) {
      console.error('Error processing URL:', error);
      // Handle error case
    }
  }

  buildForm(){
    this.resetForm = this.fb.group({
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: ['', [Validators.required]]
    });
    
    this.resetForm.get('confirmPassword').valueChanges.subscribe(() => {
      if (this.resetForm.get('confirmPassword').value) {
        this.validatePasswordMatch();
      }
    });

    this.resetForm.get('password').valueChanges.subscribe(() => {
      if (this.resetForm.get('password').value) {
        this.validatePasswordMatch();
      }
    });
    
  }

  validatePasswordMatch() {
    const password = this.resetForm.get('password').value;
    const confirmPassword = this.resetForm.get('confirmPassword').value;
    
    const confirmPasswordControl = this.resetForm.get('confirmPassword');
    
    if (confirmPassword && confirmPassword !== password) {
      confirmPasswordControl.setErrors({ ...confirmPasswordControl.errors, mismatch: true });
    } else {
      const currentErrors = { ...confirmPasswordControl.errors };
      if (currentErrors) {
        delete currentErrors.mismatch;
        confirmPasswordControl.setErrors(Object.keys(currentErrors).length ? currentErrors : null);
      }
    }
  }


  togglePassword(){
    if(this.typeOfPwd == 'text'){
      this.typeOfPwd = 'password'
    }else{
      this.typeOfPwd = 'text'
    }
  }

  onSubmit() {
   if (this.resetForm.valid) {
      const newPassword = this.resetForm.get('password').value;
      this.authService.resetPassword(this.token, newPassword)
        .subscribe(
          () => {
            // Handle success
            this.messageService.add({key:'global-notification', severity: 'success', summary: 'Success', detail: 'Password changed successfully...!',sticky: true });
          },
          error => {
            // Handle error
            this.messageService.add({key:'global-notification', severity: 'error', summary: 'Error', detail: error?.error?.msg ?? 'Error Occured...!',sticky: true });
          }
        );
    }
  }

}
