/**
 * Created by chandru on 9/6/20.
 */

import {Pipe, PipeTransform} from '@angular/core';
import {GeoFenceObject} from "../state/Fleet/models/fleet.models";

@Pipe({
  name: 'geoFenceFilterPipe',
  standalone:true
})
export class GeoFenceFilterPipe implements PipeTransform {
  transform(items: GeoFenceObject[], searchText: string): GeoFenceObject[] {
    if (!items) {
      return [];
    }
    if (!searchText) {
      return items;
    }
    searchText = searchText.toLowerCase();
    return items.filter( item => {
      if (item.label) {
        return item.label.toLowerCase().includes(searchText);
      }
    });
  }
}
