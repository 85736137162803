<div class="timeline mainTtl MT20">
  <div class="title TAC">Deliver Timeline</div>
  <div class="toggleButton TAC cursorPointer">
    <!-- {{_.filter(timelineContentArray, 'selected').length}} -->
    <!-- <span pTooltip="Filter Data" tooltipPosition="top">
    <p-multiSelect class="timeline-fields-multiselect"  *ngIf="timelineObj?.multiSelectVisible" defaultLabel="Select a field"  [options]="timelineObj?.fields" [(ngModel)]="selectedtimeLineFields" optionLabel="field" (onChange)="getTimelineData()"></p-multiSelect>
  </span> -->
    @if ((timeLineTrackingStatus$ | async)) {
      <span (click)="clickTimelineDataRefresh()" pTooltip="Refresh Data" tooltipPosition="top">
      <i class="pi pi-refresh"></i>
    </span>
    }
    @if (_.filter(timelineContentArray, 'selected')?.length !== timelineContentArray?.length) {
      <span (click)="clickToggle()" class="timeline-plus-minus">
      <i class="pi pi-plus"></i>
    </span>
    }
    @if (_.filter(timelineContentArray, 'selected')?.length === timelineContentArray?.length) {
      <span (click)="clickToggle()" class="timeline-plus-minus">
      <i class="pi pi-minus"></i>
    </span>
    }
  </div>
</div>

<p-timeline  [value]="timelineContentArray" align="left" class="timeline" [ngStyle]="{'visibility': showHide ? 'block' : 'none'}">
  <ng-template  let-event  let-i="index"  pTemplate="marker" class="">
    @if (iconList[event?.header]) {
      <span class="custom-marker p-shadow-2" [style.backgroundColor]="event.color"
            (click)="(event.eventDescription || event.image || event.eventReason || (event.contents && event.contents.length > 0))?clickEvent(event):''">
        <i><img [src]="iconList[event?.header]" style="width:20px;"></i>
      </span>
    }
    @if (!iconList[event?.header]) {
      <div class="p-timeline-event-marker ng-star-inserted"
           (click)="(event.eventDescription || event.image || event.eventReason || (event.contents && event.contents.length > 0))?clickEvent(event):''"></div>
    }
  </ng-template>
  <ng-template  let-event let-i="index" pTemplate="content">
    <div class="" [class.selected]="event?.selected"
         (click)="(event.eventDescription || event.image || event.eventReason || (event.contents && event.contents.length > 0))?clickEvent(event):''"
         [ngClass]="(event.eventDescription || event.image || event.eventReason || (event.contents && event.contents.length > 0))?'cursorPointer':'cursorConMenu'">
      <h5 class="p-card-title"
          [ngClass]="(event.eventDescription || event.image || event.eventReason || (event.contents && event.contents.length > 0))?'activeTxt':'disible-text'">{{ event?.header }}</h5>
      <p class="p-card-subtitle"
         [ngClass]="(event.eventDescription || event.image || event.eventReason || (event.contents && event.contents.length > 0))?'activeTxt':''">{{ event.timestamp }}</p>
    </div>
    @if (event?.selected && (event.eventDescription || event.image || event.eventReason || (event.contents && event.contents.length > 0))) {
      <p-card class="template-card">
        <div class="cantaint">
          @if (event.content) {
            <div class="timeline-content">{{ event.content }}</div>
          }
          <div class="timeline-content" [innerHTML]="event.eventDescription"></div>
          <div class="timeline-content" [innerHTML]="event.eventReason"></div>
          @if (event.contents && event.contents.length > 0) {
            <div class="timeline-content">
              @for (content of event.contents; track content) {
                <div class="timeline-content">{{ content }}</div>
              }
              @if (event.location && event.location.lat && event.location.lng) {
                <div class="timeline-content">
                  <div class="location-icon-container cursorPointer">
                    <img width='25' height='25' class="location-icon" src="../../../assets/asset-icons/location.png"
                         (click)="onMapIconClick(event)">
                  </div>
                </div>
              }
            </div>
          }
          <div class="imglist">
            <!-- if image is a string -->
            @if (event.image && !checkIfImageIsArray(event.image)) {
              <div class="imageBox">
                <img class="cursorPointer timeline-img MB15 MR10" id="{{event.image}}"
                     [src]="assetService.getDeliveryImageById(event.image)"
                     alt="img" (click)="sendImgId(event.image)"/>
              </div>
            }
            <!-- if image is an array -->
            @if (event.image && checkIfImageIsArray(event.image)) {
              <div class="imageBox">
                @for (img of event.image; track img) {
                  <img class="cursorPointer timeline-img img-array MB15 MR10" id="{{img}}"
                       [src]="assetService.getDeliveryImageById(img)"
                       alt="img" (click)="sendImgId(img)"/>
                }
              </div>
            }
          </div>
          @if (event?.displayData?.length > 0) {
            <div class="imglist">
              @if (event.displayData && event?.displayData[0]?.url) {
                <div class="imageBox">
                  <img class="cursorPointer timeline-img img-array MB15 MR10" id="{{event?.displayData[0]?.url}}"
                       [src]="event?.displayData[0]?.url"
                       alt="img" (click)="sendImgUrl(event.displayData[0].url)"/>
                </div>
              }
            </div>
          }
          @if (event?.imageUrls?.length > 0) {
            <div class="imglist">
                <div class="imageBox">
                  @for (url of event.imageUrls; track url) {
                    <img class="cursorPointer timeline-img img-array MB15 MR10" id="{{url}}"
                         [src]="url"
                         alt="img" (click)="sendImgUrl(url)"/>
                  }
                </div>
            </div>
          }
        </div>
      </p-card>
    }
  </ng-template>
</p-timeline>

<!-- <div class="modal fade" id="displayMapModal" tabindex="-1" role="dialog">
<div class="modal-dialog modal-dialog-centered modal-lg " role="document">
  <div class="modal-content">
    <div class="modal-header sidebar-modal-header">
      <h5 class="modal-title">location</h5>
      <button type="button" class="close" (click)="hideProfileImgModal('#displayMapModal')"><span aria-hidden="true">&times;</span></button> -->
<!-- <h4 class="modal-title">{{'sys.delivery-image' | translate}}</h4> -->
<!-- </div> -->

<!-- <div class="modal-body" style="margin: 0 auto">
<div class="imgContainer">

  <div id="mapView">
    <div id="map" leaflet
      (leafletMapReady)="onMapReady($event)"
      [leafletOptions]="options"
      [leafletLayers]="markers">
    </div>
  </div>
</div>
</div>

</div>
</div>
</div> -->
<p-dialog header="Location" [(visible)]="showMap" [modal]="true" [style]="{'width': '70vw'}">
  <div class="imgContainer">
    <div id="mapView">
      <div id="map" leaflet
           (leafletMapReady)="onMapReady($event)"
        [leafletOptions]="options"
        [leafletLayers]="markers" [style]="{'height':'80vh'}">
      </div>
    </div>
  </div>
</p-dialog>




