import { Injectable } from "@angular/core";
import { SettingsActions } from ".";
import { Actions, createEffect , ofType} from "@ngrx/effects";
import { switchMap ,from, map, catchError, of, tap, withLatestFrom, mergeMap, forkJoin, Observable} from "rxjs";
import { IProcessorList, SettingsService } from "./settings.service";
import { MessageService } from "primeng/api";
import { loadOrderTimeSlotsSuccess } from "../Assets/assets.action";
import { sortTimeSlots } from "./o2dsettings.helper.service";
import { IAllStatusOptions, IConsignmentAssignForm, INotifyRecipientOptions, IProgressSettings, IQueueSetting, IReportSetting, IRoutePlanSettings } from "./interfaces/lmdMmdSettings.interface";
import { cloneDeep } from "lodash";
import { IMMDFormMetaData, ILMDFormMetaData } from "./interfaces/mmd-formSettings.interface";
import { Store } from "@ngrx/store";
import {selectorFormMetaDataForLMD,selectorFormMetaData, getMetaDataForDMSSettingsSelector, getAllSettingsForDMSSettingsSelector, getGroupsSettingsWithModule, allowedStatusesSettingsSelector, selectorRoutePlanCategoryList , getMetaDataSettingsForAllModuleFromSelector, getGroupTypesForSelectedAllFromSelector, getAllSettingsForAllModuleFromSelector} from "./settings.selector"

@Injectable()
export class SettingsEffects {

  emptyObservable = new Observable<string>
  loadDefaultTableSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.getDefaultTableSettingsForAnAccount),
      switchMap(() => {
        return from(this.settings.effectOrderTableSettings()).pipe(
          switchMap((res) => {
            if(res?.assignmentWindow?.window){
              return [SettingsActions.onDefaultTableSettingsForAnAccountFetchSuccuss({settings: res}),
                loadOrderTimeSlotsSuccess({data: sortTimeSlots(res?.assignmentWindow.window)})]
            } else {
              return [SettingsActions.onDefaultTableSettingsForAnAccountFetchSuccuss({settings: res})]
            }
          }),
          catchError((error) => of(SettingsActions.onDefaultTableSettingsForAnAccountFetchFailure({err: error})))
        )
        })
   ));
   
   getNotifyRecipientOptions$ = createEffect(()=>
     this.actions$.pipe(
        ofType(SettingsActions.getNotifyRecipientOptions),
        switchMap((action) => {
            let response:IAllStatusOptions;
            let list = [];
            return from(this.settings.getAllStatusOptions(action.moduleType)).pipe(
                switchMap((res : IAllStatusOptions[]) => {
                    response['deliveryStatusOptions']= res;
                    list = res;
                    return from (this.settings.getNotificationFormValue(action.moduleType)).pipe(
                          switchMap((res : Record<string,string[]>)=>{
                            let status = {
                                deliveryStatus: [],
                                notifyMode: []
                            }
                            status['deliveryStatus'] = res?.['onDeliveryStatus'].map(el => {
                                let label = list.filter(li => {
    
                                    if (li.status === el) {
                                        return li
                                    }
                                })
                                return label[0]
                            })
                            status['notifyMode'] = cloneDeep(res?.['notifyMode'].map(str => {
                                let name = str.charAt(0).toUpperCase() + str.slice(1)
                                return { label: name, id: str }
                            }))
                        response['selectedDeliveryStatus'] = status['deliveryStatus'];
                        response['selectedNotifyMode'] = status['notifyMode'];
                        return [SettingsActions.getNotifyRecipientOptionsSuccess({ res : response, moduleType : action.moduleType })]
                          }),
                          catchError((error) => of(SettingsActions.getNotifyRecipientOptionsFailure({ err: error })))
                    )
                }),
                catchError((error) => of(SettingsActions.getNotifyRecipientOptionsFailure({ err: error })))
            )
        })
     )
   );

   updateNotifyRecipientOptions$ = createEffect(()=>
    this.actions$.pipe(
        ofType(SettingsActions.updateNotifyRecipientOptions),
        switchMap((action)=>{
            return from(this.settings.setNotificationFormValues(action.moduleType , action.data)).pipe(
                tap(() => {
                    this.messageService.add({ key: 'global-notification', severity: 'success', summary: 'Updated', detail: 'Successfully Updated ' +  ' Notify Recipient Settings' });
                  }),
                switchMap((res : INotifyRecipientOptions)=>{
                      return [SettingsActions.updateNotifyRecipientOptionsSuccess({res,moduleType : action.moduleType})]
                }),
                catchError((error) => of(SettingsActions.getNotifyRecipientOptionsFailure({ err: error })))
            )
        })
    )
   );

   getReportSetting$ = createEffect(()=>
    this.actions$.pipe(
        ofType(SettingsActions.getReportSetting),
        switchMap((action)=>{
            return from(this.settings.getReportSetting(action.moduleType)).pipe(
                switchMap((res)=>{
                      return [SettingsActions.getReportSettingSuccess({res, moduleType:action.moduleType})]
                }),
                catchError((error) => of(SettingsActions.getNotifyRecipientOptionsFailure({ err: error })))
            )
        })
    )
   );

   updateReportSetting$ = createEffect(()=>
   this.actions$.pipe(
       ofType(SettingsActions.updateReportSetting),
       switchMap((action)=>{
           return from(this.settings.setReportSettings(action.moduleType , action.data)).pipe(
            tap(() => {
                this.messageService.add({ key: 'global-notification', severity: 'success', summary: 'Updated', detail: 'Successfully Updated ' +  ' Report Settings' });
              }),
               switchMap((res : IReportSetting)=>{
                     return [SettingsActions.updateReportSettingSuccess({res ,moduleType : action.moduleType})]
               }),
               catchError((error) => of(SettingsActions.getNotifyRecipientOptionsFailure({ err: error })))
           )
       })
   )
  );
  getQueueSettingsByModule$ = createEffect(()=>
    this.actions$.pipe(
        ofType(SettingsActions.getQueueSettingsByModule),
        switchMap((action)=>{
            return from(this.settings.getNotificationFormValue(action.moduleType ,action.queue)).pipe(
                switchMap((res : IQueueSetting)=>{
                      return [SettingsActions.getQueueSettingsByModuleSuccess({res,moduleType : action.moduleType})]
                }),
                catchError((error) => of(SettingsActions.getNotifyRecipientOptionsFailure({ err: error })))
            )
        })
    )
   );

   updateQueueSetting$ = createEffect(()=>
   this.actions$.pipe(
       ofType(SettingsActions.updateQueueSetting),
       switchMap((action)=>{
           return from(this.settings.updateDmsQueueSettingsByModuleType(action.moduleType , action.payload)).pipe(
            tap(() => {
                this.messageService.add({ key: 'global-notification', severity: 'success', summary: 'Updated', detail: 'Successfully Updated ' +  ' Report Settings' });
              }),
               switchMap((res)=>{
                     return [SettingsActions.updateQueueSettingSuccess({res,moduleType : action.moduleType})]
               }),
               catchError((error) => of(SettingsActions.getNotifyRecipientOptionsFailure({ err: error })))
           )
       })
   )
  );
  getroundTripSetting$ = createEffect(()=>
  this.actions$.pipe(
      ofType(SettingsActions.getRoundTripSetting),
      switchMap((action)=>{
          return from(this.settings.getRoundTripSettingsByModuleType(action.moduleType)).pipe(
              switchMap((res)=>{
                    return [SettingsActions.getRoundTripSettingSuccess({res,moduleType : action.moduleType})]
              }),
              catchError((error) => of(SettingsActions.getNotifyRecipientOptionsFailure({ err: error })))
          )
      })
    )
  );
  setO2DFormSectionSetting$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.setO2DFormSectionSetting),
      switchMap((action) => {
        return from(this.settings.putFormSectionSetting(action.payload)).pipe(
          tap(() => {
              this.messageService.add({ key: 'global-notification', severity: 'success', summary: 'Updated', detail: 'Successfully Updated ' +  ' Report Settings' });
            }),
             switchMap((_r)=>{
                   return [SettingsActions.setO2DFormSectionSettingSuccess({msg:'updated successfully',payload:action.payload})]
             }),
             catchError((error) => of(SettingsActions.getNotifyRecipientOptionsFailure({ err: error })))
         )
     })
   ));

   getObdSetting$ = createEffect(()=>
   this.actions$.pipe(
       ofType(SettingsActions.getObdSetting),
       switchMap(()=>{
           return from(this.settings.getObdSettingsByModuleType()).pipe(
               switchMap((res)=>{
                     return [SettingsActions.getObdSettingSuccess({res})]
               }),
               catchError((error) => of(SettingsActions.getNotifyRecipientOptionsFailure({ err: error })))
           )
       })
   )
  );
  setO2DTableFieldSetting$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.setO2DTableFieldSetting),
      switchMap((action) => {
        return from(this.settings.putTableFieldSetting(action.payload)).pipe(
          tap(() => {
            this.messageService.add({
              key: 'global-notification',
              severity: 'success',
              summary: 'Updated',
              detail: 'Successfully Updated ' + ' Table Settings'
            });
          }),
          switchMap(() => {
            return [SettingsActions.setO2DTableFieldSettingSuccess({msg:'Successfully Updated',payload:action.payload})]
          }),

          catchError((error) => of(SettingsActions.onDefaultTableSettingsForAnAccountFetchFailure({err: error})))
        )
      })
    )
  );
  setO2DSlotSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.setO2DSlotSetting),
      switchMap((action) => {
        return from(this.settings.putSlotSetting(action.payload)).pipe(
          switchMap(() => {
            return [SettingsActions.setO2DSlotSettingSuccess({msg:'Successfully Updated',payload:action.payload})]
          }),

          catchError((error) => of(SettingsActions.onDefaultTableSettingsForAnAccountFetchFailure({err: error})))
        )
      })
    )
  );
  updateGetRoundTripSetting$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.updateGetRoundTripSetting),
      switchMap((action) => {
        return from(this.settings.setRoundTripSettings(action.moduleType, action.payload)).pipe(
          tap(() => {
            this.messageService.add({
              key: 'global-notification',
              severity: 'success',
              summary: 'Updated',
              detail: 'Successfully Updated ' + ' Report Settings'
            });
          }),
          switchMap((res: IRoutePlanSettings) => {
            return [SettingsActions.updateGetRoundTripSettingSuccess({res, moduleType: action.moduleType})]
          }),
          catchError((error) => of(SettingsActions.getNotifyRecipientOptionsFailure({err: error})))
        )
      })
    ));
  updateObdSetting$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.updateObdSetting),
      switchMap((action)=>{
          return from(this.settings.setObdSettings(action.payload)).pipe(
           tap(() => {
               this.messageService.add({ key: 'global-notification', severity: 'success', summary: 'Updated', detail: 'Successfully Updated ' +  ' Report Settings' });
             }),
              switchMap((res)=>{
                    return [SettingsActions.updateObdSettingSuccess({res})]
              }),
              catchError((error) => of(SettingsActions.getNotifyRecipientOptionsFailure({ err: error })))
          )
      })
  )
 );

 getAssignmentWindownSettings$ = createEffect(()=>
   this.actions$.pipe(
       ofType(SettingsActions.getAssignmentWindownSettings),
       switchMap((action)=>{
           return from(this.settings.getAssignmentWindownSettings(action.moduleType)).pipe(
               switchMap((res)=>{
                     return [SettingsActions.getAssignmentWindownSettingsSuccess({res})]
               }),
               catchError((error) => of(SettingsActions.getNotifyRecipientOptionsFailure({ err: error })))
           )
       })
   )
  );
 
  updateAssignmentWindownSettings$ = createEffect(()=>
  this.actions$.pipe(
      ofType(SettingsActions.updateAssignmentWindownSettings),
      switchMap((action)=>{
          return from(this.settings.putAssignmentWindownSettings(action.moduleType,action.payload)).pipe(
           tap(() => {
               this.messageService.add({ key: 'global-notification', severity: 'success', summary: 'Updated', detail: 'Successfully Updated ' +  ' Report Settings' });
             }),
              switchMap((res)=>{
                    return [SettingsActions.updateAssignmentWindownSettingsSuccess({res})]
              }),
              catchError((error) => of(SettingsActions.getNotifyRecipientOptionsFailure({ err: error })))
          )
      })
    )
  );
  getAllProcessorsSettingO2D$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.getAllProcessorsSettingO2D),
      switchMap(() => {
        return from(this.settings.getAllProcessors()).pipe(
          switchMap((res: IProcessorList[]) => {
            return [SettingsActions.getAllProcessorsSettingO2DSuccess({processorList: res})]
          }),
          catchError(() => [])
        )
      })
    )
  );
  putProcessorSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.putProcessorSettings),
      switchMap((action) => {
        return from(this.settings.putProcessorSettings(action.payload)).pipe(
          switchMap(() => {
            this.messageService.add({
              key: 'global-notification',
              severity: 'success',
              summary: 'Updated',
              detail: 'Successfully Updated ' + ' Processor Settings'
            });
            return [SettingsActions.getDefaultTableSettingsForAnAccount(),
              SettingsActions.getAllProcessorsSettingO2D()]
          }),
          catchError(() => {
            this.messageService.add({
              key: 'global-notification',
              severity: 'error',
              summary: 'Error',
              detail: 'Failed to update ' + 'Processor Settings'
            })
            ;
            return []
          }),
        )
      })
    )
  );
  getFormSettingsByModuleType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.getFormSettingsByModuleType),
      switchMap((action) => {
        return from(this.settings.getFormSettingsByModuleType(action.moduleType)).pipe(
          switchMap((res: IConsignmentAssignForm) => {
            return [SettingsActions.setFormSettingsByModuleType({moduleType: action.moduleType, settings: res})]
          }),
          catchError(() => [])
        )
      })
    )
  );
  putFormSettingsByModuleType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.putFormSettingsByModuleType),
      switchMap((action) => {
        return from(this.settings.putFormSettingsByModuleType(action.moduleType, action.payload)).pipe(
          tap(() => {
            this.messageService.add({
              key: 'global-notification',
              severity: 'success',
              summary: 'Updated',
              detail: 'Successfully Updated ' + ' Form Settings'
            });
          }),
          switchMap((res: any) => {
            return [SettingsActions.setFormSettingsByModuleType({moduleType: action.moduleType, settings: res})]
          }),
          catchError(() => [])
        )
      })
    )
  );
  getFormMetadata$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.getFormMetadata),
      withLatestFrom(this.store.select(selectorFormMetaData)),
      switchMap(([_, stateData]) => {
        if (stateData) {
          return of(SettingsActions.setFormMetadata({formMetaData: stateData}))
        } else {
          return from(this.settings.getFormMetadataForMMD()).pipe(
            switchMap((res: IMMDFormMetaData) => {
              return [SettingsActions.setFormMetadata({formMetaData: res})]
            }),
            catchError(() => [])
          )
        }
      })
    )
  );
  getLMDFormMetadata$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.getLMDFormMetadata),
      withLatestFrom(this.store.select(selectorFormMetaDataForLMD)),
      switchMap(([_, stateData]) => {
        if (stateData) {
          return of(SettingsActions.setLMDFormMetadata({formMetaData: stateData}))
        } else {
          return from(this.settings.getFormMetaDataForLMD()).pipe(
            switchMap((res: ILMDFormMetaData) => {
              return [SettingsActions.setLMDFormMetadata({formMetaData: res})]
            }),
            catchError(() => [])
          )
        }
      })
    )
  );
  getProgressSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.getProgressSettingsByModuleType),
      switchMap((action) => {
        return from(this.settings.getProgressSettingsByModule(action.moduleType)).pipe(
          switchMap((res: IProgressSettings) => {
            return [SettingsActions.setProgressSettingsByModuleType({moduleType: action.moduleType, settings: res})]
          }),
          catchError(() => [])
        )
      })
    )
  );
  putProgressSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.putProgressSettingsByModuleType),
      switchMap((action) => {
        return from(this.settings.setProgressSettingsByModule(action.payload, action.moduleType)).pipe(
          tap(() => {
            this.messageService.add({
              key: 'global-notification',
              severity: 'success',
              summary: 'Updated',
              detail: 'Successfully Updated ' + ' Progress Settings'
            });
          }),
          switchMap((res: IProgressSettings) => {
            return [SettingsActions.setProgressSettingsByModuleType({moduleType: action.moduleType, settings: res})]
          }),
          catchError(() => [])
        )
      })
    )
  );


  getGeofenceSettings$ = createEffect(() =>
  this.actions$.pipe(
    ofType(SettingsActions.getGeofenceSettingsByAssetType),
    switchMap((action) => {
      return from(this.settings.getGeofenceSettingsByAsset(action.accountId)).pipe(
        switchMap((res) => {
          return [SettingsActions.getGeofenceSettingsByAssetTypeSuccess({payload:res})]
        }),
        catchError(() => [])
      )
    })
  )
);

  putGeofenceSettings$ = createEffect(() =>
  this.actions$.pipe(
    ofType(SettingsActions.putGeofenceSettingsByAssetType),
    switchMap((action) => {
      return from(this.settings.setGeofenceSettingsByAsset(action.payload,action.module,action.typeId)).pipe(
        tap(() => {
          this.messageService.add({ key: 'global-notification', severity: 'success', summary: 'Updated', detail: 'Successfully Updated ' +  ' Geofence Settings' });
        }),
        switchMap(() => {
          return [SettingsActions.putGeofenceSettingsByAssetTypeSuccess({payload: action.payload })]
        }),
        catchError(() => [])
      )
    })
  )
);

  // getMetaDataForDMSSettings$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(SettingsActions.getMetaDataForDMSSettings),
  //     switchMap((action) => {
  //         return from(this.settings.getDMSMetadataSettings(action.moduleType)).pipe(
  //           switchMap((res) => {
  //             return [SettingsActions.getMetaDataForDMSSettingsSuccess({moduleType:action.moduleType,settings:res})]
  //           }),
  //           catchError((error) => [])
  //         )
  //     })
  //   )
  // );

  getMetaDataForDMSSettings$ = createEffect(() =>
  this.actions$.pipe(
    ofType(SettingsActions.getMetaDataForDMSSettings),
    withLatestFrom(this.store.select(getMetaDataForDMSSettingsSelector)),
    switchMap(([action, stateData]) => {
      if(stateData[action.moduleType] && stateData[action.moduleType][action.groupType]){
        return of(SettingsActions.getMetaDataForDMSSettingsSuccess({moduleType:action.moduleType,settings:stateData[action.moduleType][action.groupType],groupType:action.groupType}));
      }else {
        return from(this.settings.getDMSMetadataSettings(action.moduleType,action.groupType)).pipe(
          switchMap((res) => {
            const dynamicDropDownObservables = [];

            // Finding if Is Dynamic is true anywhere in Metadata response/ 
            res?.headers?.forEach((el,) => {
              res[el]?.actions?.forEach((e, id) => {
                if (e?.type === 'dropDown' && e?.isDynamic) {
                  dynamicDropDownObservables.push(
                    this.settings.getDMSMetadataDynamicValues(e.apiDetails.url, e.apiDetails.method).pipe(
                      catchError(() => []),
                      mergeMap(ress => {
                        // Setting the value according to api res
                        res[el].actions[id].dropDownValues = [...ress];
                        return [SettingsActions.getMetaDataForDMSSettingsSuccess({moduleType:action.moduleType,settings:ress,groupType:action.groupType})];
                      })
                    )
                  );
                }
                if(e?.data){
                  let accordianData = e?.data
                  accordianData?.map((ele,idx)=>{
                    if(ele?.subActions){
                      let subaction = ele?.subActions
                      subaction?.map((val:any,i)=>{
                        if(val.type === 'dropDown' && val?.isDynamic){
                          dynamicDropDownObservables.push(
                            this.settings.getDMSMetadataDynamicValues(val.apiDetails.url, val.apiDetails.method).pipe(
                              catchError(() => []),
                              mergeMap(ress => {
                                // Setting the value according to api res
                                res[el].actions[id].data[idx].subActions[i].dropDownValues = [...ress]
                                return [SettingsActions.getMetaDataForDMSSettingsSuccess({moduleType:action.moduleType,settings:ress,groupType:action.groupType})];
                              })
                            )
                          );
                        }
                      })
                    }
                  })
                }
              });
            });
            if(dynamicDropDownObservables.length === 0){
              return [res]
            }else{
              return forkJoin(dynamicDropDownObservables).pipe(
                map(() =>{
                  return res
                }),
                catchError(() => []),
              );
              
            }

          }),
          switchMap((res) =>{ 
            return  [SettingsActions.getMetaDataForDMSSettingsSuccess({moduleType:action.moduleType,settings:res,groupType:action.groupType})]
          }),
          catchError(() => [SettingsActions.errorHandlerForGenricDMSSetting()])
        );
      }
    })
  )
);
  getAllSettingsForDMSWithModule$ = createEffect(() =>
  this.actions$.pipe(
    ofType(SettingsActions.getAllDMSSettingsWithModuleType),
    withLatestFrom(this.store.select(getAllSettingsForDMSSettingsSelector)),
    switchMap(([action,stateData]) => {
      if(stateData[action.moduleType]){
        return of(SettingsActions.getAllDMSSettingsWithModuleTypeSuccess({moduleType:action.moduleType, settings: stateData[action.moduleType] }))
      }else{
        return from(this.settings.getAllDMSSettingsWithModule(action.moduleType)).pipe(
          switchMap((res) => {
            return [SettingsActions.getAllDMSSettingsWithModuleTypeSuccess({
              moduleType: action.moduleType,
              settings: res
            })]
          }),
          catchError(() => [SettingsActions.errorHandlerForGenricDMSSetting()])
        )
      }
    })
  )
)

putAllSettingsForDMSWithModule$ = createEffect(() =>
this.actions$.pipe(
  ofType(SettingsActions.putAllDMSSettingsWithModuleType),
  switchMap((action) => {
      return from(this.settings.putGenericSettingsWithModuleTypeAndPath(action.moduleType,action.path,action.payload)).pipe(
        tap(() => {
          this.messageService.add({ key: 'global-notification', severity: 'success', summary: 'Updated', detail: 'Successfully Updated ' + action.path + 'Settings' });
        },(error)=>{
          console.log('Error Occured!',error)
          this.messageService.add({ key: 'global-notification', severity: 'error', summary: 'Something Went Wrong' });
        },),
        switchMap(() => {
          return [SettingsActions.putAllDMSSettingsWithModuleTypeSuccess({moduleType:action.moduleType,path:action.path,payload:action.payload})]
        }),
        catchError(() => [SettingsActions.errorHandlerForGenricDMSSetting()])
      )
  })
)
)


getAllGroupsofSettingsWithModule$ = createEffect(() =>
this.actions$.pipe(
  ofType(SettingsActions.getGroupsOfDMSSettingsByModule),
  withLatestFrom(this.store.select(getGroupsSettingsWithModule)),
  switchMap(([action,stateData]) => {
    if(stateData && stateData[action.module]){
      return of(SettingsActions.getGroupsOfDMSSettingsByModuleSuccess({res:stateData[action.module],module:action.module}))
    }else{
      return from(this.settings.getDMSMetaDataByGroups(action.module)).pipe(
        switchMap((res) => {
          return [SettingsActions.getGroupsOfDMSSettingsByModuleSuccess({
            res: res.settings,
            module:action.module
          })]
        }),
        catchError(() => [SettingsActions.errorHandlerForGenricDMSSetting()])
      )
    }
  })
)
)



getAllowedStatusesSettingsByModule$ = createEffect(() =>
  this.actions$.pipe(
    ofType(SettingsActions.getAllowedStatusesSettingsByModule),
    withLatestFrom(this.store.select(allowedStatusesSettingsSelector)),
    switchMap(([action,stateData]) => {
      if(stateData[action.moduleType]){
        return[]
      }else{
        return from(this.settings.effectGetStatusesAllowedByModule(action.moduleType)).pipe(
          switchMap((res) => {
            return [SettingsActions.getAllowedStatusesSettingsByModuleSuccess({
              moduleType: action.moduleType,
              res: res
            })]
          }),
          catchError(() => [SettingsActions.errorHandlerForGenricDMSSetting()])
        )
      }
    })
  )
)




  

  getRoutePlanCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.getRoutePlanCategory),
      withLatestFrom(this.store.select(selectorRoutePlanCategoryList)),
      switchMap(([_,stateData]) => {
        if (stateData) {
          return of(SettingsActions.getRoutePlanCategorySuccess({ routePlanCategory: stateData }))
        } else {
          return from(this.settings.effectGetRoutePlanCategory().pipe(
            map((value) => SettingsActions.getRoutePlanCategorySuccess({ routePlanCategory: value.categories })),
            catchError((error) => of(SettingsActions.getRoutePlanCategoryFailure({ err: error })))
          ))
        }
      })
    )
  )



  // Generic Effects for Module Settings

  effectGetMetaDataSettingsForSelectedModule$ = createEffect(()=>
    this.actions$.pipe(
      ofType(SettingsActions.getMetaDataSettingsForSelectedModule),
      withLatestFrom(this.store.select(getMetaDataSettingsForAllModuleFromSelector)),
      switchMap(([action, stateDataExists]) => {
        let stateData = stateDataExists?.[action.module]
        if(stateData && stateData[action.groupType]){
          return of(SettingsActions.getMetaDataSettingsForSelectedModuleSuccess({res:stateData[action.groupType],groupType:action.groupType,module:action.module}));
        }else {
          return from(this.settings.getDMSMetadataSettings(action.module,action.groupType)).pipe(
            switchMap((res) => {
              const dynamicDropDownObservables = [];
  
              // Finding if Is Dynamic is true anywhere in Metadata response/ 
              res?.headers?.forEach((el,) => {
                res[el]?.actions?.forEach((e, id) => {
                  if (e?.type === 'dropDown' && e?.isDynamic) {
                    dynamicDropDownObservables.push(
                      this.settings.getDMSMetadataDynamicValues(e.apiDetails.url, e.apiDetails.method).pipe(
                        catchError(() => []),
                        mergeMap(ress => {
                          // Setting the value according to api res
                          res[el].actions[id].dropDownValues = [...ress];
                          return [SettingsActions.getMetaDataSettingsForSelectedModuleSuccess({res:ress,groupType:action.groupType,module:action.module})];
                        })
                      )
                    );
                  }
                  if(e?.data){
                    let accordianData = e?.data
                    accordianData?.map((ele,idx)=>{
                      if(ele?.subActions){
                        let subaction = ele?.subActions
                        subaction?.map((val:any,i)=>{
                          if(val.type === 'dropDown' && val?.isDynamic){
                            dynamicDropDownObservables.push(
                              this.settings.getDMSMetadataDynamicValues(val.apiDetails.url, val.apiDetails.method).pipe(
                                catchError(() => []),
                                mergeMap(ress => {
                                  // Setting the value according to api res
                                  res[el].actions[id].data[idx].subActions[i].dropDownValues = [...ress]
                                  return [SettingsActions.getMetaDataSettingsForSelectedModuleSuccess({res:ress,groupType:action.groupType,module:action.module})];
                                })
                              )
                            );
                          }
                        })
                      }
                    })
                  }
                });
              });
              if(dynamicDropDownObservables.length === 0){
                return [res]
              }else{
                return forkJoin(dynamicDropDownObservables).pipe(
                  map(() =>{
                    return res
                  }),
                  catchError(() => []),
                );
                
              }
  
            }),
            switchMap((res) =>{ 
              return  [SettingsActions.getMetaDataSettingsForSelectedModuleSuccess({res:res,groupType:action.groupType,module:action.module})]
            }),
            catchError(() => [SettingsActions.errorHandlerForGenricDMSSetting()])
          );
        }
      })
    )
  )
  


  getAllGroupsForSelectedModule$ = createEffect(()=>
    this.actions$.pipe(
      ofType(SettingsActions.getGroupTypesForSelectedModule),
      withLatestFrom(this.store.select(getGroupTypesForSelectedAllFromSelector)),
      switchMap(([action,stateDataExists]) => {
        let stateData = stateDataExists?.[action?.module]
        if(stateData){
          return of(SettingsActions.getGroupTypesForSelectedModuleSuccess({res:stateData,module:action.module}))
        }else{
          return from(this.settings.getDMSMetaDataByGroups(action.module)).pipe(
            switchMap((res) => {
              return [SettingsActions.getGroupTypesForSelectedModuleSuccess({res: res.settings,module:action.module})]
            }),
            catchError(() => [SettingsActions.errorHandlerForGenricDMSSetting()])
          )
        }
      })
    )
  )


getAllSettingsForSelectedModule$ = createEffect(() =>
  this.actions$.pipe(
    ofType(SettingsActions.getAllSettingsForSelectedModule),
    withLatestFrom(this.store.select(getAllSettingsForAllModuleFromSelector)),
    switchMap(([action,stateDataExists]) => {
      let stateData = stateDataExists?.[action?.module]
      if(stateData){
        return of(SettingsActions.getAllSettingsForSelectedModuleSuccess({res: stateData,  module:action.module}))
      }else{
        return from(this.settings.getOrderAllSettingsforSelectedModule(action.module)).pipe(
          switchMap((res) => {
            return [SettingsActions.getAllSettingsForSelectedModuleSuccess({
              res: res,
              module:action.module
            })]
          }),
          catchError(() => [SettingsActions.errorHandlerForGenricDMSSetting()])
        )
      }
    })
  )
)

  updateSettingsForSelectedWthType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.updateAllSettingsForSelectedModule),
      switchMap((action) => {
        return from(this.settings.putGnericSettingsForSelectedModule(action.payload,action.typeId,action.module)).pipe(
          tap(() => {
            this.messageService.add({ key: 'global-notification', severity: 'success', summary: 'Updated', detail: 'Successfully Updated ' + action.typeId + ' Settings' });
          },(error)=>{
            console.log('Error Occured!',error)
            this.messageService.add({ key: 'global-notification', severity: 'error', summary: 'Something Went Wrong' });
          },),
          switchMap(() => {
            return [SettingsActions.updateAllSettingsForSelectedModuleSuccess({payload:action.payload,typeId:action.typeId,module:action.module})]
          }),
    
          catchError(() => of(SettingsActions.errorHandlerForGenricDMSSetting()))
        )
      })
    )
    );




  constructor(
    private actions$: Actions,
    private settings: SettingsService,
    private messageService: MessageService,
    private store: Store
  ) {
  }


}

