<p-dialog [position]="'center'" [transitionOptions]="'250ms'" [dismissableMask]="true" [draggable]="false"
  [(visible)]="isEventFocusedView" (onShow)="onViewChangeOfEventsDialog(false)"
  (onHide)="onViewChangeOfEventsDialog(true)" [modal]="true" [style]="{'width': '100vw', 'height':'95vh'}"
  styleClass="temp-events-dialog">
  <ng-template pTemplate="header">
    <div class="grid mx-0 flex align-items-center">
      <h5 class="m-0">Non-Compliance Events</h5><span [style]="{'font-size': '0.95rem', 'margin-left':'0.55rem'}"> (Consignment
        : {{selectedOrderDeliveryGroup.delvieryGrpSequence}})</span>
    </div>
  </ng-template>
  <ng-template pTemplate="content">
    <div class="temp-events-dialog-content">
      <div class="panel-container temperature-events-map">
        <app-consignment-event-geofence-map [hideLeftActionButtons]="true"
          [currentNonComplianceEvent]="currentNonComplianceEvent"
          (emittRightButtonsAction)="recieveRightButtonsAction($event)" [isRightSidebarClosed]="!isEventFocusedView"
          [reloadMapNumber]='reloadMapNumber' [agentDetails]="agentDetails" [displayPills]='displayPills'
          [eventsToPlot]="data?.eventsToPlot" [geoFences]="geoFences" [liveDataFromSocket]="liveDataFromSocket"
          [mapMarkers]="mapMarkers" [pageName]="pageName" [carGpsHistoricalData]="carGpsHistoricalData"
          [routesDetails]="routesDetails" [trackIcons]="trackIcons" [rowData]="rowData"
          [selectedOrderDeliveryGroup]="selectedOrderDeliveryGroup" />
      </div>
      <div class="events-list-graph-container">
        <div class="table-container">
          <app-non-compliance-events-table [selectedNonComplianceEvent]="selectedNonComplianceEvent" [eventsSubTypes]="eventsSubTypes" [agentDetails]="agentDetails"
            [temperatureEvents]="temperatureEvents" [selectedOrderDeliveryGroup]="selectedOrderDeliveryGroup"
            (emittCurrentEvent)="triggerRowClick($event)" (emittGetEventAction)="emittGetEventAction.emit($event)" />
        </div>
        <div class="graph-data-container">
          <div class="header-title">
            Sensor Stats
          </div>
          <app-car-status-graph [chartData]="graphData" [forceChartHeightInPx]="'340px'"
            [isDataZoomRequired]='true' [isSmallEChart]="false" [isTotalNotRequired]='true'
            [loader]="boxStats?.showLoader" [showTitleWithCounts]="true" [show]="boxStats?.show"
            (sendDataToPlotMarker)="sendDataToPlotMarker($event)" [updateValue]="graphUpdateValue" />
        </div>
      </div>
    </div>
  </ng-template>
</p-dialog>

@if(showLoader){
  <app-loader />
  }