import { createReducer,
   on
   } from "@ngrx/store";
import { OrderDocumentsActions } from ".";

export interface OrderDocumentsState {
  documents:any
  uploadStatus:string,
  statusConfig:any
}


export const initialState: OrderDocumentsState = {
  documents:[],
  uploadStatus:'',
  statusConfig:{}
};

export const OrderDocumentsReducer = createReducer(
  initialState,

  on(
    OrderDocumentsActions.getOrderDocumentsSuccess,
    (state, { documents }) => {
      return {
        ...state,
        documents: documents,
      };
    }
  ),
  on(
    OrderDocumentsActions.orderDocumentsActionSuccess,
    (state, { statutsKey }) => {
      return {
        ...state,
        uploadStatus: statutsKey,
      };
    }
  ),
  on(
    OrderDocumentsActions.setCurrentUploadDocumentStatus,
    (state, { statusConfig }) => {
      return {
        ...state,
        statusConfig: statusConfig,
      };
    }
  ),
);
