import { Injectable } from '@angular/core';
import { EChartDataType, EChartSeries } from '../../../app/shared/e-charts/e-charts-data-type';
import { visualMaps } from '../../../state/dashboard/dashboard/dashboard.helper.service';
import { CONSTANT } from '../../../config/constant';
import { cloneDeep, forEach, groupBy, keys, map, orderBy } from 'lodash';
import moment from 'moment';
const MMD_MODULE = CONSTANT.DMS_MODULES.MMD.MODULE;
const LMD_MODULE = CONSTANT.DMS_MODULES.LMD.MODULE;
@Injectable({
  providedIn: 'root'
})
export class ConsignmentEventGeofenceGraphService {

  constructor() { }

  public varyingEChartGraphData: EChartDataType = {
    series: [],
    legend: [],
    type: 'line',
    title: 'Stats',
    xAxis: [],
    visualMap: visualMaps,
    isDataZoomRequired: true
  };

  drawEChars(label, series, moduleName) {

    const type = 'line';
    if (moduleName) {
      if (moduleName === MMD_MODULE) {
        this.varyingEChartGraphData.title = 'Sensor Stats';
      } else if (moduleName === LMD_MODULE) {
        this.varyingEChartGraphData.title = 'Box Stats';
      }
    }

    this.varyingEChartGraphData.type = type;
    const legend = keys(series);
    this.varyingEChartGraphData.legend = map(legend, function (seriesName) {
      let seriesNameStr: string;
      switch (seriesName) {
        case 'temp':
          seriesNameStr = 'Temp in °C';
          break;
        case 'humidity':
          seriesNameStr = 'Humidity in %';
          break;
      }
      return seriesNameStr;
    });

    this.varyingEChartGraphData.legendToDisable = 'Humidity in %';


    this.varyingEChartGraphData.series = map(legend, function (seriesName) {
      let eChartSeries: EChartSeries;
      let chartColor: string;
      let seriesNameStr: string;
      switch (seriesName) {
        case 'temp':
          chartColor = '#ffc107';
          seriesNameStr = 'Temp in °C';
          break;
        case 'humidity':
          chartColor = '#28a745';
          seriesNameStr = 'Humidity in %';
          break;
      }
      if (chartColor) {

      }
      eChartSeries = {
        data: series[seriesName],
        type: type,
        name: seriesNameStr,
        stack: false,
        smooth: false,
        label: { normal: { show: true, position: 'top' } },
      };
      return eChartSeries;
    });


    this.varyingEChartGraphData.xAxis = label;
    return cloneDeep(this.varyingEChartGraphData)
  }

  dateFormator(date, format?) {
    let dateFormate = moment(date).format('MMMM Do YYYY, h:mm a');
    if (format) {
      if (format === 'date') {
        dateFormate = moment(date).format('MMMM Do YYYY, h:mm a');
      } else if (format === 'time') {
        dateFormate = moment(date).format('h:mm:ss a');
      } else if (format === 'lll') {
        dateFormate = moment(date).format('LLL');
      }
    }
    return dateFormate;
  }

  plotEmptyGraph(moduleName,hideHumidity) {
    const xAxisByStatus = {
      temp: [],
      humidity: []
    };
    if(hideHumidity){
      delete xAxisByStatus.humidity
    }
    const yAxis = [];

    return this.drawEChars(yAxis, xAxisByStatus, moduleName);
  }

  plotGraph(result, moduleName,hideHumidity) {
    if (result && result.length > 0) {
      const groupByDate = groupBy(result, 'updated');
      const data = groupByDate;
      const yAxisKeys = keys(data);
      const yAxis = [];
      const xAxisByStatus = {
        temp: [],
        humidity: []
      };
      if(hideHumidity){
        delete xAxisByStatus.humidity
      }
      forEach(yAxisKeys, (dateString) => {
        const allStatusDetails = data[dateString];
        forEach(allStatusDetails, (countByStatus) => {
          if (countByStatus.sensorData) {
            xAxisByStatus.temp.push(countByStatus.sensorData.temperature || countByStatus.sensorData.temperature == 0 ? (countByStatus.sensorData.temperature).toFixed(2) : null);
            !xAxisByStatus.humidity && xAxisByStatus.humidity?.push(countByStatus.sensorData.humidity || countByStatus.sensorData.humidity == 0 ? (countByStatus.sensorData.humidity).toFixed(2) : null);
            yAxis.push(this.dateFormator(dateString, 'lll'));
          }
        });
      });
      return this.drawEChars(yAxis, xAxisByStatus, moduleName)
    }
    return this.plotEmptyGraph(moduleName,hideHumidity);
  }

  buildGraph(deviceId,d,moduleName,hideHumidity=false) {
    if (deviceId) {
      const data = orderBy(d, ['updated'], ['asc']);
      return this.plotGraph(data,moduleName,hideHumidity);
    } 
      return this.plotEmptyGraph(moduleName,hideHumidity);
  }

}
