import {BrowserModule, provideClientHydration, withNoHttpTransferCache} from '@angular/platform-browser';
import {APP_INITIALIZER, ErrorHandler, isDevMode, NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi} from '@angular/common/http';
import {CommonModule, HashLocationStrategy, LocationStrategy, NgOptimizedImage} from '@angular/common';
import {AbilityModule} from '@casl/angular';
import {Ability, PureAbility} from '@casl/ability';
import {FormsModule} from '@angular/forms';
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {DragDropModule} from '@angular/cdk/drag-drop';

import {
  CalendarDateFormatter,
  CalendarModule,
  CalendarMomentDateFormatter,
  DateAdapter,
  MOMENT,
} from 'angular-calendar';
import {adapterFactory} from 'angular-calendar/date-adapters/moment';
import moment from 'moment';

import {AppRoutingModule} from './app-routing.module';
import {MaterialModule} from './shared/material.module';
import {CoreModule} from './core/core.module';
import {AuthGuard} from '../auth/auth.guard';

/*------------------sentry----------------------*/
import {Router} from '@angular/router';
import * as Sentry from '@sentry/angular';
/*------------------Services----------------------*/
import {AssetService} from '../services/asset.service';
import {ServiceLinkableService} from '../services/serviceLinkable.service';
import {FormService} from '../services/form.service';
import {AssetGroupService} from '../services/asset.group.service';
import {ExceptionService} from '../services/exception.service';
import {AssetConfigService} from '../services/asset-config.service';
import {SearchService} from '../services/search.service';
import {GeofenceService} from '../services/geofence.service';
import {ValidityCheckService} from '../services/validity-check.service';
import {SubscriptionService} from '../services/subscription.service';
import {PagerService} from '../services/pager.service';
import {ConfigService} from '../services/config.service';
import {CommonService} from '../services/common.service';
import {MyProfileManagementService} from '../services/my-profile-management.service';
import {MapsService} from '../services/maps.service';
import {SetCssPropertiesService} from '../services/set-css-properties.service';
import {HTTPListener} from '../interceptors/HttpInterceptor';
import {BeaconGatewayService} from '../services/beacon-gateway.service';
import {DateFormatterService} from '../services/dateformat';
import {HealthCheckService} from '../services/healthCheck.service';
import {TrackingService} from '../services/tracking.service';
import {RfidGatewayService} from '../services/rfid-gateway.service';
import {LoginServiceService} from '../services/login-service.service';
import {MaintenanceService} from '../services/maintenance.service';
import {FaceMatchService} from '../services/face-match.service';
import {FaceDetectionService} from '../services/face-detection.service';
import {DateRangePickerService} from '../services/date-range-picker.service';
import {LinkedAssetIconService} from '../services/linked-asset-icon.service';
import {SplitScreenService} from '../services/split-screen.service';
import {ThemeService} from '../services/theme.service';
import {RoleManagementService} from '../services/roleManagement.service';
import {AttendanceService} from '../services/attendance.service';
import {EventManagementService} from '../services/eventManagement.service';
import {OrderStatusColorValuesService} from '../services/order-status-color-values.service';
import {AssetDataModifyService} from '../services/asset-data-modify.service';

/*------------------Components----------------------*/
import {AppComponent} from './app.component';
import {ForbiddenComponent} from './shared/forbidden/forbidden.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {AppMainComponent} from './core/main/app.main.component';
import {AppMenuComponent} from './core/menu/app.menu.component';
import {AppMenuitemComponent} from './core/app.menuitem.component';
import {AppConfigComponent} from './core/config/app.config.component';
import {AppTopBarComponent} from './core/topbar/app.topbar.component';
import {AppFooterComponent} from './core/footer/app.footer.component';
import {AppRightPanelComponent} from './core/right-panel/app.rightpanel.component';
import {AppMegamenuComponent} from './core/mega-menu/app.megamenu.component';
import {AppProfileComponent} from './core/profile/app.profile.component';
import {AppBreadcrumbComponent} from './core/breadcrumb/app.breadcrumb.component';
import {MenuService} from './core/menu/app.menu.service';
import {BreadcrumbService} from './core/breadcrumb/app.breadcrumb.service';
import { ResetPasswordComponent } from './reset-password/reset-password.component';

import {AccordionModule} from 'primeng/accordion';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {AvatarModule} from 'primeng/avatar';
import {AvatarGroupModule} from 'primeng/avatargroup';
import {BreadcrumbModule} from 'primeng/breadcrumb';
import {ButtonModule} from 'primeng/button';
import {CardModule} from 'primeng/card';
import {CarouselModule} from 'primeng/carousel';
import {CascadeSelectModule} from 'primeng/cascadeselect';
import {ChartModule} from 'primeng/chart';
import {CheckboxModule} from 'primeng/checkbox';
import {ChipModule} from 'primeng/chip';
import {ChipsModule} from 'primeng/chips';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ConfirmPopupModule} from 'primeng/confirmpopup';
import {ColorPickerModule} from 'primeng/colorpicker';
import {ContextMenuModule} from 'primeng/contextmenu';
import {DataViewModule} from 'primeng/dataview';
import {DialogModule} from 'primeng/dialog';
import {DividerModule} from 'primeng/divider';
import {DropdownModule} from 'primeng/dropdown';
import {FieldsetModule} from 'primeng/fieldset';
import {FileUploadModule} from 'primeng/fileupload';
import {GalleriaModule} from 'primeng/galleria';
import {ImageModule} from 'primeng/image';
import {InplaceModule} from 'primeng/inplace';
import {InputNumberModule} from 'primeng/inputnumber';
import {InputMaskModule} from 'primeng/inputmask';
import {InputSwitchModule} from 'primeng/inputswitch';
import {InputTextModule} from 'primeng/inputtext';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {KnobModule} from 'primeng/knob';
import {ListboxModule} from 'primeng/listbox';
import {MegaMenuModule} from 'primeng/megamenu';
import {MenuModule} from 'primeng/menu';
import {MenubarModule} from 'primeng/menubar';
import {MessagesModule} from 'primeng/messages';
import {MessageModule} from 'primeng/message';
import {MultiSelectModule} from 'primeng/multiselect';
import {OrderListModule} from 'primeng/orderlist';
import {OrganizationChartModule} from 'primeng/organizationchart';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {PaginatorModule} from 'primeng/paginator';
import {PanelModule} from 'primeng/panel';
import {PanelMenuModule} from 'primeng/panelmenu';
import {PasswordModule} from 'primeng/password';
import {PickListModule} from 'primeng/picklist';
import {ProgressBarModule} from 'primeng/progressbar';
import {RadioButtonModule} from 'primeng/radiobutton';
import {RatingModule} from 'primeng/rating';
import {RippleModule} from 'primeng/ripple';
import {ScrollPanelModule} from 'primeng/scrollpanel';
import {ScrollTopModule} from 'primeng/scrolltop';
import {SelectButtonModule} from 'primeng/selectbutton';
import {SidebarModule} from 'primeng/sidebar';
import {SkeletonModule} from 'primeng/skeleton';
import {SlideMenuModule} from 'primeng/slidemenu';
import {SliderModule} from 'primeng/slider';
import {SplitButtonModule} from 'primeng/splitbutton';
import {SplitterModule} from 'primeng/splitter';
import {StepsModule} from 'primeng/steps';
import {TabMenuModule} from 'primeng/tabmenu';
import {TableModule} from 'primeng/table';
import {TabViewModule} from 'primeng/tabview';
import {TagModule} from 'primeng/tag';
import {BadgeModule} from 'primeng/badge';
import {TerminalModule} from 'primeng/terminal';
import {TieredMenuModule} from 'primeng/tieredmenu';
import {TimelineModule} from 'primeng/timeline';
import {ToastModule} from 'primeng/toast';
import {ToggleButtonModule} from 'primeng/togglebutton';
import {ToolbarModule} from 'primeng/toolbar';
import {TooltipModule} from 'primeng/tooltip';
import {TreeModule} from 'primeng/tree';
import {TreeTableModule} from 'primeng/treetable';
import {VirtualScrollerModule} from 'primeng/virtualscroller';
import {SpeedDialModule} from "primeng/speeddial";
import {EditorModule} from 'primeng/editor';


// Application Components
import {AppLowerMenu} from './core/app.lower.menu.component';

import {ConfirmationService, MessageService} from 'primeng/api';
import {StoreModule} from '@ngrx/store';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {EffectsModule} from '@ngrx/effects';
import {LoginEffects} from '../state/Login/login.effects';
import {environment} from '../environments/environment';
import {LoginReducer} from '../state/Login/login.reducer';
import {ProfileReducer} from '../state/Profile/profile.reducer';
import {ProfileEffects} from '../state/Profile/profile.effects';
import {SocketReducer} from '../state/Socket/socket.reducer';
import {SocketEffects} from '../state/Socket/socket.effects';
import {KioskReducer} from '../state/Kiosk/kiosk.reducer';
import {KioskEffects} from '../state/Kiosk/kiosk.effects';
import {FleetReducer} from '../state/Fleet/fleet.reducer';
import {FleetEffects} from '../state/Fleet/fleet.effects';
import {AssetsReducers} from '../state/Assets/assets.reducer';
import {AssetsEffects} from '../state/Assets/assets.effects';
import {UserReducers} from '../state/User/user.reducer';
import {UserEffects} from '../state/User/user.effects';
import {ServiceWorkerModule} from '@angular/service-worker';
import {O2dAssignmentService} from './o2d/services/o2d-assignment.service';
import {TimelineReducer} from '../state/Timeline/timeline.reducer';
import {TimelineEffects} from '../state/Timeline/timeline.effects';
import {ETARequiredReducer} from '../state/ETACalculation/ETACalculation.reducer';
import {ETACalculationEffect} from '../state/ETACalculation/ETACalculation.effects';
import {SidebarReducer} from '../state/Sidebar/sidebar.reducer';
import {SettingsEffects} from '../state/Settings/settings.effects';
import {SettingsReducer} from '../state/Settings/settings.reducer';
import {KioskStoreTabMenuComponent} from './kiosk-store-tab-menu/kiosk-store-tab-menu.component';
import {SuperAdminSettingsEffects} from '../state/SuperAdminSettings/superadminsettings.effects';
import {SuperAdminSettingsReducer} from '../state/SuperAdminSettings/superadminsettings.reducer';
import {WebcamModule} from 'ngx-webcam';

import {NotificationEffects} from '../state/Notification/notification.effects'
import {NotificationReducer} from '../state/Notification/notification.reducer';

import {DashboardEffect} from '../state/dashboard/dashboard/dashboard.effects';
import {dashboardReducer} from '../state/dashboard/dashboard/dashboard.reducer';
import {HttpLoaderFactory} from "../config/translation.config";
import {CustomerCollaborationReducer} from '../state/CustomerCollaboration/customer.collaboration.reducer';
import {CustomerCollaborationEffects} from '../state/CustomerCollaboration/customer.collaboration.effects';
import {CommunicationReducer} from '../state/communication/communication.reducer';
import {CommunicationEffects} from '../state/communication/communication.effects';
import {ReportsSubscriptionEffects} from '../state/ReportsSubscription/reports.subscription.effects';
import {ReportsSubscriptionReducer} from '../state/ReportsSubscription/reports.subscription.reducer';
import {WarehouseReducer} from '../state/Warehouse/warehouse.reducer';
import {WarehouseEffects} from '../state/Warehouse/warehouse.effects';
import { OrderDocumentsReducer } from '../state/Order Documents/order.documents.reducer';
import { OrderDocumentsEffects } from '../state/Order Documents/order.documents.effects';

const RxJS_Services = [HTTPListener];


// -------------prime ng------------
export function momentAdapterFactory() {
  return adapterFactory(moment);
}

@NgModule({
  declarations: [
    AppComponent,
    ForbiddenComponent,
    DashboardComponent,
    AppMainComponent,
    AppMenuComponent,
    AppMenuitemComponent,
    AppConfigComponent,
    AppTopBarComponent,
    AppFooterComponent,
    AppRightPanelComponent,
    AppMegamenuComponent,
    AppProfileComponent,
    AppBreadcrumbComponent,
    AppLowerMenu,
    KioskStoreTabMenuComponent,
    ResetPasswordComponent
  ],
  imports: [
    Sentry.TraceModule,
    BrowserModule,
    CommonModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient, ConfigService],
      },
    }),
    AppRoutingModule,
    FormsModule,
    AbilityModule,
    MaterialModule,
    CoreModule,
    CalendarModule.forRoot(
      {provide: DateAdapter, useFactory: momentAdapterFactory},
      {
        dateFormatter: {
          provide: CalendarDateFormatter,
          useClass: CalendarMomentDateFormatter,
        },
      }
    ),
    AccordionModule,
    AutoCompleteModule,
    AvatarGroupModule,
    AvatarModule,
    BadgeModule,
    BreadcrumbModule,
    ButtonModule,
    CalendarModule,
    CardModule,
    CarouselModule,
    CascadeSelectModule,
    ChartModule,
    CheckboxModule,
    ChipModule,
    ChipsModule,
    ConfirmDialogModule,
    ConfirmPopupModule,
    ColorPickerModule,
    ContextMenuModule,
    DataViewModule,
    DialogModule,
    DividerModule,
    DropdownModule,
    FieldsetModule,
    FileUploadModule,
    GalleriaModule,
    ImageModule,
    InplaceModule,
    InputNumberModule,
    InputMaskModule,
    InputSwitchModule,
    InputTextModule,
    InputTextareaModule,
    KnobModule,
    ListboxModule,
    MegaMenuModule,
    MenuModule,
    MenubarModule,
    MessageModule,
    MessagesModule,
    MultiSelectModule,
    OrderListModule,
    OrganizationChartModule,
    OverlayPanelModule,
    PaginatorModule,
    PanelModule,
    PanelMenuModule,
    PasswordModule,
    PickListModule,
    ProgressBarModule,
    RadioButtonModule,
    RatingModule,
    RippleModule,
    ScrollPanelModule,
    ScrollTopModule,
    SelectButtonModule,
    SidebarModule,
    SkeletonModule,
    SlideMenuModule,
    SliderModule,
    SplitButtonModule,
    SplitterModule,
    StepsModule,
    TableModule,
    TabMenuModule,
    TabViewModule,
    TagModule,
    TerminalModule,
    TieredMenuModule,
    TimelineModule,
    ToastModule,
    ToggleButtonModule,
    ToolbarModule,
    TooltipModule,
    TreeModule,
    TreeTableModule,
    VirtualScrollerModule,
    SpeedDialModule,
    EditorModule,
    DragDropModule,
    WebcamModule,
    StoreModule.forRoot({
      login: LoginReducer,
      userProfile: ProfileReducer,
      sockets: SocketReducer,
      kiosk: KioskReducer,
      fleet: FleetReducer,
      assets: AssetsReducers,
      user: UserReducers,
      timeline: TimelineReducer,
      etaRequired: ETARequiredReducer,
      sidebar: SidebarReducer,
      settings: SettingsReducer,
      notification: NotificationReducer,
      superAdminSettings: SuperAdminSettingsReducer,
      dashboard: dashboardReducer,
      customerCollaboration: CustomerCollaborationReducer,
      orderDocuments:OrderDocumentsReducer,
      communication: CommunicationReducer,
      warehouse: WarehouseReducer,
      reportsSubscription: ReportsSubscriptionReducer
    }),
    StoreDevtoolsModule.instrument({
      trace: true,
      name: "iotzen-platform",
      maxAge: 25,
      logOnly: isDevMode(),
    }),
    EffectsModule.forRoot([
      LoginEffects,
      ProfileEffects,
      SocketEffects,
      KioskEffects,
      FleetEffects,
      AssetsEffects,
      UserEffects,
      TimelineEffects,
      ETACalculationEffect,
      SettingsEffects,
      DashboardEffect,
      SuperAdminSettingsEffects,
      NotificationEffects,
      CustomerCollaborationEffects,
      OrderDocumentsEffects,
      CommunicationEffects,
      WarehouseEffects,
      ReportsSubscriptionEffects
    ]),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      registrationStrategy: 'registerWhenStable:30000'
    }),
    NgOptimizedImage,
  ],
  schemas: [NO_ERRORS_SCHEMA],
  providers: [
    MessageService,
    AssetService,
    ServiceLinkableService,
    FormService,
    PagerService,
    AssetGroupService,
    AssetConfigService,
    ConfigService,
    CommonService,
    ExceptionService,
    TranslateService,
    RxJS_Services,
    BeaconGatewayService,
    SetCssPropertiesService,
    AttendanceService,
    RfidGatewayService,
    HealthCheckService,
    TrackingService,
    RoleManagementService,
    SearchService,
    ValidityCheckService,
    SubscriptionService,
    LoginServiceService,
    MyProfileManagementService,
    MapsService,
    MaintenanceService,
    DateRangePickerService,
    AuthGuard,
    GeofenceService,
    FaceMatchService,
    FaceDetectionService,
    LinkedAssetIconService,
    SplitScreenService,
    ThemeService,
    EventManagementService,
    DateFormatterService,
    OrderStatusColorValuesService,
    AssetDataModifyService,
    O2dAssignmentService,
    MenuService,
    BreadcrumbService,
    ConfirmationService,
    provideHttpClient(withInterceptorsFromDi()),
    provideClientHydration(withNoHttpTransferCache()),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HTTPListener,
      multi: true,
    },
    {provide: LocationStrategy, useClass: HashLocationStrategy},
    {provide: Ability, useValue: new Ability()},
    {provide: PureAbility, useExisting: Ability},
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: ApplicationInitializerFactory,
    //   deps: [TranslateService, Injector],
    //   multi: true
    // },
    {provide: MOMENT, useValue: moment},
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
      },
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  exports: [],
})
export class AppModule {
}
