/* eslint-disable no-case-declarations */
import {createReducer, on} from '@ngrx/store';
import {AssetsActions} from '.';
import {ICity, ILocation, ILogisticProvider, IRootAssetTypes} from './interfaces/IAsset.types.modal';
import {IOrderAsset, IOrderAssets} from './interfaces/IAsset.order.modal';
import {CONSTANT} from '../../config/constant';
import {ISensorAsset} from './interfaces/IAsset.sensor.modal';
import {IDeliveryPersonAsset} from './interfaces/IAsset.deliveryPerson.modal';
import {IProductAsset} from './interfaces/IAsset.product.model';
import {IColdChainBoxAsset} from './interfaces/IAsset.coldChainBox.modal';
import {AssetImageConfigHolder, Assets, AssetsCounts, GroupHolder, IAssetCardImgStatus, } from './interfaces/Assets.modal';
import {IDeliveryCenterAsset} from './interfaces/IAsset.deliveryCenter.modal';
import {IMobileAsset} from './interfaces/IAsset.mobile.modal';
import {IZoneAsset} from './interfaces/IAsset.zone.modal';
import {IOderTableHeaderData} from './interfaces/IOrder.table.header';
import {IConsignmentTableHeaderData} from './interfaces/IConsignment.table.header';
import {IVehicleTableHeaderData} from './interfaces/IVehicle.table.header';
import {ITimeSlots} from './interfaces/ITimeSlots.modal';
import {IVehicleAsset} from './interfaces/IAsset.vehicle.modal';
import {
  constructPickupCityFilterIfNotPresent,
  getCarrierTableFilter,
  getHydratedCarrierTableFilter,
  groupByCategory,
  handleTableColumnsToDisplay,
  handleTableColumnsToDisplayInMobileView,
  handleTableColumnsToDisplayInMobilewithoutSetting,
  handleTableheaderForCustCollab,
  isMobileView,
  moveElement,
  setSequence,
  updateDateRangeFieldInConfig,
  updateDeliveryCenter,
  updateDeliverySlots,
  updateLinkedAssets,
  updateLocation,
  updateLogistic,
  updatePickUp,
  updateRoutePlans,
  updateAllVehicle
} from './reducer.helper.service';
import {FilterMetadata, Message} from 'primeng/api';
import {ILmdOderTableHeaderData} from './interfaces/ILmdOrder.table.header';
import {IAgentTableHeaderData} from './interfaces/IAgent.table.header';
import {IAllAssignedCarriersData} from './interfaces/IAllAssignedCarriers.model';
import {IAllDeliveryData} from './interfaces/IAllDeliveries.model';
import {IExpansionConsignmentHeader} from './interfaces/IExpansionConsignmentHeader.model';
import {ICategory} from './interfaces/IAsset.category.model';
import {
  IKioskCDSTableHeaderData,
  IKioskKDSTableHeaderData,
  IKioskOrderTableHeaderData,
  IKioskProductsTableHeaderData,
  IKioskTableHeaderData,
  IKioskTodayOrderTableHeaderData
} from './interfaces/IAsset.kiosk';
import {IRoutePlan, IRoutePlanTableHeaders} from './interfaces/I-RoutePlan.table.header';
import {
  ILmdManagementTableHeaderData,
  IMmdManagementTableHeaderData
} from './interfaces/IProgressDeliveredHeader.model';
import { ICommunicationTableHeaders, ICurrentPageStateAsset } from './interfaces/ICommunication.table.header';
import { IWarehouseTableHeaderData } from './interfaces/IWarehouseSupply.table.header';
import { IBayAsset } from './interfaces/IAsset.bay.modal';
import { IAisleAsset } from './interfaces/IAsset.aisle.modal';
import { IScheduleTableHeaders } from './interfaces/ISchedule.table.header';

const CURRENT_LIMIT_PER_PAGE = CONSTANT.ASSET.CURRENT_LIMIT_PER_PAGE;

export interface AssetsState {
  tableBody:Record<string,IOrderAssets>;
  counts: AssetsCounts | undefined;
  selectedAssetData: Assets[];
  selectedAssetType: string;
  assetDefaultImageConfigs: AssetImageConfigHolder | undefined;
  assetLinkedGroups: GroupHolder | undefined;
  hasLoaded: string;
  tableFilterMetas: Record<string, FilterMetadata> | undefined;
  assetConfig: IRootAssetTypes[];
  city: ICity[];
  mmdCities: ICity[];
  lmdCities: ICity[];
  logisticProvider: ILogisticProvider[];
  location: ILocation[];
  order: IOrderAsset[];
  sensor: ISensorAsset[];
  deliveryPerson: IDeliveryPersonAsset[];
  vehicle: IVehicleAsset[];
  product: IProductAsset[];
  coldChainBox: IColdChainBoxAsset[];
  deliveryCenter: IDeliveryCenterAsset[];
  mobile: IMobileAsset[];
  zones: IZoneAsset[];
  bay: IBayAsset[];
  aisle: IAisleAsset[];
  tableHeaderData: IOderTableHeaderData;
  tableHeaderO2DOrders: IOderTableHeaderData;
  tableHeaderO2DPending: IOderTableHeaderData;
  tableHeaderO2DProcessing: IOderTableHeaderData;
  tableHeaderO2DDispatch: IOderTableHeaderData;
  tableHeaderO2DProgress: IOderTableHeaderData;
  consignmentTableHeaderData: IConsignmentTableHeaderData;
  orderTableHeaderData: ILmdOderTableHeaderData
  mmdConsignmentTableHeaderData: IConsignmentTableHeaderData;
  mmdConsignmentAssignTableHeaderData: IConsignmentTableHeaderData;
  mmdConsignmentQueueTableHeaderData: IConsignmentTableHeaderData;
  vehicleTableHeaderData: IVehicleTableHeaderData;
  mmdVehicleAssignTableHeaderData: IVehicleTableHeaderData;
  mmdVehicleQueueTableHeaderData: IVehicleTableHeaderData;
  lmdOrderTableHeaderData: ILmdOderTableHeaderData;
  lmdOrderQueueTableHeaderData: ILmdOderTableHeaderData;
  lmdOrderAssignTableHeaderData: ILmdOderTableHeaderData;
  agentTableHeaderData: IAgentTableHeaderData;
  routePlanTableHeader: Partial<IRoutePlanTableHeaders>;
  scheduleTableHeader: Partial<IScheduleTableHeaders>;
  allScheduleTableHeader: Partial<IScheduleTableHeaders>;
  todayScheduleTableHeader: Partial<IScheduleTableHeaders>;
  lmdAgentAssignTableHeaderData: IAgentTableHeaderData;
  timeSlots: ITimeSlots[];
  testError: Error | undefined;
  categories: ICategory[]
  temp: unknown[];
  carrierData: IAllAssignedCarriersData[];
  deliveriesData: IAllDeliveryData[];
  expansionRowTableHeadings: IExpansionConsignmentHeader;
  categoryReordered: Message
  kioskTableHeaderData: IKioskTableHeaderData;
  kioskOrderTableHeaderData: IKioskOrderTableHeaderData;
  kioskTodayOrderTableHeaderData: IKioskTodayOrderTableHeaderData;
  kioskCDSTableHeaderData: IKioskCDSTableHeaderData;
  kioskKDSTableHeaderData: IKioskKDSTableHeaderData;
  kioskProductsTableHeaderData: IKioskProductsTableHeaderData;
  isMobileView: boolean;
  linkedAssets: Record<string, unknown>
  mmdManagementTableHeaderData: IMmdManagementTableHeaderData;
  lmdManagementTableHeaderData: ILmdManagementTableHeaderData;
  routePlanCount: number;
  routePlans: IRoutePlan[];
  groupedRoutePlans:any
  geoFenceForAssetData;
  assetCardsImageDisplayStatus:Record<string, IAssetCardImgStatus>;
  isActionButtonNeededInRoutePlan:boolean;
  communicationTableHeader: ICommunicationTableHeaders;
  menu:[];
  currentPageNumber:ICurrentPageStateAsset;
  warehouseTableHeader: IWarehouseTableHeaderData;
  warehouseRequisitionTableHeader: IWarehouseTableHeaderData;
  warehouseFulfillmentTableHeader: IWarehouseTableHeaderData;
  warehouseSupplyTableHeader: IWarehouseTableHeaderData;
  warehouseProgressTableHeader: IWarehouseTableHeaderData;
  warehouseOutboundTableHeader: IWarehouseTableHeaderData;
  multipleAssetCreationStatus: number;
  allSchedules: any
  todaySchedules: any,
  conflictingSchedules:any
}

const initialState: AssetsState = {
  tableBody: {},
  carrierData: undefined,
  deliveriesData: [],
  counts: undefined,
  selectedAssetData: [],
  selectedAssetType: undefined,
  assetDefaultImageConfigs: undefined,
  assetLinkedGroups: undefined,
  hasLoaded: 'notLoaded',
  tableFilterMetas: undefined,
  timeSlots: [],
  assetConfig: [],
  city: [],
  mmdCities: [],
  lmdCities: [],
  logisticProvider: [],
  location: [],
  order: [],
  sensor: [],
  deliveryPerson: [],
  vehicle: [],
  product: [],
  coldChainBox: [],
  deliveryCenter: [],
  mobile: [],
  zones: [],
  bay: [],
  aisle: [],
  categories: [],
  categoryReordered: {},
  expansionRowTableHeadings: {
    delvieryGrpSequence: {
      columnDisplayName: 'app.tableHeader.id',
      filterFieldName: 'delvieryGrpSequence',
      isFilterable: false,
      isSortable: false,
      isColumnSeen: true,
      isContentArray: false,
    },
    carrier: {
      columnDisplayName: 'app.tableHeader.carrier',
      filterFieldName: 'carrier',
      isFilterable: true,
      filterMenuHeaderText: 'app.tableHeader.selectCarrier',
      filterMenuType: 'multiSelectFromOptions',
      availableOptionsKeysList: [],
      availableOptions: {},
      availableOptionsArray: [],
      isSortable: false,
      isColumnSeen: true,
      isContentArray: false,
    },
    routePlans: {
      columnDisplayName: 'app.tableHeader.routePlans',
      filterFieldName: 'routePlans',
      isFilterable: true,
      filterMenuHeaderText: 'app.tableHeader.selectRoutePlans',
      filterMenuType: 'treeSelectFromOptions',
      availableOptionsKeysList: [],
      availableOptions: {},
      availableOptionsArray: [],
      isColumnSeen: true,
      isContentArray: false,
      groupRoutePlanArray:[],
    },
    batchSize: {
      columnDisplayName: 'app.tableHeader.deliveries',
      filterFieldName: 'batchSize',
      isFilterable: false,
      isSortable: false,
      isColumnSeen: true,
      isContentArray: false,
    },
    pickupCity: {
      columnDisplayName: 'app.tableHeader.pickupCity',
      filterFieldName: 'pickupCity',
      isFilterable: true,
      isSortable: true,
      isColumnSeen: true,
      filterMenuHeaderText: 'app.tableHeader.selectPickupCity',
      filterMenuType: 'multiSelectFromOptions',
      availableOptionsKeysList: [],
      availableOptions: {},
      availableOptionsArray: [],
      isContentArray: false,
      prevSelectedCities: [],
    },
    mobileNo: {
      columnDisplayName: 'app.tableHeader.registrationNumber',
      filterFieldName: 'mobileNo',
      isFilterable: false,
      isSortable: false,
      isColumnSeen: true,
      isContentArray: false,
    },
    creationDate: {
      columnDisplayName: 'app.tableHeader.creationDate',
      filterFieldName: 'creationDate',
      isFilterable: true,
      isSortable: true,
      isColumnSeen: true,
      filterMenuHeaderText: 'Select date range',
      filterMenuType: 'dateRange',
      startDate: new Date(),
      endDate: new Date(),
      isContentArray: false,
    },
    status: {
      columnDisplayName: 'app.tableHeader.orderStatus',
      filterFieldName: 'status',
      isFilterable: true,
      isSortable: false,
      isColumnSeen: true,
      filterMenuHeaderText: 'Select Status',
      filterMenuType: 'multiSelectFromOptions',
      availableOptionsKeysList: ['Pending', 'Returning', 'Archived', 'Completed'],
      availableOptions: {
        Pending: {filterParameterValue: 'pending'},
        Returning: {filterParameterValue: 'returning'},
        Archived: {filterParameterValue: 'archived'},
        Completed: {filterParameterValue: 'completed'}
      },
      availableOptionsArray: [
        {name: 'Pending', filterParameterValue: 'pending'},
        {name: 'Returning', filterParameterValue: 'returning'},
        {name: 'Archived', filterParameterValue: 'archived'},
        {name: 'Completed', filterParameterValue: 'completed'},
      ],
      isContentArray: false,
    },
    action: {
      columnDisplayName: "app.tableHeader.action",
      filterFieldName: 'action',
      isFilterable: false,
      isSortable: false,
      isColumnSeen: true,
      isContentArray: false,
      isNamingConventionReq: true,
      showIconList: true,
      iconsList: []
    }
  },
  tableHeaderData: {
    orderId: {
      columnDisplayName: 'app.tableHeader.orderId',
      filterFieldName: 'orderId',
      isFilterable: false,
      isSortable: true,
      isColumnSeen: true,
      isContentArray: false,
      isClickable: true,
    },
    created: {
      columnDisplayName: "app.tableHeader.created",
      filterFieldName: "created",
      isFilterable: true,
      isSortable: true,
      isColumnSeen: true,
      filterMenuHeaderText: "Select date range",
      filterMenuType: "dateRange",
      startDate: new Date(),
      endDate: new Date(),
      isContentArray: false,
      isDefaultFilter: false,
    },
    custDetails: {
      columnDisplayName: 'app.tableHeader.custDetails',
      filterFieldName: 'custDetails',
      isFilterable: false,
      isSortable: true,
      isColumnSeen: true,
      isContentArray: true,
    },
    deliveryCenterDetails: {
      columnDisplayName: 'app.tableHeader.custDetails',
      filterFieldName: 'deliveryCenterDetails',
      isFilterable: true,
      isSortable: true,
      isColumnSeen: true,
      isContentArray: true,
      filterMenuHeaderText: 'Select',
      filterMenuType: 'multiSelectFromOptions',
      availableOptionsKeysList: [],
      availableOptions: {},
      availableOptionsArray: [],
    },
    invoiceNumber: {
      columnDisplayName: 'app.tableHeader.invoiceNumber',
      filterFieldName: 'invoiceNumber',
      isFilterable: false,
      isSortable: true,
      isColumnSeen: true,
      isContentArray: true,
      isClickable: false,
    },
    invoiceDate: {
      columnDisplayName: 'app.tableHeader.invoiceDate',
      filterFieldName: 'invoiceDate',
      isFilterable: true,
      isSortable: true,
      isColumnSeen: false,
      isContentArray: false,
      isClickable: false,
      filterMenuHeaderText: 'Select date range',
      filterMenuType: 'dateRange',
      isRemovalDate: true,
    },
    noofCases: {
      columnDisplayName: 'app.tableHeader.noofCases',
      filterFieldName: 'noofCases',
      isFilterable: false,
      isSortable: false,
      isColumnSeen: true,
      isContentArray: false,
      isClickable: false,
    },
    deliveryCode:{
      columnDisplayName: 'app.tableHeader.deliveryCode',
      filterFieldName: 'deliveryCode',
      isFilterable: false,
      isSortable: false,
      isColumnSeen: true,
      isContentArray: false,
      isClickable: false,
    },
    customerCollabrationStatus:{
      columnDisplayName: 'app.tableHeader.customerCollabrationStatus',
      filterFieldName: 'customerCollabrationStatus',
      isFilterable: false,
      isSortable: false,
      isColumnSeen: true,
      isContentArray: false,
      isClickable: false,
    },
    items: {
      columnDisplayName: 'app.tableHeader.items',
      filterFieldName: 'items',
      isFilterable: false,
      isSortable: false,
      isColumnSeen: false,
      isContentArray: true,
    },
    pickupCity: {
      columnDisplayName: 'app.tableHeader.pickupCity',
      filterFieldName: 'pickupCity',
      isFilterable: true,
      isSortable: true,
      isColumnSeen: false,
      filterMenuHeaderText: 'Select Pickup cities',
      filterMenuType: 'multiSelectFromOptions',
      availableOptionsKeysList: [],
      availableOptions: {},
      availableOptionsArray: [],
      isContentArray: false,
      prevSelectedCities: [],
    },
    pickupLocation: {
      columnDisplayName: 'app.tableHeader.pickupLocation',
      filterFieldName: 'pickupLocation',
      isFilterable: true,
      isSortable: true,
      isColumnSeen: true,
      filterMenuHeaderText: 'Select Pickup Locations',
      filterMenuType: 'groupedMultiSelectFromOptions',
      availableOptionsKeysList: [],
      availableOptions: {},
      availableOptionsArray: [],
      isContentArray: false,
    },
    orderSource: {
      columnDisplayName: 'app.tableHeader.orderSource',
      filterFieldName: 'orderSource',
      isFilterable: false,
      isSortable: false,
      isColumnSeen: false,
      isContentArray: false,
      isNamingConventionReq: true,
    },
    shipmentDate: {
      columnDisplayName: 'app.tableHeader.shipmentDate',
      filterFieldName: 'shipmentDate',
      isFilterable: true,
      isSortable: true,
      isColumnSeen: false,
      isContentArray: false,
      isClickable: false,
      filterMenuHeaderText: 'Select date range',
      filterMenuType: 'dateRange',
      isRemovalDate: true,
    },
    expectedDeliveryDateTimeDetails: {
      columnDisplayName: 'app.tableHeader.expectedDeliveryDateTimeDetails',
      filterFieldName: 'expectedDeliveryDateTimeDetails',
      isFilterable: true,
      isSortable: true,
      isColumnSeen: true,
      filterMenuHeaderText: 'Select date range',
      filterMenuType: 'dateRange',
      startDate: new Date(),
      endDate: new Date(),
      isContentArray: false,
      isDefaultFilter: true
    },
    orderType: {
      columnDisplayName: 'app.tableHeader.orderType',
      filterFieldName: 'orderType',
      isFilterable: true,
      isSortable: false,
      isColumnSeen: false,
      filterMenuHeaderText: 'Select Order Type',
      filterMenuType: 'singleSelectFromOptions',
      availableOptionsKeysList: ['delivery', 'promotional', 'pickup'],
      availableOptions: {

        delivery: {
          filterParameterValue: 'delivery',
          name: 'Delivery',
          isSelected: false,
        },
        promotional: {
          filterParameterValue: 'promotional',
          name: 'Promotional',
          isSelected: false,
        },
        pickup: {
          filterParameterValue: 'pickup',
          name: 'Pickup',
          isSelected: false,
        },
      },
      availableOptionsArray: [
        {
          filterParameterValue: 'delivery',
          name: 'Delivery',
          field: 'delivery',
          isSelected: false,
        },
        {
          filterParameterValue: 'promotional',
          name: 'Promotional',
          field: 'promotional',
          isSelected: false,
        },
        {
          filterParameterValue: 'pickup',
          name: 'Pickup',
          field: 'pickup',
          isSelected: false,
        }
      ],
      isContentArray: false,
    },
    lpStatus: {
      columnDisplayName: 'app.tableHeader.lpStatus',
      filterFieldName: 'lpStatus',
      isFilterable: true,
      isSortable: false,
      isColumnSeen: false,
      filterMenuHeaderText: 'Select LP Assignment Status',
      filterMenuType: 'singleSelectFromOptions',
      availableOptionsKeysList: ['assigned', 'unassigned'],
      availableOptions: {
        assigned: {
          filterParameterValue: 'true',
          name: 'Assigned',
          isSelected: false,
        },
        unassigned: {
          filterParameterValue: 'false',
          name: 'Not Assigned',
          isSelected: false,
        },
      },
      availableOptionsArray: [
        {
          filterParameterValue: 'true',
          name: 'Assigned',
          field: 'assigned',
          isSelected: false,
        },
        {
          filterParameterValue: 'false',
          name: 'Not Assigned',
          field: 'unassigned',
          isSelected: false,
        },
      ],
      isContentArray: false,
    },
    lpTrackingId: {
      columnDisplayName: 'app.tableHeader.lpTrackingId',
      filterFieldName: 'lpTrackingId',
      isFilterable: false,
      isSortable: false,
      isColumnSeen: true,
      isContentArray: false,
      isClickable: false,
    },
    lpDetailsAmount: {
      columnDisplayName: 'Freight Cost',
      filterFieldName: 'lpDetailsAmount',
      isFilterable: false,
      isSortable: false,
      isColumnSeen: true,
      isContentArray: false,
      isClickable: false,
    },
    logistic: {
      columnDisplayName: 'app.tableHeader.logistic',
      filterFieldName: 'logistic',
      isFilterable: true,
      isSortable: true,
      isColumnSeen: true,
      filterMenuHeaderText: 'Select logistic provider',
      filterMenuType: 'multiSelectFromOptions',
      availableOptionsKeysList: [],
      availableOptions: {},
      availableOptionsArray: [],
      isContentArray: false,
      isSearchAllowed: true,
    },
    deliverySlot: {
      columnDisplayName: 'app.tableHeader.deliverySlot',
      filterFieldName: 'deliverySlot',
      isFilterable: true,
      isSortable: false,
      isColumnSeen: false,
      filterMenuHeaderText: 'Select Delivery Slot',
      filterMenuType: 'multiSelectFromOptions',
      availableOptionsKeysList: [],
      availableOptions: {},
      availableOptionsArray: [],
      isContentArray: false,
    },
    orderStatusDetails: {
      columnDisplayName: 'app.tableHeader.orderStatus',
      filterFieldName: 'orderStatusDetails',
      isFilterable: true,
      isSortable: true,
      isColumnSeen: true,
      isContentArray: true,
      isNamingConventionReq: true,
      filterMenuHeaderText: 'Select order status',
      filterMenuType: 'singleSelectFromOptions',
      availableOptionsKeysList: ['inProgress', 'delivered', 'anomaly'],
      availableOptions: {
        inProgress: {
          filterParameterValue: 'inProgress',
          name: 'In Progress',
          isSelected: false,
        },
        delivered: {
          filterParameterValue: 'delivered',
          name: 'Delivered',
          isSelected: false,
        },
        anomaly: {
          filterParameterValue: 'anomaly',
          name: 'Anomaly',
          isSelected: false,
        },
      },
      availableOptionsArray: [
        {
          filterParameterValue: 'inProgress',
          name: 'In Progress',
          isSelected: false,
        },
        {
          filterParameterValue: 'delivered',
          name: 'Delivered',
          isSelected: false,
        },
        {
          filterParameterValue: 'anomaly',
          name: 'Anomaly',
          isSelected: false,
        },
      ],
    },
    removeAction: {
      columnDisplayName: 'app.tableHeader.removeAction',
      filterFieldName: 'removeAction',
      isFilterable: false,
      isSortable: false,
      isColumnSeen: true,
      filterMenuHeaderText: 'Remove',
      filterMenuType: 'anchor',
      availableOptionsKeysList: [],
      availableOptions: {},
      availableOptionsArray: [],
      isContentArray: false,
    },
    customerTrackingLink: {
      columnDisplayName: 'app.tableHeader.customerTrackingLink',
      filterFieldName: 'customerTrackingLink',
      isFilterable: false,
      isSortable: false,
      isColumnSeen: true,
      isContentArray: false,
      isClickable: true,
      isRedirectLink: true,
      labelForLink: 'Link',
      imageSource: 'custLink pi pi-telegram',
    },
    action: {
      columnDisplayName: "app.tableHeader.action",
      filterFieldName: 'action',
      isFilterable: false,
      isSortable: false,
      isColumnSeen: true,
      isContentArray: false,
      isNamingConventionReq: true,
      showIconList: true,
      iconsList: []
    }
  },
  consignmentTableHeaderData: {
    orderId: {
      columnDisplayName: 'app.tableHeader.id',
      filterFieldName: 'orderId',
      isFilterable: false,
      isSortable: true,
      isColumnSeen: true,
      isContentArray: false,
      isClickable: true,
    },
    created: {
      columnDisplayName: "app.tableHeader.created",
      filterFieldName: "created",
      isFilterable: true,
      isSortable: true,
      isColumnSeen: true,
      filterMenuHeaderText: "Select date range",
      filterMenuType: "dateRange",
      startDate: new Date(),
      endDate: new Date(),
      isContentArray: false,
      isDefaultFilter: true,
    },
    custDetails: {
      columnDisplayName: "app.tableHeader.custDetails",
      filterFieldName: "custDetails",
      isFilterable: false,
      isSortable: false,
      isColumnSeen: true,
      isContentArray: true,
    },
    items: {
      columnDisplayName: 'app.tableHeader.items',
      filterFieldName: 'items',
      isFilterable: false,
      isSortable: false,
      isColumnSeen: false,
      isContentArray: true,
    },
    vehicleAssignedStatus: {
      columnDisplayName: 'app.tableHeader.vehicleAssignedStatus',
      filterFieldName: 'vehicleAssignedStatus',
      isFilterable: true,
      isSortable: false,
      isColumnSeen: true,
      isContentArray: true,
      filterMenuHeaderText: 'Select Vehicle Status',
      filterMenuType: 'singleSelectFromOptions',
      availableOptionsKeysList: ['assigned', 'unassigned'],
      availableOptions: {
        assigned: {
          filterParameterValue: 'true',
          name: 'Assigned',
          isSelected: false,
        },
        unassigned: {
          filterParameterValue: 'false',
          name: 'Unsssigned',
          isSelected: false,
        },
      },
      availableOptionsArray: [
        {
          filterParameterValue: 'true',
          name: 'Assigned',
          field: 'assigned',
          isSelected: false,
        },
        {
          filterParameterValue: 'false',
          name: 'Unassigned',
          field: 'unassigned',
          isSelected: false,
        },
      ],
    },
  
    pickupCity: {
      columnDisplayName: 'app.tableHeader.pickupCity',
      filterFieldName: 'pickupCity',
      isFilterable: true,
      isSortable: false,
      isColumnSeen: true,
      filterMenuHeaderText: 'Select Pickup cities',
      filterMenuType: 'multiSelectFromOptions',
      availableOptionsKeysList: [],
      availableOptions: {},
      availableOptionsArray: [],
      isContentArray: false,
      prevSelectedCities: [],
    },
    pickupLocation: {
      columnDisplayName: 'app.tableHeader.pickupLocation',
      filterFieldName: 'pickupLocation',
      isFilterable: true,
      isSortable: false,
      isColumnSeen: true,
      filterMenuHeaderText: 'Select Pickup Locations',
      filterMenuType: 'groupedMultiSelectFromOptions',
      availableOptionsKeysList: [],
      availableOptions: {},
      availableOptionsArray: [],
      isContentArray: false,
    },
    deliveryLoc: {
      columnDisplayName: 'app.tableHeader.deliveryLoc',
      filterFieldName: 'deliveryLoc',
      isFilterable: true,
      isSortable: false,
      isColumnSeen: true,
      isContentArray: false,
      isClickable: false,
      filterMenuHeaderText: 'Select delivery location',
      filterMenuType: 'multiSelectFromOptions',
      availableOptionsKeysList: [],
      availableOptions: {},
      availableOptionsArray: []
    },
    expectedDeliveryDateTimeDetails: {
      columnDisplayName: 'app.tableHeader.expectedDeliveryDateTimeDetails',
      filterFieldName: 'expectedDeliveryDateTimeDetails',
      isFilterable: true,
      isSortable: true,
      isColumnSeen: true,
      filterMenuHeaderText: 'Select date range',
      filterMenuType: 'dateRange',
      startDate: new Date(),
      endDate: new Date(),
      isContentArray: false,
      isDefaultFilter: false
    },
    orderSource: {
      columnDisplayName: 'app.tableHeader.orderSource',
      filterFieldName: 'orderSource',
      isFilterable: false,
      isSortable: false,
      isColumnSeen: false,
      isContentArray: false,
      isNamingConventionReq: true,
    },
    orderStatus: {
      columnDisplayName: 'app.tableHeader.orderStatus',
      filterFieldName: 'orderStatus',
      isFilterable: true,
      isSortable: true,
      isColumnSeen: true,
      isContentArray: false,
      isNamingConventionReq: true,
      filterMenuHeaderText: 'Select order status',
      filterMenuType: 'singleSelectFromOptions',
      availableOptionsKeysList: ['inProgress', 'delivered', 'anomaly'],
      availableOptions: {
        inProgress: {
          filterParameterValue: 'inProgress',
          name: 'In Progress',
          isSelected: false,
        },
        delivered: {
          filterParameterValue: 'delivered',
          name: 'Delivered',
          isSelected: false,
        },
        anomaly: {
          filterParameterValue: 'anomaly',
          name: 'Anomaly',
          isSelected: false,
        },
      },
      availableOptionsArray: [
        {
          filterParameterValue: 'inProgress',
          name: 'In Progress',
          isSelected: false,
        },
        {
          filterParameterValue: 'delivered',
          name: 'Delivered',
          isSelected: false,
        },
        {
          filterParameterValue: 'anomaly',
          name: 'Anomaly',
          isSelected: false,
        },
      ],
    },
    deliverySlot: {
      columnDisplayName: 'app.tableHeader.deliverySlot',
      filterFieldName: 'deliverySlot',
      isFilterable: true,
      isSortable: false,
      isColumnSeen: false,
      filterMenuHeaderText: 'Select Delivery Slot',
      filterMenuType: 'multiSelectFromOptions',
      availableOptionsKeysList: [],
      availableOptions: {},
      availableOptionsArray: [],
      isContentArray: false,
    },
    removeAction: {
      columnDisplayName: 'app.tableHeader.removeAction',
      filterFieldName: 'removeAction',
      isFilterable: false,
      isSortable: false,
      isColumnSeen: true,
      filterMenuHeaderText: 'Remove',
      filterMenuType: 'anchor',
      availableOptionsKeysList: [],
      availableOptions: {},
      availableOptionsArray: [],
      isContentArray: false,
    },
  },
  vehicleTableHeaderData: {
    name: {
      columnDisplayName: "app.tableHeader.name",
      filterFieldName: "name",
      isFilterable: false,
      isSortable: false,
      isColumnSeen: true,
      isContentArray: false,
      isClickable: true,
    },
    registrationNumber: {
      columnDisplayName: "app.tableHeader.registrationNumber",
      filterFieldName: "registrationNumber",
      isFilterable: false,
      isSortable: false,
      isColumnSeen: true,
      isContentArray: false,
    },
    removeAction: {
      columnDisplayName: 'app.tableHeader.removeAction',
      filterFieldName: 'removeAction',
      isFilterable: false,
      isSortable: false,
      isColumnSeen: true,
      filterMenuHeaderText: 'Remove',
      filterMenuType: 'anchor',
      availableOptionsKeysList: [],
      availableOptions: {},
      availableOptionsArray: [],
      isContentArray: false,
    },
  },
  orderTableHeaderData: {
    orderId: {
      columnDisplayName: "app.tableHeader.orderId",
      filterFieldName: "orderId",
      isFilterable: false,
      isSortable: true,
      isColumnSeen: true,
      isContentArray: false,
      isClickable: true,
    },
    created: {
      columnDisplayName: "app.tableHeader.created",
      filterFieldName: "created",
      isFilterable: true,
      isSortable: true,
      isColumnSeen: true,
      filterMenuHeaderText: "Select date range",
      filterMenuType: "dateRange",
      startDate: new Date(),
      endDate: new Date(),
      isContentArray: false,
      isDefaultFilter: false,
    },
    custDetails: {
      columnDisplayName: "app.tableHeader.custDetails",
      filterFieldName: "custDetails",
      isFilterable: false,
      isSortable: true,
      isColumnSeen: true,
      isContentArray: true,
    },
    items: {
      columnDisplayName: "app.tableHeader.items",
      filterFieldName: "items",
      isFilterable: false,
      isSortable: false,
      isColumnSeen: false,
      isContentArray: true,
    },
    agentDetails: {
      columnDisplayName: "app.tableHeader.agentDetails",
      filterFieldName: "agentDetails",
      isFilterable: true,
      isSortable: false,
      isColumnSeen: true,
      isContentArray: true,
      filterMenuHeaderText: "Select Agent Status",
      filterMenuType: "singleSelectFromOptions",
      availableOptionsKeysList: ["assigned", "unassigned"],
      availableOptions: {
        assigned: {
          filterParameterValue: "true",
          name: "Assigned",
          isSelected: false,
        },
        unassigned: {
          filterParameterValue: "false",
          name: "Unsssigned",
          isSelected: false,
        },
      },
      availableOptionsArray: [
        {
          filterParameterValue: "true",
          name: "Assigned",
          field: "assigned",
          isSelected: false,
        },
        {
          filterParameterValue: "false",
          name: "Unassigned",
          field: "unassigned",
          isSelected: false,
        },
      ],
    },
    pickupCity: {
      columnDisplayName: "app.tableHeader.pickupCity",
      filterFieldName: "pickupCity",
      isFilterable: true,
      isSortable: false,
      isColumnSeen: true,
      filterMenuHeaderText: "Select Pickup cities",
      filterMenuType: "multiSelectFromOptions",
      availableOptionsKeysList: [],
      availableOptions: {},
      availableOptionsArray: [],
      isContentArray: false,
      prevSelectedCities: [],
    },
    pickupLocation: {
      columnDisplayName: "app.tableHeader.pickupLocation",
      filterFieldName: "pickupLocation",
      isFilterable: true,
      isSortable: true,
      isColumnSeen: true,
      filterMenuHeaderText: "Select Pickup Locations",
      filterMenuType: "groupedMultiSelectFromOptions",
      availableOptionsKeysList: [],
      availableOptions: {},
      availableOptionsArray: [],
      isContentArray: false,
    },
    orderSource: {
      columnDisplayName: "app.tableHeader.orderSource",
      filterFieldName: "orderSource",
      isFilterable: false,
      isSortable: false,
      isColumnSeen: false,
      isContentArray: false,
      isNamingConventionReq: true,
    },
    expectedDeliveryDateTimeDetails: {
      columnDisplayName: "app.tableHeader.expectedDeliveryDateTimeDetails",
      filterFieldName: "expectedDeliveryDateTimeDetails",
      isFilterable: true,
      isSortable: true,
      isColumnSeen: true,
      filterMenuHeaderText: "Select date range",
      filterMenuType: "dateRange",
      startDate: new Date(),
      endDate: new Date(),
      isContentArray: false,
      isDefaultFilter: false
    },
    orderStatus: {
      columnDisplayName: "app.tableHeader.orderStatus",
      filterFieldName: "orderStatus",
      isFilterable: true,
      isSortable: true,
      isColumnSeen: true,
      isContentArray: false,
      isNamingConventionReq: true,
      filterMenuHeaderText: "Select order status",
      filterMenuType: "singleSelectFromOptions",
      availableOptionsKeysList: ["inProgress", "delivered", "anomaly"],
      availableOptions: {
        inProgress: {
          filterParameterValue: "inProgress",
          name: "In Progress",
          isSelected: false,
        },
        delivered: {
          filterParameterValue: "delivered",
          name: "Delivered",
          isSelected: false,
        },
        anomaly: {
          filterParameterValue: "anomaly",
          name: "Anomaly",
          isSelected: false,
        },
      },
      availableOptionsArray: [
        {
          filterParameterValue: "inProgress",
          name: "In Progress",
          isSelected: false,
        },
        {
          filterParameterValue: "delivered",
          name: "Delivered",
          isSelected: false,
        },
        {
          filterParameterValue: "anomaly",
          name: "Anomaly",
          isSelected: false,
        },
      ],
    },
    deliverySlot: {
      columnDisplayName: "app.tableHeader.deliverySlot",
      filterFieldName: "deliverySlot",
      isFilterable: true,
      isSortable: false,
      isColumnSeen: false,
      filterMenuHeaderText: "Select Delivery Slot",
      filterMenuType: "multiSelectFromOptions",
      availableOptionsKeysList: [],
      availableOptions: {},
      availableOptionsArray: [],
      isContentArray: false,
    },
    removeAction: {
      columnDisplayName: "app.tableHeader.removeAction",
      filterFieldName: "removeAction",
      isFilterable: false,
      isSortable: false,
      isColumnSeen: true,
      filterMenuHeaderText: "Remove",
      filterMenuType: "anchor",
      availableOptionsKeysList: [],
      availableOptions: {},
      availableOptionsArray: [],
      isContentArray: false,
    },
  },
  agentTableHeaderData: {
    agentName: {
      columnDisplayName: "app.tableHeader.agentName",
      filterFieldName: "agentName",
      isFilterable: false,
      isSortable: false,
      isColumnSeen: true,
      isContentArray: false,
      isClickable: false,
    },
    agentNo: {
      columnDisplayName: "app.tableHeader.agentNo",
      filterFieldName: "agentNo",
      isFilterable: false,
      isSortable: false,
      isColumnSeen: true,
      isContentArray: false,
    },
    agentLocation: {
      columnDisplayName: "app.tableHeader.agentLocation",
      filterFieldName: "agentLocation",
      isFilterable: true,
      isSortable: false,
      isColumnSeen: true,
      filterMenuHeaderText: "Select agent Locations",
      filterMenuType: "groupedMultiSelectFromOptions",
      availableOptionsKeysList: [],
      availableOptions: {},
      availableOptionsArray: [],
      isContentArray: true,
    },
  },
  routePlanTableHeader: {
    label: {
      columnDisplayName: "Label",
      filterFieldName: "label",
      isFilterable: false,
      isSortable: false,
      isColumnSeen: true,
      isContentArray: false,
      isClickable: true,

    },
    pickupCity: {
      columnDisplayName: "Pickup City",
      filterFieldName: "pickupCity",
      isFilterable: true,
      isSortable: false,
      isColumnSeen: true,
      filterMenuHeaderText: "Select Pickup cities",
      filterMenuType: "multiSelectFromOptions",
      availableOptionsKeysList: [],
      availableOptions: {},
      availableOptionsArray: [],
      isContentArray: false,
      prevSelectedCities: [],
    },
    pickupLocation: {
      columnDisplayName: "Pickup Location",
      filterFieldName: "pickupLocation",
      isFilterable: true,
      isSortable: false,
      isColumnSeen: true,
      filterMenuHeaderText: "Select Pickup Locations",
      filterMenuType: "groupedMultiSelectFromOptions",
      availableOptionsKeysList: [],
      availableOptions: {},
      availableOptionsArray: [],
      isContentArray: false,
    },
    deliveryCentersCount: {
      columnDisplayName: "Delivery Center Count",
      filterFieldName: "deliveryCentersCount",
      isFilterable: false,
      isSortable: false,
      isColumnSeen: true,
      isContentArray: false,
      isClickable: false,
    },
    removeAction: {
      columnDisplayName: "Action",
      filterFieldName: "removeAction",
      isFilterable: false,
      isSortable: false,
      isColumnSeen: true,
      // filterMenuHeaderText: "Remove",
      // filterMenuType: "anchor",
      showIconList: true,
      availableOptionsKeysList: [],
      availableOptions: {},
      availableOptionsArray: [],
      isContentArray: false,
    },
  },
  scheduleTableHeader: {
    created: {
      columnDisplayName: "app.tableHeader.created",
      filterFieldName: "created",
      isFilterable: false,
      isSortable: false,
      isColumnSeen: false,
     // filterMenuHeaderText: "Select date range",
     // filterMenuType: "dateRange",
      startDate: new Date(),
      endDate: new Date(),
      isContentArray: false,
     // isDefaultFilter: false,
    },
    scheduleTypeLabel: {
      columnDisplayName: 'Plan Type',
      filterFieldName: 'scheduleTypeLabel',
      isFilterable: true,
      isSortable: false,
      isColumnSeen: true,
      isContentArray: false,
      isNamingConventionReq: true,
      filterMenuHeaderText: 'Select Plan Type',
      filterMenuType: 'groupedMultiSelectFromOptions',
      availableOptionsKeysList: ['single', 'recurring'],
      availableOptions: {
        single: {
          filterParameterValue: 'single',
          name: 'One Time',
          isSelected: false,
          items :[{
            filterParameterValue:'single',
            name:'One Time',
            isSelected:false
          }]
        },
        recurring: {
          filterParameterValue: 'recurring',
          name: 'Recurring',
          isSelected: false,
          items :[{
            filterParameterValue:'daily',
            name:'Daily',
            isSelected:false
          },{
            filterParameterValue:'weekly',
            name:'Weekly',
            isSelected:false
          },{
            filterParameterValue:'monthly',
            name:'Monthly',
            isSelected:false
          }]
        },
      },
      availableOptionsArray: [
        {
          name: 'One Time',
          filterParameterValue:'single',
          isSelected: false,
          items :[{
            filterParameterValue:'single',
            name:'One Time',
            isSelected:false
          },]
        },
        {
          name: 'Recurring',
          filterParameterValue:'recurring',
          isSelected: false,
          items :[{
            filterParameterValue:'daily',
            name:'Daily',
            isSelected:false
          },{
            filterParameterValue:'weekly',
            name:'Weekly',
            isSelected:false
          },{
            filterParameterValue:'monthly',
            name:'Monthly',
            isSelected:false
          }]
        },
        
      ],
    },

    recurringDateRange:{
      columnDisplayName: 'Plan Period',
      filterFieldName: 'recurringDateRange',
      isFilterable: false,
      isSortable: false,
      isColumnSeen: true,
      isContentArray: false,
    },
    daysLabels: {
      columnDisplayName: 'Days',
      filterFieldName: 'daysLabels',
      isFilterable: false,
      isSortable: false,
      isColumnSeen: true,
      isContentDays: true,
      isContentArray:false
    },
    scheduledDateLabel: {
      columnDisplayName: 'Scheduled Date & Time',
      filterFieldName: 'scheduledDateLabel',
      isFilterable: false,
      isSortable: false,
      isColumnSeen: true,
      startDate: new Date(),
      endDate: new Date(),
      isContentArray: false,
     filterMenuHeaderText: "Select date range",
     filterMenuType: "dateRange",
    //  isDefaultFilter: false,
    },
    pickupCity: {
      columnDisplayName: 'app.tableHeader.pickupCity',
      filterFieldName: 'pickupCity',
      isFilterable: true,
      isSortable: false,
      isColumnSeen: false,
      filterMenuHeaderText: 'Select Pickup cities',
      filterMenuType: 'multiSelectFromOptions',
      availableOptionsKeysList: [],
      availableOptions: {},
      availableOptionsArray: [],
      isContentArray: false,
      prevSelectedCities: [],
    },
    pickupLocation: {
      columnDisplayName: 'app.tableHeader.pickupLocation',
      filterFieldName: 'pickupLocation',
      isFilterable: true,
      isSortable: false,
      isColumnSeen: true,
      filterMenuHeaderText: 'Select Pickup Locations',
      filterMenuType: 'groupedMultiSelectFromOptions',
      availableOptionsKeysList: [],
      availableOptions: {},
      availableOptionsArray: [],
      isContentArray: false,
    },
    carrier: {
      columnDisplayName: 'app.tableHeader.carrier',
      filterFieldName: 'carrier',
      isFilterable: true,
      filterMenuHeaderText: 'app.tableHeader.selectCarrier',
      filterMenuType: 'multiSelectFromOptions',
      availableOptionsKeysList: [],
      availableOptions: {},
      availableOptionsArray: [],
      isSortable: false,
      isColumnSeen: true,
      isContentArray: false,
    },
    routePlans: {
      columnDisplayName: 'app.tableHeader.routePlans',
      filterFieldName: 'routePlans',
      isFilterable: true,
      filterMenuHeaderText: 'app.tableHeader.selectRoutePlans',
      filterMenuType: 'treeSelectFromOptions',
      availableOptionsKeysList: [],
      availableOptions: {},
      availableOptionsArray: [],
      isColumnSeen: true,
      isContentArray: false,
      isContentRelative: true,
      groupRoutePlanArray:[],
    },
    status: {
      columnDisplayName: 'Status',
      filterFieldName: 'status',
      isFilterable: true,
      isSortable: false,
      isColumnSeen: true,
      isContentArray: false,
      isNamingConventionReq: true,
      filterMenuHeaderText: 'Select Status',
      filterMenuType: 'multiSelectFromOptions',
      availableOptionsKeysList: ['active', 'paused', 'expired'],
      isContentHeaderSubheader: true,
      availableOptions: {
        active: {
          filterParameterValue: 'active',
          name: 'Active',
          isSelected: false,
        },
        paused: {
          filterParameterValue: 'paused',
          name: 'Paused',
          isSelected: false,
        },
        expired: {
          filterParameterValue: 'expired',
          name: 'Expired',
          isSelected: false,
        }
      },
      availableOptionsArray: [
        {
          filterParameterValue: 'active',
          name: 'Active',
          isSelected: false,
        },
        {
          filterParameterValue: 'paused',
          name: 'Paused',
          isSelected: false,
        }
        , {
          filterParameterValue: 'expired',
          name: 'Expired',
          isSelected: false,
        }
      ],
    },
    action: {
      columnDisplayName: "app.tableHeader.action",
      filterFieldName: 'action',
      isFilterable: false,
      isSortable: false,
      isColumnSeen: true,
      isContentArray: false,
      isNamingConventionReq: true,
      showIconList: true,
      iconsList: []
    }
  },
  allScheduleTableHeader: {},
  todayScheduleTableHeader: {},
  kioskTableHeaderData: {
    orderId: {
      columnDisplayName: 'app.tableHeader.orderId',
      filterFieldName: 'orderId',
      isFilterable: false,
      isSortable: true,
      isColumnSeen: true,
      isContentArray: false,
      isClickable: true,
    },
    items: {
      columnDisplayName: "app.tableHeader.items",
      filterFieldName: "items",
      isFilterable: false,
      isSortable: false,
      isColumnSeen: false,
      isContentArray: true,
    },
    pickupCity: {
      columnDisplayName: "app.tableHeader.pickupCity",
      filterFieldName: "pickupCity",
      isFilterable: true,
      isSortable: true,
      isColumnSeen: false,
      filterMenuHeaderText: "Select Pickup cities",
      filterMenuType: "multiSelectFromOptions",
      availableOptionsKeysList: [],
      availableOptions: {},
      availableOptionsArray: [],
      isContentArray: false,
      prevSelectedCities: [],
    },
    pickupLocation: {
      columnDisplayName: "app.tableHeader.pickupLocation",
      filterFieldName: "pickupLocation",
      isFilterable: true,
      isSortable: true,
      isColumnSeen: false,
      filterMenuHeaderText: "Select Pickup Locations",
      filterMenuType: "groupedMultiSelectFromOptions",
      availableOptionsKeysList: [],
      availableOptions: {},
      availableOptionsArray: [],
      isContentArray: false,
    },
    expectedDeliveryDateTimeDetails: {
      columnDisplayName: 'app.tableHeader.date',
      filterFieldName: 'expectedDeliveryDateTimeDetails',
      isFilterable: true,
      isSortable: true,
      isColumnSeen: true,
      filterMenuHeaderText: 'Select date range',
      filterMenuType: 'dateRange',
      startDate: new Date(new Date().setDate(new Date().getDate() - 6)),
      endDate: new Date(),
      isContentArray: false,
      isDefaultFilter: true
    },
    orderType: {
      columnDisplayName: "app.tableHeader.orderType",
      filterFieldName: "orderType",
      isFilterable: true,
      isSortable: false,
      isColumnSeen: false,
      filterMenuHeaderText: "Select Order Type",
      filterMenuType: "singleSelectFromOptions",
      availableOptionsKeysList: ["delivery", "promotional", "pickup"],
      availableOptions: {

        delivery: {
          filterParameterValue: "delivery",
          name: "Delivery",
          isSelected: false,
        },
        promotional: {
          filterParameterValue: "promotional",
          name: "Promotional",
          isSelected: false,
        },
        pickup: {
          filterParameterValue: "pickup",
          name: "Pickup",
          isSelected: false,
        },
      },
      availableOptionsArray: [
        {
          filterParameterValue: "delivery",
          name: "Delivery",
          field: "delivery",
          isSelected: false,
        },
        {
          filterParameterValue: "promotional",
          name: "Promotional",
          field: "promotional",
          isSelected: false,
        },
        {
          filterParameterValue: "pickup",
          name: "Pickup",
          field: "pickup",
          isSelected: false,
        }
      ],
      isContentArray: false,
    },
    orderTime: {
      columnDisplayName: "app.tableHeader.orderTime",
      filterFieldName: "orderTime",
      isFilterable: false,
      isSortable: false,
      isColumnSeen: true,
      isContentArray: false,
      isNamingConventionReq: true,
    },
    amount: {
      columnDisplayName: "app.tableHeader.amount",
      filterFieldName: "amount",
      isFilterable: false,
      isSortable: false,
      isColumnSeen: true,
      isContentArray: false,
    },
    orderStatusDetails: {
      columnDisplayName: "app.tableHeader.orderStatus",
      filterFieldName: "orderStatusDetails",
      isFilterable: true,
      isSortable: true,
      isColumnSeen: true,
      isContentArray: true,
      showSubtitle: true,
      orderStatusIsUpdateable: true,
      isNamingConventionReq: true,
      filterMenuHeaderText: "Select order status",
      filterMenuType: "multiSelectFromOptions",
      availableOptionsKeysList: ["created", "pending", "placed", "serviced", "cancelled", "discarded", "refunded"],
      availableOptions: {
        created: {
          filterParameterValue: "created",
          name: "Created",
          isSelected: false,
        },
        pending: {
          filterParameterValue: "pending",
          name: "Pending",
          isSelected: false,
        },
        placed: {
          filterParameterValue: "placed",
          name: "Placed",
          isSelected: false,
        },
        serviced: {
          filterParameterValue: "serviced",
          name: "Serviced",
          isSelected: false,
        },
        cancelled: {
          filterParameterValue: "cancelled",
          name: "Cancelled",
          isSelected: false,
        },
        discarded: {
          filterParameterValue: "discarded",
          name: "Discarded",
          isSelected: false,
        },
        refunded: {
          filterParameterValue: "refunded",
          name: "Refunded",
          isSelected: false,
        }

      },
      availableOptionsArray: [
        {
          filterParameterValue: "created",
          name: "Created",
          isSelected: false,
        },
        {
          filterParameterValue: "prepared",
          name: "Prepared",
          isSelected: false,
        },
        {
          filterParameterValue: "preparing",
          name: "Preparing",
          isSelected: false,
        },
        {
          filterParameterValue: "placed",
          name: "Placed",
          isSelected: false,
        },
        {
          filterParameterValue: "serviced",
          name: "Serviced",
          isSelected: false,
        },
        {
          filterParameterValue: "cancelled",
          name: "Cancelled",
          isSelected: false,
        },
        {
          filterParameterValue: "discarded",
          name: "Discarded",
          isSelected: false
        },
        {
          filterParameterValue: "refunded",
          name: "Refunded",
          isSelected: false
        }
      ],
    },
    paymentStatus: {
      columnDisplayName: "app.tableHeader.paymentStatus",
      filterFieldName: "paymentStatus",
      isFilterable: true,
      isSortable: true,
      isColumnSeen: true,
      isContentArray: true,
      isNamingConventionReq: true,
      isColorLabel: true,
      isChip: true,

      filterMenuHeaderText: "Select payment status",
      filterMenuType: "singleSelectFromOptions",
      availableOptionsKeysList: ["Paid", "Not paid", "Refunded", "Refund Initiated"],
      availableOptions: {
        paid: {
          filterParameterValue: "paid",
          name: "Paid",
          isSelected: false,
        },
        notPaid: {
          filterParameterValue: "notPaid",
          name: "Not paid",
          isSelected: false,
        },
        refunded: {
          filterParameterValue: "refunded",
          name: "Refunded",
          isSelected: false,
        },
        refundInitiated: {
          filterParameterValue: "refundInitiated",
          name: "Refund Initiated",
          isSelected: false,
        },
        verified: {
          filterParameterValue: "verified",
          name: "Verified",
          isSelected: false
        }
      },
      availableOptionsArray: [
        {
          filterParameterValue: "paid",
          name: "Paid",
          isSelected: false,
        },
        {
          filterParameterValue: "notPaid",
          name: "Not paid",
          isSelected: false,
        },
        {
          filterParameterValue: "refunded",
          name: "Refunded",
          isSelected: false,
        },
        {
          filterParameterValue: "refundInitiated",
          name: "Refund Initiated",
          isSelected: false
        },
        {
          filterParameterValue: "verified",
          name: "Verified",
          isSelected: false
        }
      ],
    },
    paymentMode: {
      columnDisplayName: "app.tableHeader.paymentMode",
      filterFieldName: "paymentMode",
      isFilterable: true,
      isSortable: true,
      isColumnSeen: true,
      isContentArray: false,
      isNamingConventionReq: true,

      filterMenuHeaderText: "Select payment mode",
      filterMenuType: "singleSelectFromOptions",
      availableOptionsKeysList: ["Cash", "Online"],
      availableOptions: {
        paid: {
          filterParameterValue: "cash",
          name: "Cash",
          isSelected: false,
        },
        notPaid: {
          filterParameterValue: "online",
          name: "Online",
          isSelected: false,
        },
        manuallyVerified: {
          filterParameterValue: "manuallyVerified",
          name: "Manually Verified",
          isSelected: false,
        }
      },
      availableOptionsArray: [
        {
          filterParameterValue: "cash",
          name: "Cash",
          isSelected: false,
        },
        {
          filterParameterValue: "online",
          name: "Online",
          isSelected: false,
        },
        {
          filterParameterValue: "manuallyVerified",
          name: "Manually Verified",
          isSelected: false,
        }
      ],
    },
    serviceTime: {
      columnDisplayName: "app.tableHeader.serviceTime",
      filterFieldName: "serviceTime",
      isFilterable: false,
      isSortable: false,
      isColumnSeen: true,
      isContentArray: false,
      isNamingConventionReq: true,
    },
    dineType: {
      columnDisplayName: "Dine Type",
      filterFieldName: 'dineType',
      isFilterable: true,
      isSortable: false,
      isColumnSeen: true,
      isContentArray: false,
      isNamingConventionReq: true,

      filterMenuHeaderText: "Select Dine Type",
      filterMenuType: "singleSelectFromOptions",
      availableOptionsKeysList: ["Dine In","Dine Out","Table"],
      availableOptions: {
        dineIn: {
          filterParameterValue: "dineIn",
          name: "Dine In",
          isSelected: false,
        },
        dineOut: {
          filterParameterValue: "dineOut",
          name: "Dine Out",
          isSelected: false,
        },
        table: {
          filterParameterValue: "table",
          name: "Table",
          isSelected: false,
        }
      },
      availableOptionsArray: [
        {
          filterParameterValue: "dineIn",
          name: "Dine In",
          isSelected: false,
        },
        {
          filterParameterValue: "dineOut",
          name: "Dine Out",
          isSelected: false,
        },
        {
          filterParameterValue: "table",
          name: "Table",
          isSelected: false,
        }
      ],
    },
    tableNo: {
      columnDisplayName: 'Table No',
      filterFieldName: 'tableNo',
      isFilterable: false,
      isSortable: false,
      isColumnSeen: true,
      isContentArray: false,
      isClickable: false,
    },
    orderSource: {
      columnDisplayName: "app.tableHeader.orderSource",
      filterFieldName: 'orderSource',
      isFilterable: true,
      isSortable: true,
      isColumnSeen: true,
      isContentArray: false,
      isNamingConventionReq: true,

      filterMenuHeaderText: "Select order source",
      filterMenuType: "singleSelectFromOptions",
      availableOptionsKeysList: ["Kiosk", "Iotzen-pos"],
      availableOptions: {
        paid: {
          filterParameterValue: "kiosk",
          name: "kiosk",
          isSelected: false,
        },
        notPaid: {
          filterParameterValue: "iotzen-pos",
          name: "iotzen-pos",
          isSelected: false,
        }
      },
      availableOptionsArray: [
        {
          filterParameterValue: "kiosk",
          name: "kiosk",
          isSelected: false,
        },
        {
          filterParameterValue: "iotzen-pos",
          name: "iotzen-pos",
          isSelected: false,
        }
      ],
    },
    action: {
      columnDisplayName: "app.tableHeader.action",
      filterFieldName: 'action',
      isFilterable: false,
      isSortable: false,
      isColumnSeen: true,
      isContentArray: false,
      isNamingConventionReq: true,
      showIconList: true,
      iconsList: []
    }
  },
  kioskProductsTableHeaderData: {
    imageUrl: {
      columnDisplayName: "Image",
      filterFieldName: "imageUrl",
      isFilterable: false,
      isSortable: false,
      isColumnSeen: true,
      isImg: true,
    },
    itemName: {
      columnDisplayName: "Name",
      filterFieldName: "itemName",
      isFilterable: false,
      isSortable: false,
      isColumnSeen: true,
      isContentArray: false,
      isClickable: true,
    },
    description: {
      columnDisplayName: "Description",
      filterFieldName: "description",
      isFilterable: false,
      isSortable: false,
      isColumnSeen: true,
      isContentArray: false,
    },
    categories: {
      columnDisplayName: "Categories",
      filterFieldName: "categories",
      isFilterable: true,
      isSortable: false,
      isColumnSeen: true,
      isContentArray: true,
      isChip: true,
      filterMenuType: "multiSelectFromOptions",
      filterMenuHeaderText: "Select category",
      availableOptionsKeysList: [],
      availableOptions: {},
      availableOptionsArray: []
    },
    "veg/nonVeg": {
      columnDisplayName: "Veg/NonVeg",
      filterFieldName: "veg/nonVeg",
      isFilterable: false,
      isSortable: false,
      isColumnSeen: true,
      isContentArray: false,
      isNamingConventionReq: true,
    },
    price: {
      columnDisplayName: "Price",
      filterFieldName: "price",
      isFilterable: false,
      isSortable: false,
      isColumnSeen: true,
      isContentArray: true,
      isInlineEditable: true,
      iconPlaceholder: 'Click on individual price to edit',
      minValue: 1
    },
  },
  mmdManagementTableHeaderData: {
    orderId: {
      columnDisplayName: "ID",
      filterFieldName: "orderId",
      isFilterable: false,
      isSortable: false,
      isColumnSeen: true,
      isContentArray: false,
      isClickable: true,
      isIconType: false,
      isDataClickedToMakeApiCall: false,
      isNamingConventionReq: false,
    },
    deliverySequence: {
      columnDisplayName: "Del Seq",
      filterFieldName: "deliverySequence",
      isFilterable: false,
      isSortable: false,
      isColumnSeen: true,
      isContentArray: false,
    },
    deliveryCenter: {
      columnDisplayName: 'app.dms.delivery-center.label',
      filterFieldName: 'deliveryCenter',
      isFilterable: false,
      isSortable: false,
      isColumnSeen: true,
      isContentArray: false,
      isIconType: false,
      isDataClickedToMakeApiCall: false,
      isNamingConventionReq: false,
      isTextData: true,
    },
    pickupLocation: {
      columnDisplayName: 'app.dms.processing-center.label',
      filterFieldName: 'pickupLocation',
      isFilterable: true,
      isSortable: false,
      isColumnSeen: true,
      isContentArray: false,
      isDataClickedToMakeApiCall: false,
      isNamingConventionReq: false,
      isTextData: true,
      filterMenuHeaderText: 'app.dms.processing-center.label',
      filterMenuType: 'groupedMultiSelectFromOptions',
      availableOptionsKeysList: [],
      availableOptions: {},
      availableOptionsArray: []
    },
    pickupCity: {
      columnDisplayName: "Pickup City",
      filterFieldName: "pickupCity",
      isFilterable: true,
      isSortable: false,
      isColumnSeen: false,
      filterMenuHeaderText: "Select Pickup cities",
      filterMenuType: "multiSelectFromOptions",
      availableOptionsKeysList: [],
      availableOptions: {},
      availableOptionsArray: [],
      isContentArray: false,
      prevSelectedCities: [],
      isIconType: false,
      isDataClickedToMakeApiCall: false,
      isNamingConventionReq: false,
    },
    expectedDeliveryDateTimeDetails: {
      columnDisplayName: "Expected Delivery",
      filterFieldName: "expectedDeliveryDateTimeDetails",
      isFilterable: true,
      isSortable: false,
      isColumnSeen: true,
      filterMenuHeaderText: 'Select date range',
      filterMenuType: 'dateRange',
      startDate: new Date(),
      endDate: new Date(),
      isContentArray: false,
      isIconType: false,
      isDataClickedToMakeApiCall: false,
      isNamingConventionReq: false,
    },
    orderStatus: {
      columnDisplayName: 'Status',
      filterFieldName: 'orderStatus',
      isFilterable: false,
      isSortable: false,
      isColumnSeen: true,
      isContentArray: false,
      isIconType: false,
      isDataClickedToMakeApiCall: false,
      isNamingConventionReq: true,
      isUpdatable: true,
      showCustomBadge: true,
      dropdownContionalText: CONSTANT.DELIVERY_STATUS.LABELS.RETURNING_TO
    },

    statusDateAndTime: {
      columnDisplayName: 'Status Date and Time',
      filterFieldName: 'statusDateAndTime',
      isFilterable: true,
      isSortable: false,
      isColumnSeen: false,
      isContentArray: false,
      isIconType: false,
      isDataClickedToMakeApiCall: false,
      isNamingConventionReq: false,
    },
    etaDetails: {
      columnDisplayName: "ETA",
      filterFieldName: "etaDetails",
      isFilterable: false,
      isSortable: false,
      isColumnSeen: true,
      isContentArray: false,
      isDataClickedToMakeApiCall: true,
      isIconType: false,
      isNamingConventionReq: false,
    },
    tempDetails: {
      columnDisplayName: "Temperature",
      filterFieldName: "tempDetails",
      isFilterable: false,
      isSortable: false,
      isColumnSeen: true,
      isContentArray: false,
      isDataClickedToMakeApiCall: false,
      isIconType: false,
      isNamingConventionReq: false,
    },
    customerTrack: {
      columnDisplayName: "Customer Track",
      filterFieldName: "customerTrack",
      isColumnSeen: true,
      isContentArray: false,
      isIconType: true,
      isDataClickedToMakeApiCall: false,
      imageSource: 'pi pi-telegram',
      isNamingConventionReq: false,

    },
    removeOrderAssignment: {
      columnDisplayName: 'Delete',
      filterFieldName: "removeOrderAssignment",
      isColumnSeen: true,
      isContentArray: false,
      isIconType: true,
      // imageType: 'fa-trash-alt'
      imageSource: 'pi pi-trash',
      isDataClickedToMakeApiCall: false,
      isNamingConventionReq: false,
    },
  },
  lmdManagementTableHeaderData: {
    orderId: {
      columnDisplayName: "ID",
      filterFieldName: "orderId",
      isFilterable: false,
      isSortable: false,
      isColumnSeen: true,
      isContentArray: false,
      isClickable: true,
      isIconType: false,
      isDataClickedToMakeApiCall: false,
      isNamingConventionReq: false,
    },
    customer: {
      columnDisplayName: "Customer Details",
      filterFieldName: "customer",
      isFilterable: false,
      isSortable: false,
      isColumnSeen: true,
      isContentArray: false,
      isIconType: false,
      isDataClickedToMakeApiCall: false,
      isNamingConventionReq: false,
    },
    pickupLocation: {
      columnDisplayName: 'Pickup Location',
      filterFieldName: 'pickupLocation',
      isFilterable: true,
      isSortable: false,
      isColumnSeen: true,
      isContentArray: false,
      isDataClickedToMakeApiCall: false,
      isNamingConventionReq: false,
      isTextData: true,
      filterMenuHeaderText: 'Select Pickup Location',
      filterMenuType: 'groupedMultiSelectFromOptions',
      availableOptionsKeysList: [],
      availableOptions: {},
      availableOptionsArray: []
    },
    pickupCity: {
      columnDisplayName: "Pickup City",
      filterFieldName: "pickupCity",
      isFilterable: true,
      isSortable: false,
      isColumnSeen: false,
      filterMenuHeaderText: "Select Pickup cities",
      filterMenuType: "multiSelectFromOptions",
      availableOptionsKeysList: [],
      availableOptions: {},
      availableOptionsArray: [],
      isContentArray: false,
      prevSelectedCities: [],
      isIconType: false,
      isDataClickedToMakeApiCall: false,
      isNamingConventionReq: false,
    },
    expectedDeliveryDateTimeDetails: {
      columnDisplayName: "Expected Delivery",
      filterFieldName: "expectedDeliveryDateTimeDetails",
      isFilterable: true,
      isSortable: false,
      isColumnSeen: true,
      filterMenuHeaderText: 'Select date range',
      filterMenuType: 'dateRange',
      startDate: new Date(),
      endDate: new Date(),
      isContentArray: false,
      isIconType: false,
      isDataClickedToMakeApiCall: false,
      isNamingConventionReq: false,
    },
    orderStatus: {
      columnDisplayName: 'Status',
      filterFieldName: 'orderStatus',
      isFilterable: false,
      isSortable: false,
      isColumnSeen: true,
      isContentArray: false,
      isIconType: false,
      isDataClickedToMakeApiCall: false,
      isNamingConventionReq: true,
      isUpdatable: true,
      showCustomBadge: true,
      dropdownContionalText: CONSTANT.DELIVERY_STATUS.LABELS.RETURNING_TO
    },

    statusDateAndTime: {
      columnDisplayName: 'Status Date and Time',
      filterFieldName: 'statusDateAndTime',
      isFilterable: true,
      isSortable: false,
      isColumnSeen: false,
      isContentArray: false,
      isIconType: false,
      isDataClickedToMakeApiCall: false,
      isNamingConventionReq: false,
    },
    etaDetails: {
      columnDisplayName: "ETA",
      filterFieldName: "etaDetails",
      isFilterable: false,
      isSortable: false,
      isColumnSeen: true,
      isContentArray: false,
      isDataClickedToMakeApiCall: true,
      isIconType: false,
      isNamingConventionReq: false,
    },
    tempDetails: {
      columnDisplayName: "Temperature",
      filterFieldName: "tempDetails",
      isFilterable: false,
      isSortable: false,
      isColumnSeen: true,
      isContentArray: false,
      isDataClickedToMakeApiCall: false,
      isIconType: false,
      isNamingConventionReq: false,
    },
    customerTrack: {
      columnDisplayName: "Customer Track",
      filterFieldName: "customerTrack",
      isColumnSeen: true,
      isContentArray: false,
      isIconType: true,
      isDataClickedToMakeApiCall: false,
      imageSource: 'pi pi-telegram',
      isNamingConventionReq: false,

    },
    removeOrderAssignment: {
      columnDisplayName: 'Delete',
      filterFieldName: "removeOrderAssignment",
      isColumnSeen: true,
      isContentArray: false,
      isIconType: true,
      imageSource: 'pi pi-trash',
      isDataClickedToMakeApiCall: false,
      isNamingConventionReq: false,
    },
  },
  tableHeaderO2DOrders: undefined,
  tableHeaderO2DPending: undefined,
  tableHeaderO2DProcessing: undefined,
  tableHeaderO2DDispatch: undefined,
  tableHeaderO2DProgress: undefined,
  mmdConsignmentTableHeaderData: undefined,
  mmdConsignmentAssignTableHeaderData: undefined,
  mmdConsignmentQueueTableHeaderData: undefined,
  mmdVehicleAssignTableHeaderData: undefined,
  mmdVehicleQueueTableHeaderData: undefined,
  lmdOrderTableHeaderData: undefined,
  lmdOrderQueueTableHeaderData: undefined,
  lmdOrderAssignTableHeaderData: undefined,
  lmdAgentAssignTableHeaderData: undefined,
  kioskOrderTableHeaderData: undefined,
  kioskTodayOrderTableHeaderData: undefined,
  kioskCDSTableHeaderData: undefined,
  kioskKDSTableHeaderData: undefined,
  testError: undefined,
  isMobileView: undefined,
  linkedAssets: null,
  temp: [],
  routePlanCount: null,
  routePlans: [],
  groupedRoutePlans:[],
  geoFenceForAssetData: {},
  assetCardsImageDisplayStatus:{},
  isActionButtonNeededInRoutePlan:true,
  communicationTableHeader: {
    created: {
      columnDisplayName: "app.tableHeader.date",
      filterFieldName: "created",
      isFilterable: true,
      isSortable: true,
      isColumnSeen: true,
      filterMenuHeaderText: "Select date range",
      filterMenuType: "dateRange",
      startDate: new Date(),
      endDate: new Date(),
      isContentArray: false,
      isDefaultFilter: true,
    },
    name: {
      columnDisplayName: 'app.tableHeader.recipientName',
      filterFieldName: 'name',
      isFilterable: false,
      isSortable: false,
      isColumnSeen: true,
      isContentArray: false,
      isClickable:false
    },
    orderId: {
      columnDisplayName: 'app.tableHeader.orderId',
      filterFieldName: 'orderId',
      isFilterable: false,
      isSortable: false,
      isColumnSeen: true,
      isContentArray: false,
      isClickable:false
    },
    mobileNumber: {
      columnDisplayName: 'app.tableHeader.mobileNo',
      filterFieldName: 'mobileNumber',
      isFilterable: false,
      isSortable: false,
      isColumnSeen: true,
      isContentArray: false,
      isClickable: false,
    },
    emailId: {
      columnDisplayName: 'app.tableHeader.email',
      filterFieldName: 'emailId',
      isFilterable: false,
      isSortable: false,
      isColumnSeen: true,
      isContentArray: false,
      isClickable: false,
    },
    type: {
      columnDisplayName: 'app.tableHeader.channel',
      filterFieldName: 'type',
      isFilterable: true,
      isSortable: false,
      isColumnSeen: true,
      filterMenuHeaderText: 'Select Channel type',
      filterMenuType: 'singleSelectFromOptions',
      availableOptionsKeysList: ['email', 'sms','whatsApp'],
      availableOptions: {
        email: {
          filterParameterValue: 'email',
          name: 'Email',
          isSelected: false,
        },
        sms: {
          filterParameterValue: 'sms',
          name: 'SMS',
          isSelected: false,
        },
        whatsapp: {
          filterParameterValue: 'whatsapp',
          name: 'WhatsApp',
          isSelected: false,
        },
      },
      availableOptionsArray: [
        {
          filterParameterValue: 'email',
          name: 'Email',
          field: 'email',
          isSelected: false,
        },
        {
          filterParameterValue: 'sms',
          name: 'SMS',
          field: 'sms',
          isSelected: false,
        },
        {
          filterParameterValue: 'whatsapp',
          name: 'WhatsApp',
          field: 'whatsapp',
          isSelected: false,
        },
      ],
      isContentArray: false,
      isNamingConventionReq: true,
    },
    content: {
      columnDisplayName: "app.tableHeader.content",
      filterFieldName: 'content',
      id: "viewContent",
      label: "View Content",
      isColumnSeen: true,
      showIcon: true,
      icon: 'pi pi-eye',
      isSingleAction: true
    }
  },
  menu:[],
  currentPageNumber:{
    assetType:'',
    skip:0,
    limit:null
  },
  warehouseTableHeader:  {
    orderId: {
      columnDisplayName: 'app.tableHeader.id',
      filterFieldName: 'orderId',
      isFilterable: false,
      isSortable: true,
      isColumnSeen: true,
      isContentArray: false,
      isClickable: true,
    },
    batchNo: {
      columnDisplayName: 'app.tableHeader.batchNo',
      filterFieldName: 'batchNo',
      isFilterable: false,
      isSortable: true,
      isColumnSeen: true,
      isContentArray: false,
      isClickable: true
    },
    created: {
      columnDisplayName: "app.tableHeader.created",
      filterFieldName: "created",
      isFilterable: true,
      isSortable: true,
      isColumnSeen: true,
      filterMenuHeaderText: "Select date range",
      filterMenuType: "dateRange",
      startDate: new Date(),
      endDate: new Date(),
      isContentArray: false,
      isDefaultFilter: true,
    },
    pickupLocation: {
      columnDisplayName: 'app.tableHeader.from',
      filterFieldName: 'pickupLocation',
      isFilterable: true,
      isSortable: true,
      isColumnSeen: true,
      filterMenuHeaderText: 'Select Pickup Locations',
      filterMenuType: 'groupedMultiSelectFromOptions',
      availableOptionsKeysList: [],
      availableOptions: {},
      availableOptionsArray: [],
      isContentArray: false,
    },
    pickupCity: {
      columnDisplayName: 'app.tableHeader.city',
      filterFieldName: 'pickupCity',
      isFilterable: true,
      isSortable: true,
      isColumnSeen: true,
      filterMenuHeaderText: 'Select Pickup cities',
      filterMenuType: 'multiSelectFromOptions',
      availableOptionsKeysList: [],
      availableOptions: {},
      availableOptionsArray: [],
      isContentArray: false,
      prevSelectedCities: [],
    },
    custDetails: {
      columnDisplayName: 'app.tableHeader.to',
      filterFieldName: 'custDetails',
      isFilterable: false,
      isSortable: true,
      isColumnSeen: true,
      isContentArray: true,
    },
    items: {
      columnDisplayName: 'app.tableHeader.items',
      filterFieldName: 'items',
      isFilterable: false,
      isSortable: false,
      isColumnSeen: true,
      isContentArray: true,
    },
    assetName: {
      columnDisplayName: 'app.tableHeader.product',
      filterFieldName: 'assetName',
      isFilterable: false,
      isSortable: true,
      isColumnSeen: true,
      isContentArray: false,
      isClickable: false
    },
    skuCode:{
      columnDisplayName: 'app.tableHeader.skuCode',
      filterFieldName: 'skuCode',
      isFilterable: false,
      isSortable: true,
      isColumnSeen: true,
      isContentArray: false,
      isClickable: false
    },
    quantity: {
      columnDisplayName: 'app.tableHeader.availableQty',
      filterFieldName: 'quantity',
      isFilterable: false,
      isSortable: true,
      isColumnSeen: true,
      isContentArray: false,
      isClickable: false
    },
    soldQuantity: {
      columnDisplayName: 'app.tableHeader.soldQty',
      filterFieldName: 'soldQuantity',
      isFilterable: false,
      isSortable: true,
      isColumnSeen: true,
      isContentArray: false,
      isClickable: false
    },
    expiredQuantity: {
      columnDisplayName: 'app.tableHeader.expiredQty',
      filterFieldName: 'expiredQuantity',
      isFilterable: false,
      isSortable: true,
      isColumnSeen: true,
      isContentArray: false,
      isClickable: false
    },
    expiryDate: {
      columnDisplayName: 'app.tableHeader.expiryDate',
      filterFieldName: 'expiryDate',
      isFilterable: false,
      isSortable: true,
      isColumnSeen: true,
      filterMenuHeaderText: 'Select date range',
      filterMenuType: 'dateRange',
      startDate: new Date(),
      endDate: new Date(),
      isContentArray: false,
      isDefaultFilter: false
    },
    expectedDeliveryDateTimeDetails:{
      columnDisplayName: 'app.tableHeader.expectedDeliveryDateTimeDetails',
      filterFieldName: 'expectedDeliveryDateTimeDetails',
      isFilterable: true,
      isSortable: true,
      isColumnSeen: true,
      filterMenuHeaderText: 'Select date range',
      filterMenuType: 'dateRange',
      startDate: new Date(),
      endDate: new Date(),
      isContentArray: false,
      isDefaultFilter: false
    },
    stockLocationName: {
      columnDisplayName: 'app.tableHeader.stockLocationName',
      filterFieldName: 'stockLocationName',
      isFilterable: false,
      isSortable: true,
      isColumnSeen: true,
      isContentArray: false,
      isClickable: false
    },
    orderStatus: {
      columnDisplayName: 'app.tableHeader.orderStatus',
      filterFieldName: 'orderStatus',
      isFilterable: true,
      isSortable: true,
      isColumnSeen: true,
      isNamingConventionReq: true,
      filterMenuHeaderText: 'Select Status',
      filterMenuType: 'singleSelectFromOptions',
      availableOptionsKeysList: ['inTransit', 'inStock', 'soldOut'],
      availableOptions: {
        inTransit: {
          filterParameterValue: 'inTransit',
          name: 'In Transit',
          isSelected: false,
        },
        inStock: {
          filterParameterValue: 'inStock',
          name: 'in Stock',
          isSelected: false,
        },
        soldOut: {
          filterParameterValue: 'soldOut',
          name: 'Sold Out',
          isSelected: false,
        },
      },
      availableOptionsArray: [
        {
          filterParameterValue: 'inTransit',
          name: 'In Transit',
          field: 'inTransit',
          isSelected: false,
        },
        {
          filterParameterValue: 'inStock',
          name: 'In Stock',
          field: 'inStock',
          isSelected: false,
        },
        {
          filterParameterValue: 'soldOut',
          name: 'Sold Out',
          field: 'soldOut',
          isSelected: false,
        },
      ],
      isContentArray: false,
    },
    fulfillment: {
      columnDisplayName: "app.tableHeader.fulfillment",
      filterFieldName: 'fulfillment',
      id: "fulfillment",
      label: "Fulfillment",
      isColumnSeen: true,
      showIcon: true,
      icon: 'pi pi-check-circle',
      isSingleAction: true
    },
    action: {
      columnDisplayName: "app.tableHeader.action",
      filterFieldName: 'action',
      isFilterable: false,
      isSortable: false,
      isColumnSeen: true,
      isContentArray: false,
      isNamingConventionReq: true,
      showIconList: true,
      iconsList: []
    }
  },
  warehouseRequisitionTableHeader: undefined,
  warehouseFulfillmentTableHeader: undefined,
  warehouseSupplyTableHeader: undefined,
  warehouseProgressTableHeader: undefined,
  warehouseOutboundTableHeader: undefined,
  multipleAssetCreationStatus: 0,
  allSchedules: {},
  todaySchedules: {},
  conflictingSchedules:{
    withRoutePlan:[],
    withCarrier:[]
  }
};


export const AssetsReducers = createReducer(
  initialState,
  on(AssetsActions.setLoaded, (state, {data}) => {
    return {
      ...state,
      hasLoaded: data
    };
  }),
  on(AssetsActions.isActionButtonNeeded,(state,{value})=>{
    return{
      ...state,
      isActionButtonNeededInRoutePlan:value
    }
  }),
  on(AssetsActions.failure, (state, {error}) => {
    console.error(error);
    return {
      ...state,
      testError: error
    };
  }),
  on(AssetsActions.loadAssetsConfigSuccess, (state, {data}) => {
    return {
      ...state,
      assetConfig: data
    };
  }),
  on(AssetsActions.loadAssetDefaultImageSuccess, (state, {data}) => {
    return {
      ...state,
      assetDefaultImageConfigs: {...state.assetDefaultImageConfigs, ...data}
    };
  }),
  on(AssetsActions.setSelectedAssetType, (state, {assetType}) => {
    return {
      ...state,
      selectedAssetType: assetType
    };
  }),
  on(AssetsActions.loadAssetsFromStateInitially, (state,{skip}) => {
    const aT = state.selectedAssetType;
    const selectedData = state?.[aT] ? [...state[aT]] : [];
    const selectedAssetArr = [];
    for (let i = skip; i < skip+CURRENT_LIMIT_PER_PAGE; i++) {
      if (selectedData[i])
        selectedAssetArr.push(selectedData[i])
    }

    return {
      ...state,
      selectedAssetData: selectedAssetArr
    };
  }),
  on(AssetsActions.loadAssetCountByTypeSuccess, (state, {data}) => {
    return {
      ...state,
      counts: {...state.counts, ...data}
    };
  }),
  on(AssetsActions.setAssetCountInConfig, (state, {assetType, count}) => {
    const copy = {...state.counts}
    if (copy[assetType])
      copy[assetType] = {...copy[assetType], count: count}
    return {
      ...state,
      counts: copy
    };
  }),
  on(AssetsActions.loadAssetsFromState, (state, {assetType, skip, limit}) => {
    const selectedData = state[assetType] ? [...state[assetType]] : [];
    const selectedAssetArr = [];
    const l = skip + limit;
    for (let i = skip; i < l; i++) {
      if (selectedData[i])
        selectedAssetArr.push(selectedData[i])
    }

    return {
      ...state,
      selectedAssetData: selectedAssetArr
    };
  }),
  on(AssetsActions.loadAssetsFromEffectsSuccess, (state, {data}) => {
    return {
      ...state,
      selectedAssetData: data
    };
  }),
  on(AssetsActions.loadAssetLinkedGroupsSuccess, (state, {data}) => {
    return {
      ...state,
      assetLinkedGroups: {...state.assetLinkedGroups, ...data}
    };
  }),
  on(AssetsActions.loadAssetByTypeSuccess, (state, { assetType, data }) => {
    let updatedState;
    switch (assetType) {
      case CONSTANT.ASSET_TYPES.CITY:
        const othoc = {...state.tableHeaderData};
        othoc.pickupCity = updatePickUp(othoc?.pickupCity, [...data as ICity[]]);

        const cities = [...data, {city: 'City Unknown', _id: 'notAssigned'}]

        const k_main = {...state.kioskTableHeaderData};
        k_main.pickupCity = updatePickUp(k_main?.pickupCity, [...(cities as ICity[]),]);

        updatedState = {
          ...state,
          city: data as ICity[],
          tableHeaderData: {...othoc},
          hasLoaded: CONSTANT.ASSET_TYPES.CITY,
          kioskTableHeaderData: {...k_main}
        };
        break;
      case CONSTANT.ASSET_TYPES.LOCATION:
        const othdl = {...state.tableHeaderData};


        updatedState = {
          ...state,
          location: data as ILocation[],
          tableHeaderData: othdl,
          hasLoaded: CONSTANT.ASSET_TYPES.LOCATION,
        };
        break;
      case CONSTANT.ASSET_TYPES.LOGISTIC_PROVIDER:
        const othdlp = {...state?.tableHeaderData};
        othdlp.logistic = updateLogistic(othdlp?.logistic, [...data as ILogisticProvider[]]);
        updatedState = {
          ...state,
          logisticProvider: data as ILogisticProvider[],
          tableHeaderData: othdlp,
          hasLoaded: CONSTANT.ASSET_TYPES.LOGISTIC_PROVIDER
        };
        break;
      case CONSTANT.ASSET_TYPES.DELIVERY_CENTER:
        const dthoc = {...state.consignmentTableHeaderData};
        const othdc = { ...state.tableHeaderData };
        const dataDeliveryCenter = data || []
        dthoc.deliveryLoc = updateDeliveryCenter(dthoc.deliveryLoc, [...dataDeliveryCenter as IDeliveryCenterAsset[]]);
        othdc.deliveryCenterDetails = updateDeliveryCenter(othdc.deliveryCenterDetails, [...dataDeliveryCenter as IDeliveryCenterAsset[]]);
        updatedState = {
          ...state,
          deliveryCenter: dataDeliveryCenter as IDeliveryCenterAsset[],
          consignmentTableHeaderData: dthoc,
          tableHeaderData: othdc,
          hasLoaded: CONSTANT.ASSET_TYPES.DELIVERY_CENTER
        };
        break;
      case CONSTANT.ASSET_TYPES.CATEGORY:
        const dataCopy = data || []

        const seq = setSequence([...dataCopy as ICategory[]])
        updatedState = {
          ...state,
          category: dataCopy,
          categoryReordered: {},
          categories: seq
        };
        break;
      case CONSTANT.ASSET_TYPES.VEHICLE:
        const schthoc = {...state.scheduleTableHeader};
        const vehicleData = data || []
        schthoc.carrier = updateAllVehicle(schthoc.carrier, [...vehicleData as IVehicleAsset[]]);
          updatedState = {
            ...state,
            vehicle: vehicleData as IVehicleAsset[],
            scheduleTableHeader: schthoc,
          };
        break;
      /* case CONSTANT.ASSET_TYPES.ORDER:
        updatedState = {
          ...state,
          order: data as IOrderAsset[]
        };
        break;
      case CONSTANT.ASSET_TYPES.PRODUCT:
        updatedState = {
          ...state,
          product: data as IProductAsset[]
        };
        break;
     
      case CONSTANT.ASSET_TYPES.MOBILE:
        updatedState = {
          ...state,
          mobile: data as IMobileAsset[]
        };
        break;
      case CONSTANT.ASSET_TYPES.SENSOR:
        updatedState = {
          ...state,
          sensor: data as ISensorAsset[]
        };
        break;
      case CONSTANT.ASSET_TYPES.DELIVERY_PERSON:
        updatedState = {
          ...state,
          deliveryPerson: data as IDeliveryPersonAsset[]
        };
        break;
      case CONSTANT.ASSET_TYPES.COLD_CHAIN_BOX:
        updatedState = {
          ...state,
          coldChainBox: data as IColdChainBoxAsset[]
        };
        break;
      case CONSTANT.ASSET_TYPES.ZONE:
        updatedState = {
          ...state,
          zones: data as IZoneAsset[]
        };
        break; */
      default:
        updatedState = {
          ...state,
          temp: [...state.temp, data],
          // [assetType]: [...(state[assetType] ?? []) , ...data]
          [assetType]: data
        };
        break;
    }

    return {...updatedState};
  }),
  on(AssetsActions.loadOrderTableHeaderDataSuccess, (state, {id, fields,isCustomerCollabration}) => {
    let updatedState = {...state};
    const isMobileView = structuredClone(state.isMobileView);
    let copy: IOderTableHeaderData;
    if (!isMobileView) {
      copy = handleTableColumnsToDisplay({...state.tableHeaderData}, fields);
    } else {
      copy = handleTableColumnsToDisplayInMobileView({...state.tableHeaderData}, fields, id);
    }

    // Handeling case for customer collaboration casl
    if(!isCustomerCollabration){
      copy = handleTableheaderForCustCollab({...copy})
    }
    
    let lp: ILogisticProvider[] = [...state.logisticProvider];
    copy.pickupCity = updatePickUp(copy.pickupCity, [...state.city]);
    switch (id) {
      case CONSTANT.TABLE_SESSION_NAME.O2D_ORDER_TAB:
        delete copy.removeAction;
        if (lp.length > 0) {
          lp = [...lp, {name: 'All', _id: null, assetType: '', serviceAssets: '', uniqueId: ''}];
          copy.logistic = updateLogistic(copy.logistic, lp);
        }
        copy.pickupLocation = updateLocation(copy.pickupLocation, [...state.location], state.tableFilterMetas?.[id]);

        updatedState = {...updatedState, tableHeaderO2DOrders: copy};
        break;
      case CONSTANT.TABLE_SESSION_NAME.O2D_PENDING_TAB:
        delete copy.removeAction;
        delete copy.logistic;
        delete copy.lpStatus;
        copy.pickupLocation = updateLocation(copy.pickupLocation, [...state.location], state.tableFilterMetas?.[id]);
        updatedState = {...updatedState, tableHeaderO2DPending: copy};
        break;
      case CONSTANT.TABLE_SESSION_NAME.O2D_PROCESSING_TAB:
        delete copy.removeAction;
        delete copy.logistic;
        delete copy.lpStatus;
        delete copy.orderType;
        copy.pickupLocation = updateLocation(copy.pickupLocation, [...state.location], state.tableFilterMetas?.[id]);
        updatedState = {...updatedState, tableHeaderO2DProcessing: copy};
        break;
      case CONSTANT.TABLE_SESSION_NAME.O2D_DISPATCH_TAB:
        delete copy.removeAction;
        delete copy.lpStatus;
        delete copy.orderType;
        copy.pickupLocation = updateLocation(copy.pickupLocation, [...state.location], state.tableFilterMetas?.[id]);
        copy.logistic = {...copy.logistic, isFilterable: false};
        updatedState = {...updatedState, tableHeaderO2DDispatch: copy};
        break;
      case CONSTANT.TABLE_SESSION_NAME.O2D_PROGRESS_TAB:
        delete copy.lpStatus;
        delete copy.orderType;
        copy.pickupLocation = updateLocation(copy.pickupLocation, [...state.location], state.tableFilterMetas?.[id]);
        copy.logistic = { ...copy.logistic, isFilterable: true };
        updatedState = {...updatedState, tableHeaderO2DProgress: copy};
        break;
      case CONSTANT.TABLE_SESSION_NAME.KIOSK.ORDER:
        const kothd = {...state.kioskOrderTableHeaderData}
        kothd.pickupLocation = updateLocation(kothd.pickupLocation, [...state.location], state.tableFilterMetas?.[id]);
        updatedState = {...updatedState, kioskOrderTableHeaderData: {...kothd}};
        break;
      default:
        break;
    }

    return updatedState;
  }),
  on(AssetsActions.loadRoutePlanTableHeaderData, (state, {id}) => {
    let updatedState = {...state};
    const isMobileView = structuredClone(state.isMobileView);
    let isActionBtnnReq = state.isActionButtonNeededInRoutePlan
    let copy: Partial<IRoutePlanTableHeaders>;
    if (!isMobileView) {
      copy = {...state.routePlanTableHeader};
    } else {
      copy = handleTableColumnsToDisplayInMobilewithoutSetting({...state.routePlanTableHeader}, id);
    }
    if(!isActionBtnnReq){
      delete copy.removeAction
    }
    copy.pickupCity = updatePickUp(copy.pickupCity, [...state.city]);
    switch (id) {
      case CONSTANT.TABLE_SESSION_NAME.MMD.ROUTE_PLAN:

        copy.pickupLocation = updateLocation(copy.pickupLocation, [...state.location], state.tableFilterMetas?.[id]);

        updatedState = {...updatedState, routePlanTableHeader: copy};
        break;

    }
    return updatedState;

  }),
  on(AssetsActions.loadScheduleTableHeaderData, (state, { id }) => {
    let updatedState = { ...state };
    const isMobileView = structuredClone(state.isMobileView);
    let copy: Partial<IScheduleTableHeaders>;
    const schedulesKey = id === CONSTANT.TABLE_SESSION_NAME.MMD.ALL_SCHEDULE ? 'allScheduleTableHeader' : 
                         id === CONSTANT.TABLE_SESSION_NAME.MMD.TODAY_SCHEDULE ? 'todayScheduleTableHeader'  : ''

    const combinedData = {...state.scheduleTableHeader,...state[schedulesKey]}

    if (!isMobileView) {
      copy = combinedData;
    } else {
      copy = handleTableColumnsToDisplayInMobilewithoutSetting(combinedData, id);
    }
   
    copy.pickupCity = updatePickUp(copy.pickupCity, [...state.city]);
    copy.pickupLocation = updateLocation(copy.pickupLocation, [...state.location], state.tableFilterMetas?.[id]);
    copy.routePlans = updateRoutePlans(copy.routePlans, [...state.routePlans]);
    copy.routePlans.groupRoutePlanArray =  groupByCategory([...state.routePlans]) ;
    const aO = getCarrierTableFilter(state.allScheduleTableHeader, '_id', '_id', [...state.vehicle], 'carrier');
    copy.carrier = {...copy.carrier, availableOptionsArray: aO.availableOptionsArray, availableOptionsKeysList: aO.availableOptionsKeysList, availableOptions: aO.availableOptions};

    updatedState = { ...updatedState, [schedulesKey]: copy };
    return updatedState;

  }),
  on(AssetsActions.loadCommunicationHeaderData, (state, {id}) => {
    let updatedState = {...state};
    const isMobileView = structuredClone(state.isMobileView);
    let copy: ICommunicationTableHeaders;
    if (!isMobileView) {
      copy = {...state.communicationTableHeader};
    } else {
      copy = handleTableColumnsToDisplayInMobilewithoutSetting({...state.communicationTableHeader}, id);
    }
    switch (id) {
      case CONSTANT.TABLE_SESSION_NAME.COMMUNICATION.TABLE:
        updatedState = {...updatedState, communicationTableHeader: copy};
        break;
    }
    return updatedState;

  }),
  on(AssetsActions.loadExpansionConsignmentTableHeaderData, (state, {id}) => {
    let updatedState = {...state};
    const expansionHeaderCopy: IExpansionConsignmentHeader = {...state.expansionRowTableHeadings};
    expansionHeaderCopy.pickupCity = updatePickUp(expansionHeaderCopy.pickupCity, [...state.mmdCities]);
    expansionHeaderCopy.routePlans = updateRoutePlans(expansionHeaderCopy.routePlans, [...state.routePlans]);
    switch (id) {
      case CONSTANT.TABLE_SESSION_NAME.MMD.CONSIGNMENT:
        updatedState = {...updatedState, expansionRowTableHeadings: expansionHeaderCopy};
        break;
    }

    return updatedState;
  }),
  on(AssetsActions.loadConsignmentTableHeaderData, (state, {id}) => {
    let updatedState = {...state};
    const isMobileView = structuredClone(state.isMobileView);
    let copy: IConsignmentTableHeaderData = {...state.consignmentTableHeaderData};
    copy.pickupCity = updatePickUp(copy.pickupCity, [...state.mmdCities]);

    switch (id) {
      case CONSTANT.TABLE_SESSION_NAME.MMD.CONSIGNMENT:
        delete copy.custDetails;
        delete copy.items;
        copy.pickupLocation = updateLocation(copy.pickupLocation, [...state.location], state.tableFilterMetas?.[id]);
        updatedState = {...updatedState, mmdConsignmentTableHeaderData: copy};
        break;
      case CONSTANT.TABLE_SESSION_NAME.MMD.CONSIGNMENT_ASSIGN:
        delete copy.custDetails;
        delete copy.items;
        delete copy.vehicleAssignedStatus;
        delete copy.orderStatus;
        delete copy.orderSource;
        delete copy.deliverySlot;
        delete copy.removeAction;
        delete copy.created;
        copy.pickupLocation = updateLocation(copy.pickupLocation, [...state.location], state.tableFilterMetas?.[id]);
        if (isMobileView) {
          copy = handleTableColumnsToDisplayInMobilewithoutSetting({...copy}, id);
        }
        updatedState = {...updatedState, mmdConsignmentAssignTableHeaderData: copy};
        break;
      case CONSTANT.TABLE_SESSION_NAME.MMD.CONSIGNMENT_QUEUE:
        delete copy.vehicleAssignedStatus;
        delete copy.deliverySlot;
        delete copy.orderStatus;
        delete copy.created;
        copy.pickupLocation = updateLocation(copy.pickupLocation, [...state.location], state.tableFilterMetas?.[id]);
        updatedState = {...updatedState, mmdConsignmentQueueTableHeaderData: copy};
        break;
      case CONSTANT.TABLE_SESSION_NAME.MMD.ORDER:
        delete copy.custDetails;
        delete copy.items;
        delete copy.removeAction
        copy.pickupLocation = updateLocation(copy.pickupLocation, [...state.location], state.tableFilterMetas?.[id]);
        if (isMobileView) {
          copy = handleTableColumnsToDisplayInMobilewithoutSetting({...copy}, id);
        }
        updatedState = {...updatedState, mmdConsignmentTableHeaderData: copy};
        break;
      
      
      
      default:
        break;
    }

    return updatedState;
  }),
  on(AssetsActions.loadLmdOrderTableHeaderData, (state, {id}) => {
    let updatedState = {...state};
    const isMobileView = structuredClone(state.isMobileView);
    let copy: ILmdOderTableHeaderData;
    if (!isMobileView) {
      copy = {...state.orderTableHeaderData};
    } else {
      copy = handleTableColumnsToDisplayInMobilewithoutSetting({...state.orderTableHeaderData}, id);
    }

    copy.pickupCity = updatePickUp(copy.pickupCity, [...state.lmdCities]);
    copy.pickupLocation = updateLocation(copy.pickupLocation, [...state.location], state.tableFilterMetas?.[id]);

    switch (id) {
      case CONSTANT.TABLE_SESSION_NAME.LMD.ORDER:
        updatedState = {...updatedState, lmdOrderTableHeaderData: copy};
        break;
      case CONSTANT.TABLE_SESSION_NAME.LMD.ORDER_QUEUE:
        delete copy.created;
        updatedState = {...updatedState, lmdOrderQueueTableHeaderData: copy};
        break;
      case CONSTANT.TABLE_SESSION_NAME.LMD.ORDER_ASSIGN:
        delete copy.agentDetails;
        delete copy.items;
        delete copy.orderStatus;
        delete copy.orderSource;
        delete copy.deliverySlot;
        delete copy.removeAction;
        delete copy.created;
        updatedState = {...updatedState, lmdOrderAssignTableHeaderData: copy};
        break;
      default:
        break;
    }

    return updatedState;
  }),
  on(AssetsActions.loadVehicleTableHeaderData, (state, {id}) => {
    let updatedState = {...state};
    const copy: IVehicleTableHeaderData = {...state.vehicleTableHeaderData};

    switch (id) {
      case CONSTANT.TABLE_SESSION_NAME.MMD.VEHICLE_ASSIGN:
        delete copy.removeAction;
        copy.name = {...copy.name, isClickable: false, isSortable: false}
        updatedState = {...updatedState, mmdVehicleAssignTableHeaderData: copy};
        break;
      case CONSTANT.TABLE_SESSION_NAME.MMD.VEHICLE_QUEUE:
        updatedState = {...updatedState, mmdVehicleQueueTableHeaderData: copy};
        break;
      default:
        break;
    }

    return updatedState;
  }),
  on(AssetsActions.loadAgentTableHeaderData, (state, {id}) => {
    let updatedState = {...state};
    const copy: IAgentTableHeaderData = {...state.agentTableHeaderData};

    if (id === CONSTANT.TABLE_SESSION_NAME.LMD.AGENT_ASSIGN) {
      const filter = constructPickupCityFilterIfNotPresent([...state.lmdCities], {...state.tableFilterMetas?.[id]});
      copy.agentLocation = updateLocation(copy.agentLocation, [...state.location], filter);
      updatedState = {...updatedState, lmdAgentAssignTableHeaderData: copy};
    }

    return updatedState;
  }),
  on(AssetsActions.loadMmdManagementTableHeaderData, (state, {id}) => {
    let updatedState = {...state};
    let copy: IMmdManagementTableHeaderData;
    const isMobileView = structuredClone(state.isMobileView);
    if (!isMobileView) {
      copy = {...state.mmdManagementTableHeaderData};
    } else {
      copy = handleTableColumnsToDisplayInMobilewithoutSetting({...state.mmdManagementTableHeaderData}, id);
    }
    copy.pickupCity = updatePickUp(copy.pickupCity, [...state.mmdCities]);
    
    switch (id) {
      case CONSTANT.TABLE_SESSION_NAME.MMD.PROGRESS:
        copy.expectedDeliveryDateTimeDetails = {...copy.expectedDeliveryDateTimeDetails, isFilterable: false};
        copy.expectedDeliveryDateTimeDetails = {
          ...copy.expectedDeliveryDateTimeDetails,
          filterMenuType: 'emptydateRange'
        };
        copy.orderStatus = {...copy.orderStatus, isUpdatable: true};
        copy.pickupLocation = updateLocation(copy.pickupLocation, [...state.location], state.tableFilterMetas?.[id]);
        updatedState = {...updatedState, mmdManagementTableHeaderData: copy};
        break;
      case CONSTANT.TABLE_SESSION_NAME.MMD.DELIVERED:
        copy.expectedDeliveryDateTimeDetails = {...copy.expectedDeliveryDateTimeDetails, isFilterable: true};
        copy.expectedDeliveryDateTimeDetails = {...copy.expectedDeliveryDateTimeDetails, filterMenuType: 'dateRange'};
        copy.orderStatus = {...copy.orderStatus, isUpdatable: false};
        copy.pickupLocation = updateLocation(copy.pickupLocation, [...state.location], state.tableFilterMetas?.[id]);
        updatedState = {...updatedState, mmdManagementTableHeaderData: copy};
        break;
      default:
        break;
    }
    return updatedState;
  }),
  on(AssetsActions.loadLmdManagementTableHeaderData, (state, {id}) => {
    let updatedState = {...state};
    let copy: ILmdManagementTableHeaderData = {...state.lmdManagementTableHeaderData};
    const isMobileView = structuredClone(state.isMobileView);
    if (!isMobileView) {
      copy = {...state.lmdManagementTableHeaderData};
    } else {
      copy = handleTableColumnsToDisplayInMobilewithoutSetting({...state.lmdManagementTableHeaderData}, id);
    }
    copy.pickupCity = updatePickUp(copy.pickupCity, [...state.lmdCities]);

    switch (id) {
      case CONSTANT.TABLE_SESSION_NAME.LMD.PROGRESS:
        copy.expectedDeliveryDateTimeDetails = {...copy.expectedDeliveryDateTimeDetails, isFilterable: false};
        copy.expectedDeliveryDateTimeDetails = {
          ...copy.expectedDeliveryDateTimeDetails,
          filterMenuType: 'emptydateRange'
        };
        copy.orderStatus = {...copy.orderStatus, isUpdatable: true};
        copy.pickupLocation = updateLocation(copy.pickupLocation, [...state.location], state.tableFilterMetas?.[id]);
        updatedState = {...updatedState, lmdManagementTableHeaderData: copy};
        break;
      case CONSTANT.TABLE_SESSION_NAME.LMD.DELIVERED:
        copy.expectedDeliveryDateTimeDetails = {...copy.expectedDeliveryDateTimeDetails, isFilterable: true};
        copy.expectedDeliveryDateTimeDetails = {...copy.expectedDeliveryDateTimeDetails, filterMenuType: 'dateRange'};
        copy.orderStatus = {...copy.orderStatus, isUpdatable: false};
        copy.pickupLocation = updateLocation(copy.pickupLocation, [...state.location], state.tableFilterMetas?.[id]);
        updatedState = {...updatedState, lmdManagementTableHeaderData: copy};
        break;
      default:
        break;
    }
    return updatedState;
  }),
  on(AssetsActions.loadWearhouseTableHeaderData, (state, {id}) => {
    let updatedState = {...state};
    const isMobileView = structuredClone(state.isMobileView);
    let copy: IWarehouseTableHeaderData;
    if (!isMobileView) {
      copy = {...state.warehouseTableHeader};
    } else {
      copy = handleTableColumnsToDisplayInMobilewithoutSetting({...state.warehouseTableHeader}, id);
    }

    copy.pickupCity = updatePickUp(copy.pickupCity, [...state.city]);
    switch (id) {
      case CONSTANT.TABLE_SESSION_NAME.WAREHOUSE.REQUISITION:
        delete copy.batchNo;
        delete copy.expiryDate;
        delete copy.assetName;
        delete copy.skuCode;
        delete copy.quantity;
        delete copy.soldQuantity;
        delete copy.expiredQuantity;
        delete copy.stockLocationName;
        delete copy.fulfillment;
        delete copy.pickupCity;
        delete copy.pickupLocation;
        // delete copy.action;
        updatedState = {...updatedState, warehouseRequisitionTableHeader: copy};
        break;
      case CONSTANT.TABLE_SESSION_NAME.WAREHOUSE.SUPPLY:
        delete copy.orderId;
        delete copy.custDetails;
        delete copy.items;
        delete copy.expectedDeliveryDateTimeDetails;
        delete copy.fulfillment;
        delete copy.pickupCity;
        delete copy.pickupLocation;
        // delete copy.action;
        updatedState = {...updatedState, warehouseSupplyTableHeader: copy};
        break;
      case CONSTANT.TABLE_SESSION_NAME.WAREHOUSE.FULFILLMENT:
        delete copy.batchNo;
        delete copy.expiryDate;
        delete copy.assetName;
        delete copy.skuCode;
        delete copy.quantity;
        delete copy.soldQuantity;
        delete copy.expiredQuantity;
        delete copy.stockLocationName;
        delete copy.pickupCity;
        delete copy.pickupLocation;
        // delete copy.action;
        updatedState = {...updatedState, warehouseFulfillmentTableHeader: copy};
        break;
      case CONSTANT.TABLE_SESSION_NAME.WAREHOUSE.PROGRESS_TAB:
        delete copy.batchNo;
        delete copy.expiryDate;
        delete copy.assetName;
        delete copy.skuCode;
        delete copy.quantity;
        delete copy.soldQuantity;
        delete copy.expiredQuantity;
        delete copy.stockLocationName;
        delete copy.fulfillment;
        copy.orderStatus = {...copy.orderStatus, isSortable: false, isFilterable:false}
        updatedState = {...updatedState, warehouseProgressTableHeader: copy};
        break;
      case CONSTANT.TABLE_SESSION_NAME.WAREHOUSE.OUTBOUND_TAB:
          delete copy.batchNo;
          delete copy.expiryDate;
          delete copy.assetName;
          delete copy.skuCode;
          delete copy.quantity;
          delete copy.soldQuantity;
          delete copy.expiredQuantity;
          delete copy.stockLocationName;
          delete copy.fulfillment;
          copy.orderStatus = {...copy.orderStatus, isSortable: false, isFilterable:false}
          updatedState = {...updatedState, warehouseOutboundTableHeader: copy};
          break;
      default:
        break;
    }

    return updatedState;
  }),
  on(AssetsActions.updateDateRangeFieldDefaultFilterValue, (state, {id, key}) => {
    let updatedState = {...state};

    switch (id) {
      case CONSTANT.TABLE_SESSION_NAME.O2D_ORDER_TAB:
        const oOUpdated = updateDateRangeFieldInConfig({...state.tableHeaderO2DOrders}, key);
        const tableCopy = updateDateRangeFieldInConfig({...state.tableHeaderData}, key);
        updatedState = {...updatedState, tableHeaderO2DOrders: oOUpdated, tableHeaderData: tableCopy};
        break;
      case CONSTANT.TABLE_SESSION_NAME.O2D_PENDING_TAB:
        const oPendUpdated = updateDateRangeFieldInConfig({...state.tableHeaderO2DPending}, key);
        const oPtableCopy = updateDateRangeFieldInConfig({...state.tableHeaderData}, key);
        updatedState = {...updatedState, tableHeaderO2DPending: oPendUpdated, tableHeaderData: oPtableCopy};
        break;
      case CONSTANT.TABLE_SESSION_NAME.O2D_PROCESSING_TAB:
        const oProcUpdated = updateDateRangeFieldInConfig({...state.tableHeaderO2DProcessing}, key);
        const pProctableCopy = updateDateRangeFieldInConfig({...state.tableHeaderData}, key);
        updatedState = {...updatedState, tableHeaderO2DProcessing: oProcUpdated, tableHeaderData: pProctableCopy};
        break;
      case CONSTANT.TABLE_SESSION_NAME.O2D_DISPATCH_TAB:
        const oDisUpdated = updateDateRangeFieldInConfig({...state.tableHeaderO2DDispatch}, key);
        const oDistableCopy = updateDateRangeFieldInConfig({...state.tableHeaderData}, key);
        updatedState = {...updatedState, tableHeaderO2DDispatch: oDisUpdated, tableHeaderData: oDistableCopy};
        break;
      case CONSTANT.TABLE_SESSION_NAME.O2D_PROGRESS_TAB:
        const oProgUpdated = updateDateRangeFieldInConfig({...state.tableHeaderO2DProgress}, key);
        const oProtableCopy = updateDateRangeFieldInConfig({...state.tableHeaderData}, key);
        updatedState = {...updatedState, tableHeaderO2DProgress: oProgUpdated, tableHeaderData: oProtableCopy};
        break;
      case CONSTANT.TABLE_SESSION_NAME.MMD.ORDER:
        const mCUpdated = updateDateRangeFieldInConfig({...state.mmdConsignmentTableHeaderData}, key);
        const mCtableCopy: IConsignmentTableHeaderData = updateDateRangeFieldInConfig({...state.consignmentTableHeaderData}, key);
        updatedState = {
          ...updatedState,
          mmdConsignmentTableHeaderData: mCUpdated,
          consignmentTableHeaderData: mCtableCopy
        };
        break;
      case CONSTANT.TABLE_SESSION_NAME.MMD.CONSIGNMENT_QUEUE:
        const mCQUpdated = updateDateRangeFieldInConfig({...state.mmdConsignmentQueueTableHeaderData}, key);
        const mCQtableCopy: IConsignmentTableHeaderData = updateDateRangeFieldInConfig({...state.consignmentTableHeaderData}, key);
        updatedState = {
          ...updatedState,
          mmdConsignmentQueueTableHeaderData: mCQUpdated,
          consignmentTableHeaderData: mCQtableCopy
        };
        break;
      case CONSTANT.TABLE_SESSION_NAME.MMD.CONSIGNMENT_ASSIGN:
        const mCAssignUpdated = updateDateRangeFieldInConfig({...state.mmdConsignmentAssignTableHeaderData}, key);
        const mCAsstableCopy: IConsignmentTableHeaderData = updateDateRangeFieldInConfig({...state.consignmentTableHeaderData}, key);
        updatedState = {
          ...updatedState,
          mmdConsignmentAssignTableHeaderData: mCAssignUpdated,
          consignmentTableHeaderData: mCAsstableCopy
        };
        break;
      case CONSTANT.TABLE_SESSION_NAME.MMD.VEHICLE_ASSIGN:
        const mVAUpdated = {...state.mmdVehicleAssignTableHeaderData};
        updatedState = {...updatedState, mmdVehicleAssignTableHeaderData: mVAUpdated};
        break;
      case CONSTANT.TABLE_SESSION_NAME.LMD.ORDER:
        const lOUpdated = updateDateRangeFieldInConfig({...state.lmdOrderTableHeaderData}, key);
        const lOtableCopy: IConsignmentTableHeaderData = updateDateRangeFieldInConfig({...state.orderTableHeaderData}, key);
        updatedState = {...updatedState, lmdOrderTableHeaderData: lOUpdated, orderTableHeaderData: lOtableCopy};
        break;
      case CONSTANT.TABLE_SESSION_NAME.LMD.ORDER_QUEUE:
        const lOQUpdated = updateDateRangeFieldInConfig({...state.lmdOrderQueueTableHeaderData}, key);
        const lOQtableCopy: IConsignmentTableHeaderData = updateDateRangeFieldInConfig({...state.orderTableHeaderData}, key);
        updatedState = {...updatedState, lmdOrderQueueTableHeaderData: lOQUpdated, orderTableHeaderData: lOQtableCopy};
        break;
      case CONSTANT.TABLE_SESSION_NAME.LMD.ORDER_ASSIGN:
        const lOAUpdated = updateDateRangeFieldInConfig({...state.lmdOrderAssignTableHeaderData}, key);
        const lOAtableCopy: IConsignmentTableHeaderData = updateDateRangeFieldInConfig({...state.orderTableHeaderData}, key);
        updatedState = {...updatedState, lmdOrderAssignTableHeaderData: lOAUpdated, orderTableHeaderData: lOAtableCopy};
        break;
      case CONSTANT.TABLE_SESSION_NAME.LMD.AGENT_ASSIGN:
        const lAAUpdated = {...state.lmdAgentAssignTableHeaderData};
        updatedState = {...updatedState, lmdAgentAssignTableHeaderData: lAAUpdated};
        break;
      case CONSTANT.TABLE_SESSION_NAME.KIOSK.ORDER:
        const kOUpdated = updateDateRangeFieldInConfig({...state.kioskOrderTableHeaderData}, key);
        const kOtableCopy: IKioskTableHeaderData = updateDateRangeFieldInConfig({...state.kioskTableHeaderData}, key);
        updatedState = {...updatedState, kioskOrderTableHeaderData: kOUpdated, kioskTableHeaderData: kOtableCopy};
        break;
    case CONSTANT.TABLE_SESSION_NAME.WAREHOUSE.REQUISITION:
          const wrUpdated = updateDateRangeFieldInConfig({...state.warehouseRequisitionTableHeader}, key);
          const wrtableCopy = updateDateRangeFieldInConfig({...state.warehouseTableHeader}, key);
          updatedState = {...updatedState, warehouseRequisitionTableHeader: wrUpdated, warehouseTableHeader: wrtableCopy};
          break;
     case CONSTANT.TABLE_SESSION_NAME.WAREHOUSE.FULFILLMENT:
            const wFulUpdated = updateDateRangeFieldInConfig({...state.warehouseFulfillmentTableHeader}, key);
            const wFultableCopy = updateDateRangeFieldInConfig({...state.warehouseTableHeader}, key);
            updatedState = {...updatedState, warehouseFulfillmentTableHeader: wFulUpdated, warehouseTableHeader: wFultableCopy};
            break;
      case CONSTANT.TABLE_SESSION_NAME.WAREHOUSE.PROGRESS_TAB:
          const wProgUpdated = updateDateRangeFieldInConfig({...state.warehouseProgressTableHeader}, key);
          const wProtableCopy = updateDateRangeFieldInConfig({...state.warehouseTableHeader}, key);
          updatedState = {...updatedState, warehouseProgressTableHeader: wProgUpdated, warehouseTableHeader: wProtableCopy};
          break;
      case CONSTANT.TABLE_SESSION_NAME.WAREHOUSE.OUTBOUND_TAB:
            const wOutUpdated = updateDateRangeFieldInConfig({...state.warehouseOutboundTableHeader}, key);
            const wOuttableCopy = updateDateRangeFieldInConfig({...state.warehouseTableHeader}, key);
            updatedState = {...updatedState, warehouseOutboundTableHeader: wOutUpdated, warehouseTableHeader: wOuttableCopy};
            break;
      default:
        break;
    }

    return updatedState;
  }),
  on(AssetsActions.updateOrderTableHeaderDataIsColumSeen, (state, {id, key, data}) => {
    let updatedState = {...state};
    switch (id) {
      case CONSTANT.TABLE_SESSION_NAME.O2D_ORDER_TAB:
        const oOUpdated = {...state.tableHeaderO2DOrders};
        oOUpdated[key] = {...oOUpdated[key], isColumnSeen: data};
        updatedState = {...updatedState, tableHeaderO2DOrders: oOUpdated};
        break;
      case CONSTANT.TABLE_SESSION_NAME.O2D_PENDING_TAB:
        const oPendUpdated = {...state.tableHeaderO2DPending};
        oPendUpdated[key] = {...oPendUpdated[key], isColumnSeen: data};
        updatedState = {...updatedState, tableHeaderO2DPending: oPendUpdated};
        break;
      case CONSTANT.TABLE_SESSION_NAME.O2D_PROCESSING_TAB:
        const oProcUpdated = {...state.tableHeaderO2DProcessing};
        oProcUpdated[key] = {...oProcUpdated[key], isColumnSeen: data};
        updatedState = {...updatedState, tableHeaderO2DProcessing: oProcUpdated};
        break;
      case CONSTANT.TABLE_SESSION_NAME.O2D_DISPATCH_TAB:
        const oDisUpdated = {...state.tableHeaderO2DDispatch};
        oDisUpdated[key] = {...oDisUpdated[key], isColumnSeen: data};
        updatedState = {...updatedState, tableHeaderO2DDispatch: oDisUpdated};
        break;
      case CONSTANT.TABLE_SESSION_NAME.O2D_PROGRESS_TAB:
        const oProgUpdated = {...state.tableHeaderO2DProgress};
        oProgUpdated[key] = {...oProgUpdated[key], isColumnSeen: data};
        updatedState = {...updatedState, tableHeaderO2DProgress: oProgUpdated};
        break;
      case CONSTANT.TABLE_SESSION_NAME.MMD.ROUTE_PLAN:
        const mmdRouteCityUpdated = {...state.routePlanTableHeader};
        mmdRouteCityUpdated[key] = {...mmdRouteCityUpdated[key], isColumnSeen: data};
        updatedState = {...updatedState, routePlanTableHeader: mmdRouteCityUpdated};
        break;
      case CONSTANT.TABLE_SESSION_NAME.MMD.ALL_SCHEDULE:
        const mmdAllScheduleCityUpdated = { ...state.allScheduleTableHeader };
        mmdAllScheduleCityUpdated[key] = { ...mmdAllScheduleCityUpdated[key], isColumnSeen: data };
        updatedState = { ...updatedState, allScheduleTableHeader: mmdAllScheduleCityUpdated };
        break;
      case CONSTANT.TABLE_SESSION_NAME.MMD.TODAY_SCHEDULE:
        const mmdTodayScheduleCityUpdated = { ...state.todayScheduleTableHeader };
        mmdTodayScheduleCityUpdated[key] = { ...mmdTodayScheduleCityUpdated[key], isColumnSeen: data };
        updatedState = { ...updatedState, todayScheduleTableHeader: mmdTodayScheduleCityUpdated };
        break;
      case CONSTANT.TABLE_SESSION_NAME.MMD.ORDER:
        const mCUpdated = {...state.mmdConsignmentTableHeaderData};
        const mEUpdated = {...state.expansionRowTableHeadings};
        mCUpdated[key] = {...mCUpdated[key], isColumnSeen: data};
        mCUpdated.pickupCity = updatePickUp(mCUpdated.pickupCity, [...state.mmdCities]);
        mEUpdated.pickupCity = updatePickUp(mEUpdated.pickupCity, [...state.mmdCities]);

        const mCFilterData = constructPickupCityFilterIfNotPresent([...state.mmdCities], {...state.tableFilterMetas?.[id]});
        mCUpdated.pickupLocation = updateLocation(mCUpdated.pickupLocation, [...state.location], mCFilterData);
        updatedState = {
          ...updatedState,
          mmdConsignmentTableHeaderData: mCUpdated,
          expansionRowTableHeadings: mEUpdated
        };
        break;
      case CONSTANT.TABLE_SESSION_NAME.MMD.CONSIGNMENT_QUEUE:
        const mCQUpdated = {...state.mmdConsignmentQueueTableHeaderData};
        mCQUpdated[key] = {...mCQUpdated[key], isColumnSeen: data};
        mCQUpdated.pickupCity = updatePickUp(mCQUpdated.pickupCity, [...state.mmdCities]);

        const mCQFilterData = constructPickupCityFilterIfNotPresent([...state.mmdCities], {...state.tableFilterMetas?.[id]});
        mCQUpdated.pickupLocation = updateLocation(mCQUpdated.pickupLocation, [...state.location], mCQFilterData);
        updatedState = {...updatedState, mmdConsignmentQueueTableHeaderData: mCQUpdated};
        break;
      case CONSTANT.TABLE_SESSION_NAME.MMD.CONSIGNMENT_ASSIGN:
        const mCAssignUpdated = {...state.mmdConsignmentAssignTableHeaderData};
        mCAssignUpdated[key] = {...mCAssignUpdated[key], isColumnSeen: data};
        mCAssignUpdated.pickupCity = updatePickUp(mCAssignUpdated.pickupCity, [...state.mmdCities]);

        const mCAssignFilterData = constructPickupCityFilterIfNotPresent([...state.mmdCities], {...state.tableFilterMetas?.[id]});
        mCAssignUpdated.pickupLocation = updateLocation(mCAssignUpdated.pickupLocation, [...state.location], mCAssignFilterData);
        updatedState = {...updatedState, mmdConsignmentAssignTableHeaderData: mCAssignUpdated};
        break;
      case CONSTANT.TABLE_SESSION_NAME.MMD.VEHICLE_ASSIGN:
        const mVAUpdated = {...state.mmdVehicleAssignTableHeaderData};
        mVAUpdated[key] = {...mVAUpdated[key], isColumnSeen: data};
        updatedState = {...updatedState, mmdVehicleAssignTableHeaderData: mVAUpdated};
        break;
      case CONSTANT.TABLE_SESSION_NAME.LMD.ORDER:
        const lOUpdated = {...state.lmdOrderTableHeaderData};
        lOUpdated[key] = {...lOUpdated[key], isColumnSeen: data};
        lOUpdated.pickupCity = updatePickUp(lOUpdated.pickupCity, [...state.lmdCities]);

        const lOFilterData = constructPickupCityFilterIfNotPresent([...state.lmdCities], {...state.tableFilterMetas?.[id]});
        lOUpdated.pickupLocation = updateLocation(lOUpdated.pickupLocation, [...state.location], lOFilterData);
        updatedState = {...updatedState, lmdOrderTableHeaderData: lOUpdated};
        break;
      case CONSTANT.TABLE_SESSION_NAME.LMD.ORDER_QUEUE:
        const lOQUpdated = {...state.lmdOrderQueueTableHeaderData};
        lOQUpdated[key] = {...lOQUpdated[key], isColumnSeen: data};
        lOQUpdated.pickupCity = updatePickUp(lOQUpdated.pickupCity, [...state.lmdCities]);

        const lOQFilterData = constructPickupCityFilterIfNotPresent([...state.lmdCities], {...state.tableFilterMetas?.[id]});
        lOQUpdated.pickupLocation = updateLocation(lOQUpdated.pickupLocation, [...state.location], lOQFilterData);
        updatedState = {...updatedState, lmdOrderQueueTableHeaderData: lOQUpdated};
        break;
      case CONSTANT.TABLE_SESSION_NAME.LMD.ORDER_ASSIGN:
        const lOAUpdated = {...state.lmdOrderAssignTableHeaderData};
        lOAUpdated[key] = {...lOAUpdated[key], isColumnSeen: data};
        lOAUpdated.pickupCity = updatePickUp(lOAUpdated.pickupCity, [...state.lmdCities]);

        const lOAFilterData = constructPickupCityFilterIfNotPresent([...state.lmdCities], {...state.tableFilterMetas?.[id]});
        lOAUpdated.pickupLocation = updateLocation(lOAUpdated.pickupLocation, [...state.location], lOAFilterData);
        updatedState = {...updatedState, lmdOrderAssignTableHeaderData: lOAUpdated};
        break;
      case CONSTANT.TABLE_SESSION_NAME.LMD.AGENT_ASSIGN:
        const lAAUpdated = {...state.lmdAgentAssignTableHeaderData};
        lAAUpdated[key] = {...lAAUpdated[key], isColumnSeen: data};

        const lAAFilterData = constructPickupCityFilterIfNotPresent([...state.lmdCities], {...state.tableFilterMetas?.[id]});
        lAAUpdated.agentLocation = updateLocation(lAAUpdated.agentLocation, [...state.location], lAAFilterData);
        updatedState = {...updatedState, lmdAgentAssignTableHeaderData: lAAUpdated};
        break;
      case CONSTANT.TABLE_SESSION_NAME.KIOSK.ORDER:
        const kOUpdated = {...state.kioskOrderTableHeaderData};
        kOUpdated[key] = {...kOUpdated[key], isColumnSeen: data};
        updatedState = {...updatedState, kioskOrderTableHeaderData: kOUpdated};
        break;
      case CONSTANT.TABLE_SESSION_NAME.COMMUNICATION.TABLE:
        const commTabUpdate = {...state.communicationTableHeader};
        commTabUpdate[key] = {...commTabUpdate[key], isColumnSeen: data};
        updatedState = {...updatedState, communicationTableHeader: commTabUpdate};
        break;
      case CONSTANT.TABLE_SESSION_NAME.WAREHOUSE.REQUISITION:
        const wRequisitionTableUpdate = {...state.warehouseRequisitionTableHeader};
        wRequisitionTableUpdate[key] = {...wRequisitionTableUpdate[key], isColumnSeen: data};
        updatedState = {...updatedState, warehouseRequisitionTableHeader: wRequisitionTableUpdate};
        break;
      case CONSTANT.TABLE_SESSION_NAME.WAREHOUSE.SUPPLY:
        const wSupplyTabUpdate = {...state.warehouseSupplyTableHeader};
        wSupplyTabUpdate[key] = {...wSupplyTabUpdate[key], isColumnSeen: data};
        updatedState = {...updatedState, warehouseSupplyTableHeader: wSupplyTabUpdate};
        break;
      case CONSTANT.TABLE_SESSION_NAME.WAREHOUSE.FULFILLMENT:
         const wFulfillmentTableUpdate = {...state.warehouseFulfillmentTableHeader};
         wFulfillmentTableUpdate[key] = {...wFulfillmentTableUpdate[key], isColumnSeen: data};
         updatedState = {...updatedState, warehouseFulfillmentTableHeader: wFulfillmentTableUpdate};
         break;
      case CONSTANT.TABLE_SESSION_NAME.WAREHOUSE.PROGRESS_TAB:
          const wProgressTableUpdate = {...state.warehouseProgressTableHeader};
          wProgressTableUpdate[key] = {...wProgressTableUpdate[key], isColumnSeen: data};
          updatedState = {...updatedState, warehouseProgressTableHeader: wProgressTableUpdate};
          break; 
      case CONSTANT.TABLE_SESSION_NAME.WAREHOUSE.OUTBOUND_TAB:
            const wOutboundTableUpdate = {...state.warehouseOutboundTableHeader};
            wOutboundTableUpdate[key] = {...wOutboundTableUpdate[key], isColumnSeen: data};
            updatedState = {...updatedState, warehouseOutboundTableHeader: wOutboundTableUpdate};
            break;
      default:
        break;
    }

    return updatedState;
  }),
  on(AssetsActions.loadOrderTimeSlotsSuccess, (state, {data}) => {
    const orderTableHeaderData = {...state.tableHeaderData};
    const cTableHeaderData = {...state.consignmentTableHeaderData};
    const oTableHeaderData = {...state.orderTableHeaderData};
    if (data && data['response'] !== 401) {
      orderTableHeaderData.deliverySlot = updateDeliverySlots(orderTableHeaderData.deliverySlot, [...data]);
      cTableHeaderData.deliverySlot = updateDeliverySlots(cTableHeaderData.deliverySlot, [...data]);
      oTableHeaderData.deliverySlot = updateDeliverySlots(oTableHeaderData.deliverySlot, [...data]);
    }

    return {
      ...state,
      timeSlots: data,
      tableHeaderData: orderTableHeaderData,
      consignmentTableHeaderData: cTableHeaderData,
      orderTableHeaderData: oTableHeaderData
    };
  }),
  on(AssetsActions.updateOrderTableHeaderDataPickUpLocation, (state, {id, data}) => {
    let updatedState = {...state};
    switch (id) {
      case CONSTANT.TABLE_SESSION_NAME.O2D_ORDER_TAB:
        const oOUpdated = {...state.tableHeaderO2DOrders};
        oOUpdated.pickupLocation = updateLocation(oOUpdated.pickupLocation, [...state.location], data);
        updatedState = {...updatedState, tableHeaderO2DOrders: oOUpdated};
        break;
      case CONSTANT.TABLE_SESSION_NAME.O2D_PENDING_TAB:
        const oPendUpdated = {...state.tableHeaderO2DPending};
        oPendUpdated.pickupLocation = updateLocation(oPendUpdated.pickupLocation, [...state.location], data);
        updatedState = {...updatedState, tableHeaderO2DPending: oPendUpdated};
        break;
      case CONSTANT.TABLE_SESSION_NAME.O2D_PROCESSING_TAB:
        const oProcUpdated = {...state.tableHeaderO2DProcessing};
        oProcUpdated.pickupLocation = updateLocation(oProcUpdated.pickupLocation, [...state.location], data);
        updatedState = {...updatedState, tableHeaderO2DProcessing: oProcUpdated};
        break;
      case CONSTANT.TABLE_SESSION_NAME.O2D_DISPATCH_TAB:
        const oDisUpdated = {...state.tableHeaderO2DDispatch};
        oDisUpdated.pickupLocation = updateLocation(oDisUpdated.pickupLocation, [...state.location], data);
        updatedState = {...updatedState, tableHeaderO2DDispatch: oDisUpdated};
        break;
      case CONSTANT.TABLE_SESSION_NAME.O2D_PROGRESS_TAB:
        const oProgUpdated = {...state.tableHeaderO2DProgress};
        oProgUpdated.pickupLocation = updateLocation(oProgUpdated.pickupLocation, [...state.location], data);
        updatedState = {...updatedState, tableHeaderO2DProgress: oProgUpdated};
        break;
      case CONSTANT.TABLE_SESSION_NAME.MMD.ORDER:
        const mCUpdated = {...state.mmdConsignmentTableHeaderData};

        const mCFilterData = constructPickupCityFilterIfNotPresent([...state.mmdCities], {...data});
        mCUpdated.pickupLocation = updateLocation(mCUpdated.pickupLocation, [...state.location], mCFilterData);
        updatedState = {...updatedState, mmdConsignmentTableHeaderData: mCUpdated};
        break;
      case CONSTANT.TABLE_SESSION_NAME.MMD.CONSIGNMENT_ASSIGN:
        const mCAssignUpdated = {...state.mmdConsignmentAssignTableHeaderData};

        const mCAssignFilterData = constructPickupCityFilterIfNotPresent([...state.mmdCities], {...data});
        mCAssignUpdated.pickupLocation = updateLocation(mCAssignUpdated.pickupLocation, [...state.location], mCAssignFilterData);
        updatedState = {...updatedState, mmdConsignmentAssignTableHeaderData: mCAssignUpdated};
        break;
      case CONSTANT.TABLE_SESSION_NAME.MMD.CONSIGNMENT_QUEUE:

        const mCQUpdated = {...state.mmdConsignmentQueueTableHeaderData};

        const mCQFilterData = constructPickupCityFilterIfNotPresent([...state.mmdCities], {...data});
        mCQUpdated.pickupLocation = updateLocation(mCQUpdated.pickupLocation, [...state.location], mCQFilterData);
        updatedState = {...updatedState, mmdConsignmentQueueTableHeaderData: mCQUpdated};
        break;
      case CONSTANT.TABLE_SESSION_NAME.MMD.PROGRESS || CONSTANT.TABLE_SESSION_NAME.MMD.DELIVERED:

        const mMPUpdated = {...state.mmdManagementTableHeaderData};

        const mMPFilterData = constructPickupCityFilterIfNotPresent([...state.mmdCities], {...data});
        mMPUpdated.pickupLocation = updateLocation(mMPUpdated.pickupLocation, [...state.location], mMPFilterData);
        updatedState = {...updatedState, mmdManagementTableHeaderData: mMPUpdated};
        break;
      case CONSTANT.TABLE_SESSION_NAME.MMD.ROUTE_PLAN:
        const mmdRouteCityUpdated = {...state.routePlanTableHeader};
        mmdRouteCityUpdated.pickupLocation = updateLocation(mmdRouteCityUpdated.pickupLocation, [...state.location], data);
        updatedState = {...updatedState, routePlanTableHeader: mmdRouteCityUpdated};
        break;
      case CONSTANT.TABLE_SESSION_NAME.MMD.ALL_SCHEDULE:
        const mmdAllScheduleCityUpdated = { ...state.allScheduleTableHeader };
        mmdAllScheduleCityUpdated.pickupLocation = updateLocation(mmdAllScheduleCityUpdated.pickupLocation, [...state.location], data);
        updatedState = { ...updatedState, allScheduleTableHeader: mmdAllScheduleCityUpdated };
        break;
      case CONSTANT.TABLE_SESSION_NAME.MMD.TODAY_SCHEDULE:
        const mmdTodayScheduleCityUpdated = { ...state.todayScheduleTableHeader };
        mmdTodayScheduleCityUpdated.pickupLocation = updateLocation(mmdTodayScheduleCityUpdated.pickupLocation, [...state.location], data);
        updatedState = { ...updatedState, todayScheduleTableHeader: mmdTodayScheduleCityUpdated };
        break;
      case CONSTANT.TABLE_SESSION_NAME.LMD.ORDER:
        const lOUpdated = {...state.lmdOrderTableHeaderData};

        const lOFilterData = constructPickupCityFilterIfNotPresent([...state.lmdCities], {...data});
        lOUpdated.pickupLocation = updateLocation(lOUpdated.pickupLocation, [...state.location], lOFilterData);
        updatedState = {...updatedState, lmdOrderTableHeaderData: lOUpdated};
        break;
      case CONSTANT.TABLE_SESSION_NAME.LMD.ORDER_QUEUE:
        const lOQUpdated = {...state.lmdOrderQueueTableHeaderData};

        const lOQFilterData = constructPickupCityFilterIfNotPresent([...state.lmdCities], {...data});
        lOQUpdated.pickupLocation = updateLocation(lOQUpdated.pickupLocation, [...state.location], lOQFilterData);
        updatedState = {...updatedState, lmdOrderQueueTableHeaderData: lOQUpdated};
        break;
      case CONSTANT.TABLE_SESSION_NAME.LMD.ORDER_ASSIGN:
        const lOAUpdated = {...state.lmdOrderAssignTableHeaderData};
        const agentupdated = {...state.lmdAgentAssignTableHeaderData};

        const lOAFilterData = constructPickupCityFilterIfNotPresent([...state.lmdCities], {...data});
        lOAUpdated.pickupLocation = updateLocation(lOAUpdated.pickupLocation, [...state.location], lOAFilterData);
        agentupdated.agentLocation = updateLocation(agentupdated.agentLocation, [...state.location], lOAFilterData);
        updatedState = {
          ...updatedState,
          lmdOrderAssignTableHeaderData: lOAUpdated,
          lmdAgentAssignTableHeaderData: agentupdated
        };
        break;
      case CONSTANT.TABLE_SESSION_NAME.LMD.AGENT_ASSIGN:
        const lAAUpdated = {...state.lmdAgentAssignTableHeaderData};
        updatedState = {...updatedState, lmdAgentAssignTableHeaderData: lAAUpdated};
        break;
      case CONSTANT.TABLE_SESSION_NAME.LMD.PROGRESS || CONSTANT.TABLE_SESSION_NAME.LMD.DELIVERED:

        const mLPUpdated = {...state.lmdManagementTableHeaderData};

        const mLPFilterData = constructPickupCityFilterIfNotPresent([...state.lmdCities], {...data});
        mLPUpdated.pickupLocation = updateLocation(mLPUpdated.pickupLocation, [...state.location], mLPFilterData);
        updatedState = {...updatedState, lmdManagementTableHeaderData: mLPUpdated};
        break;
      case CONSTANT.TABLE_SESSION_NAME.KIOSK.ORDER:
        const kOUpdated = {...state.kioskOrderTableHeaderData};
        kOUpdated.pickupLocation = updateLocation(kOUpdated.pickupLocation, [...state.location], data);
        updatedState = {...updatedState, kioskOrderTableHeaderData: kOUpdated};
        break;
      case CONSTANT.TABLE_SESSION_NAME.WAREHOUSE.PROGRESS_TAB:
          const wProgUpdated = {...state.warehouseProgressTableHeader};
          wProgUpdated.pickupLocation = updateLocation(wProgUpdated.pickupLocation, [...state.location], data);
          updatedState = {...updatedState, warehouseProgressTableHeader: wProgUpdated};
          break;
      case CONSTANT.TABLE_SESSION_NAME.WAREHOUSE.OUTBOUND_TAB:
              const wOutUpdated = {...state.warehouseOutboundTableHeader};
              wOutUpdated.pickupLocation = updateLocation(wOutUpdated.pickupLocation, [...state.location], data);
              updatedState = {...updatedState, warehouseOutboundTableHeader: wOutUpdated};
              break;
      default:
        break;
    }

    return updatedState;
  }),
  on(AssetsActions.setOrderTableFilterMeta, (state, {id, data}) => {
    const update = {...state.tableFilterMetas};
    update[id] = data;
    return {
      ...state,
      tableFilterMetas: update
    };
  }),
  on(AssetsActions.loadLogisticProviderLinkedCities, (state, {data, moduleUniqueId, id}) => {
    let updatedState = {...state};
    const isMobileView = structuredClone(state.isMobileView);
    if (moduleUniqueId === CONSTANT.DMS_MODULES.MMD.UNIQUE_ID) {
      let mmdCities;
      if (data?.length > 0) {
        mmdCities = updateLinkedAssets([...data])
      } else {
        mmdCities = [...state.mmdCities]
      }
      let copy: IConsignmentTableHeaderData = {...state.consignmentTableHeaderData};
      const expCopy = {...state.expansionRowTableHeadings};
      const vehicleCopy: IVehicleTableHeaderData = {...state.vehicleTableHeaderData};
      const filter = constructPickupCityFilterIfNotPresent([...mmdCities], {...state.tableFilterMetas?.[id]});

      copy.pickupCity = updatePickUp(copy?.pickupCity, [...mmdCities]);
      expCopy.pickupCity = updatePickUp(expCopy?.pickupCity, [...mmdCities]);

      copy.pickupLocation = updateLocation(copy?.pickupLocation, [...state.location], filter);
      updatedState = {...updatedState, mmdCities: mmdCities};

      switch (id) {
        case CONSTANT.TABLE_SESSION_NAME.MMD.ORDER:
          delete copy.custDetails;
          delete copy.items;
          delete copy.removeAction
          if (isMobileView) {
            copy = handleTableColumnsToDisplayInMobilewithoutSetting({...copy}, id);
          }
          updatedState = {...updatedState, mmdConsignmentTableHeaderData: copy, expansionRowTableHeadings: expCopy};
          break;
        case CONSTANT.TABLE_SESSION_NAME.MMD.CONSIGNMENT_QUEUE:
          delete copy.vehicleAssignedStatus;
          delete copy.deliverySlot;
          delete copy.orderStatus;
          delete copy.created;
          copy.orderId = {...copy.orderId, isSortable: false}
          copy.pickupCity = {...copy.pickupCity, isSortable: false}
          copy.pickupLocation = {...copy.pickupLocation, isSortable: false}
          copy.expectedDeliveryDateTimeDetails = {...copy.expectedDeliveryDateTimeDetails, isSortable: false}
          updatedState = {...updatedState, mmdConsignmentQueueTableHeaderData: copy};
          break;
        case CONSTANT.TABLE_SESSION_NAME.MMD.CONSIGNMENT_ASSIGN:
          delete copy.custDetails;
          delete copy.items;
          delete copy.vehicleAssignedStatus;
          delete copy.orderStatus;
          delete copy.orderSource;
          delete copy.deliverySlot;
          delete copy.removeAction;
          delete copy.created;
          delete vehicleCopy.removeAction;

          copy.orderId = {...copy.orderId, isClickable: false, isSortable: false}
          copy.pickupCity = {...copy.pickupCity, isSortable: false}
          copy.pickupLocation = {...copy.pickupLocation, isSortable: false}
          copy.expectedDeliveryDateTimeDetails = {...copy.expectedDeliveryDateTimeDetails, isSortable: false}

          vehicleCopy.name = {...vehicleCopy.name, isClickable: false, isSortable: false}
          if (isMobileView) {
            copy = handleTableColumnsToDisplayInMobilewithoutSetting({...copy}, id);
          }
          updatedState = {
            ...updatedState,
            mmdConsignmentAssignTableHeaderData: copy,
            mmdVehicleAssignTableHeaderData: vehicleCopy
          };
          break;
        default:
          break;
      }

    } else if (moduleUniqueId === CONSTANT.DMS_MODULES.LMD.UNIQUE_ID) {
      let lmdCities;
      if (data?.length > 0) {
        lmdCities = updateLinkedAssets([...data])
      } else {
        lmdCities = [...state.lmdCities]
      }
      let copy: ILmdOderTableHeaderData = {...state.orderTableHeaderData};
      const agentCopy: IAgentTableHeaderData = {...state.agentTableHeaderData};
      const filter = constructPickupCityFilterIfNotPresent([...lmdCities], {...state.tableFilterMetas?.[id]});
      copy.pickupCity = updatePickUp(copy.pickupCity, [...lmdCities]);
      copy.pickupLocation = updateLocation(copy.pickupLocation, [...state.location], filter);
      updatedState = {...updatedState, lmdCities: lmdCities};

      switch (id) {
        case CONSTANT.TABLE_SESSION_NAME.LMD.ORDER:
          if (isMobileView) {
            copy = handleTableColumnsToDisplayInMobilewithoutSetting({...copy}, id);
          }
          updatedState = {...updatedState, lmdOrderTableHeaderData: copy};
          break;
        case CONSTANT.TABLE_SESSION_NAME.LMD.ORDER_QUEUE:
          if (isMobileView) {
            copy = handleTableColumnsToDisplayInMobilewithoutSetting({...copy}, id);
          }
          delete copy.agentDetails;
          delete copy.deliverySlot;
          delete copy.orderStatus;
          delete copy.created;
          copy.orderId = {...copy.orderId, isSortable: false}
          copy.pickupCity = {...copy.pickupCity, isSortable: false}
          copy.pickupLocation = {...copy.pickupLocation, isSortable: false}
          copy.custDetails = {...copy.custDetails, isSortable: false}
          copy.expectedDeliveryDateTimeDetails = {...copy.expectedDeliveryDateTimeDetails, isSortable: false}
          updatedState = {...updatedState, lmdOrderQueueTableHeaderData: copy};
          break;
        case CONSTANT.TABLE_SESSION_NAME.LMD.ORDER_ASSIGN:
          delete copy.agentDetails;
          delete copy.items;
          delete copy.orderStatus;
          delete copy.orderSource;
          delete copy.deliverySlot;
          delete copy.removeAction;
          delete copy.created;
          copy.orderId = {...copy.orderId, isClickable: false, isSortable: false};
          copy.pickupCity = {...copy.pickupCity, isSortable: false};
          copy.pickupLocation = {...copy.pickupLocation, isSortable: false};
          copy.custDetails = {...copy.custDetails, isSortable: false};
          copy.expectedDeliveryDateTimeDetails = {...copy.expectedDeliveryDateTimeDetails, isSortable: false};

          const lOAFilter = constructPickupCityFilterIfNotPresent([...lmdCities], {...state.tableFilterMetas?.[id]});
          agentCopy.agentLocation = updateLocation(agentCopy.agentLocation, [...state.location], lOAFilter);
          if (isMobileView) {
            copy = handleTableColumnsToDisplayInMobilewithoutSetting({...copy}, id);
          }
          updatedState = {
            ...updatedState,
            lmdOrderAssignTableHeaderData: copy,
            lmdAgentAssignTableHeaderData: agentCopy
          };
          break;
        default:
          break;
      }

    }

    return updatedState;

  }),
  on(AssetsActions.loadAllAssignedCarriersDataSuccess, (state, {data}) => {
    const updatedCarrierNameHeaderObj = getHydratedCarrierTableFilter(state.expansionRowTableHeadings, '_id', '_id', data)
    return {
      ...state,
      carrierData: data,
      expansionRowTableHeadings: {
        ...state.expansionRowTableHeadings,
        carrier: updatedCarrierNameHeaderObj
      }
    }
  }),
  on(AssetsActions.getAllDeliveriesDataSuccess, (state, {data}) => {
    return {
      ...state,
      deliveriesData: data
    }
  }),
  on(AssetsActions.getAllDeliveriesCountSuccess, (state, {data}) => {
    return {
      ...state,
      counts: {...state.counts, ...data,}
    }
  }),

  on(AssetsActions.updateDeliveryData, (state, {data}) => {
    const deliveriesDataCopy = [...state.deliveriesData]
    const index = deliveriesDataCopy.findIndex((r) =>r?._id === data?._id)
    if(index > -1)
    deliveriesDataCopy[index] = data;
    return {
      ...state,
      deliveriesData: deliveriesDataCopy
    }
  }),
  on(AssetsActions.resetAllDeliveriesCount, (state) => {
    return {
      ...state,
      counts: {...state.counts,deliveriesCount : {count: null, currentLimit: null}}
    }
  }),
  on(AssetsActions.loadAllAssignedCarriersCountSuccess, (state, {data}) => {
    return {
      ...state,
      counts: {...state.counts, ...data,},
    }
  }),
  on(AssetsActions.reorderCatagery, (state, {to, from, msg}) => {
    const copy: ICategory[] = moveElement([...state.categories], from, to);
    if (Object.keys(state.categoryReordered).length > 0) {
      return {
        ...state, categories: copy,
      }
    } else {
      return {...state, categories: copy, categoryReordered: msg}
    }
  }),
  on(AssetsActions.loadState, (state) => {
    return {
      ...state
    }
  }),
  on(AssetsActions.getKioskTableHeaderDataSuccess, (state, {id , fields}) => {
    let updatedState = {...state};  
    const isMobileView = structuredClone(state.isMobileView);
    let copy: IKioskTableHeaderData;
    if (!isMobileView) {
      copy =handleTableColumnsToDisplay({...state.kioskTableHeaderData},fields);
    } else {
      copy = handleTableColumnsToDisplayInMobileView({...state.kioskTableHeaderData}, fields, id);
    }
    switch (id) {
      case CONSTANT.TABLE_SESSION_NAME.KIOSK.ORDER:
        delete copy.orderTime;
        delete copy.pickupLocation;
        updatedState = {...updatedState, kioskOrderTableHeaderData: {...copy}};
        break;
      case CONSTANT.TABLE_SESSION_NAME.KIOSK.TODAY:
        delete copy.expectedDeliveryDateTimeDetails;
        delete copy.pickupLocation;
        updatedState = {...updatedState, kioskTodayOrderTableHeaderData: {...copy}};
        break;
      case CONSTANT.TABLE_SESSION_NAME.KIOSK.CDS:
        delete copy.expectedDeliveryDateTimeDetails;
        delete copy.pickupLocation;
        copy = {
          ...copy,
          paymentStatus: {...copy.paymentStatus, isFilterable: false},
          orderStatusDetails: {...copy.orderStatusDetails, isFilterable: false}
        }
        updatedState = {...updatedState, kioskCDSTableHeaderData: {...copy}};
        break;
      case CONSTANT.TABLE_SESSION_NAME.KIOSK.KDS:
        delete copy.expectedDeliveryDateTimeDetails;
        delete copy.pickupLocation;
        delete copy.orderSource;
        delete copy.action;
        copy = {
          ...copy,
          paymentStatus: {...copy.paymentStatus, isFilterable: false},
          orderStatusDetails: {...copy.orderStatusDetails, isFilterable: false}
        }
        updatedState = {...updatedState, kioskKDSTableHeaderData: {...copy}};
        break;
      default:
        break;
    }
    return updatedState
  }),
  on(AssetsActions.checkMobileView, (state) => {
    return {
      ...state,
      isMobileView: isMobileView()
    }
  }),
  on(AssetsActions.getAssetsLinkedByIdSuccess, (state, {id, assetsList}) => {
    return {
      ...state,
      linkedAssets: {...state.linkedAssets, [id]: assetsList}
    }
  }),
  on(AssetsActions.removeCachedAssetLinkData, (state, {ids}) => {
    const linkedAssets = {...state.linkedAssets};
    const idToDelete = ids.filter((id) => {
      return (linkedAssets.hasOwnProperty(id))
    })
    if (idToDelete.length > 0) {
      delete linkedAssets?.[idToDelete[0]]
    }
    return {
      ...state,
      linkedAssets: linkedAssets
    }
  }),
  on(AssetsActions.loadRoutePlansCountSuccess, (state, {count}) => {
    return {
      ...state,
      routePlanCount: count
    }
  }),
  on(AssetsActions.loadRoutePlansSuccess, (state, {data}) => {
    let updatedState = {...state};
    const expansionHeaderCopy: IExpansionConsignmentHeader = {...state.expansionRowTableHeadings};
    expansionHeaderCopy.routePlans = updateRoutePlans(expansionHeaderCopy.routePlans, [...data]);
    expansionHeaderCopy.routePlans.groupRoutePlanArray =  groupByCategory(data) ;
    updatedState = {...updatedState, expansionRowTableHeadings: expansionHeaderCopy, routePlans: data, groupedRoutePlans:  expansionHeaderCopy.routePlans.groupRoutePlanArray};
    return updatedState;
  }),
  on(AssetsActions.resetParticularAssetCount, (state) => {
    const updateCount = {...state.counts}
    return {
      ...state,
      counts: updateCount
    }
  }),

  on(AssetsActions.geoFenceByAssetIdSuccess, (state, {geofences}) => {
    const geoFenceCoordinates = geofences?.location?.coordinates[0] || []
    const geoFenceRadius = geofences?.radius || 0
    const [lng, lat] = geofences?.geoLocation?.coordinates || []
    return {
      ...state,
      geoFenceForAssetData: {
        geoFenceCoordinates: [...geoFenceCoordinates], geoFenceRadius, geoLocation: {lng, lat}
      }
    }
  }),
  on(AssetsActions.geoFenceRaduisSuccess, (state) => {
    return {
      ...state
    };
  }),
  on(AssetsActions.loadO2DOrdersSuccess, (state, {data, key}) => {
    return {
      ...state,
      tableBody: {...state.tableBody, [key]: data}
    }
  }),
  on(AssetsActions.updateO2DOrders, (state, {order, key}) => {
    const orders = state.tableBody[key];
    if (Array.isArray(orders?.data) && order) {
     const updatedOrders = {...orders, data: [...orders.data] , pagination : {...orders.pagination}};
      for (let i = 0; i < updatedOrders.data.length; i++) {
        if (updatedOrders?.data?.[i]._id === order._id) {
          updatedOrders.data[i] = order
        }
      }

      return {
        ...state,
        tableBody: {...state.tableBody, [key]: updatedOrders}
      }
    }else{
      return {
        ...state,
        tableBody: {...state.tableBody, [key]: orders}
      }
    }

   
  }),

  on(AssetsActions.getAssetCardsImageDisplayStatusSuccess, (state, {data}) => {

    const modifiedData = {}

    data?.forEach(status=>{
      modifiedData[status?.assetType] = status
    })

    return {
      ...state,
      assetCardsImageDisplayStatus: modifiedData
    }
  }),
  on(AssetsActions.setCurrentPageSkipLimit , (state ,{assetType,skip,limit})=>{
    return {
      ...state,
      currentPageNumber:{assetType:assetType,skip:skip,limit:limit}
    }
  }),
  on(AssetsActions.createMultipleAssetsActionStatus, (state, { status }) => {
    return {
      ...state, multipleAssetCreationStatus: status
    }
  }),
  on(AssetsActions.getSchedulesDataSuccess, (state, { schedules, key,conflictKey,conflictDurationData }) => {
    const stateData = { ...state }
    if (key === 'all') {
      stateData.allSchedules = schedules
    } else if (key === 'today' && conflictKey) {
      stateData['conflictingSchedules'] = 
      {
        ...(stateData['conflictingSchedules'] || {}),
        [conflictKey] : schedules,
        [conflictKey+'_duration'] : conflictDurationData,
      }
    }
    else if (key === 'today') {
      stateData.todaySchedules = schedules
    }
    return stateData
  }),
);