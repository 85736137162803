<div>
  <div class="prime-banner-container" [ngClass]="{'prime-banner-positioned-top' : showOnPeak}">
    @for (messageData of messages; track messageData; let idx = $index) {
      <div>
        <p-messages [severity]="messageData.severity"  [hideTransitionOptions]="'500ms'" [enableService]="false" [closable]="messageData.closable" styleClass="p-banner-msg">
          <ng-template pTemplate>
            <div class="prime-msg-container">
              <div>
                <div class="summary-detail-container">
                  <div class="summary-container">
                    
                    @if(messageData.severity==='info'){
                      <img src="assets/zen-ai.png" alt="" style="width: 2rem;height:2rem;border-radius: 0.5rem;">
                    }@else{
                      <i 
                    [ngClass]="'pi-times-circle'"
                    class="pi p-severity-icon"
                    ></i>
                    }
                    <!-- <img src="assets/ai.png" alt="" style="width: 2rem;height:2rem;"> -->
                    <div class="ml-2 summary">{{ messageData.summary }}</div>
                  </div>
                  <div class="ml-2 mr-2 msg">{{ messageData.detail }}</div>
                </div>
                <div class="action-icons">
                  @for (actionIcons of messageData.additionalActionIcons; track actionIcons) {
                    <div>
                      <i [class]="actionIcons.icon"
                         ngClass="p-msg-icon"
                         (click)="performAction(actionIcons.key)"
                         [pTooltip]="actionIcons['tooltip']"
                         tooltipPosition="right"
                      ></i>
                    </div>
                  }
                </div>
              </div>
              @if (messageData.closable) {
                <i class="pi pi-times p-msg-icon p-close-icon" (click)="clearMessage(idx)"></i>
              }
            </div>
          </ng-template>
        </p-messages>
      </div>
    }
  </div>

</div>
