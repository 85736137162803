<div class="table-container">
    <p-table id="excel-table" [value]="currentPageComplianceEvents()">
        <ng-template pTemplate="header">

            <tr>
                <th>
                    <div class="filter-container">
                        {{ 'sys.event' | translate }}
                        <div class="action-icons">

                            <i class="pi cursorPointer"
                            [ngClass]="(selectedSubType()?.length || selectedOnRoadLogistic!==onRoadLogisticsOptions[0].value) ? 'pi-filter-fill' : 'pi-filter'" (click)="eventTableFilterPanel.toggle($event)"></i>
                            <i class="pi pi-info-circle cursorPointer" (click)="eventTabInfoPanel.toggle($event)"></i>
                        </div>

                    </div>

                </th>
                <th>{{ 'Temperature' }}</th>
                <th>{{ 'sys.time' | translate }}</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-element>
            <tr class="cursorPointer" [ngClass]="{'row-highlight': currentNonComplianceEvent()?._id === element?._id}"
                (click)="triggerRowClick(element)">
                <td>{{ element.subType }}</td>
                <td>{{ element.sensorData.temperature.toFixed(2)}}</td>
                <td>{{ dateFormatter.convertDateToSelectedTimezone(element.generatedTimeStamp, 'DD/MM/YY, h:mm a') }}
                </td>
            </tr>
        </ng-template>
    </p-table>
    @if (currentPageComplianceEvents()?.length === 0 && !showLoader) {<div class="W100FL TAC asset-not-found-container">
        <h3 class="asset-not-found"><span>&ldquo;</span>{{ 'sys.no-data' | translate }}<span>&rdquo;</span></h3>
    </div>
    }
    @if (pagination().totalNoOfRecords > pagination().recordsPerPage) {
    <p-paginator [rows]="pagination().recordsPerPage" [totalRecords]="pagination().totalNoOfRecords"
        [pageLinkSize]="(isMobileView$ | async) ? 0 : 3" (onPageChange)="getDataByPageNo($event)"
        [showJumpToPageDropdown]="true" [showPageLinks]="true" class="row-exp-paginator"
        currentPageReportTemplate="{first} to {last} of {totalRecords}">
    </p-paginator>
    }
    @else{
    <div class="paginator-empty-space"> </div>
    }
</div>


@if (showLoader) {
<div>
    <app-loader></app-loader>
</div>
}


<p-overlayPanel #eventTabInfoPanel styleClass="event-table-info-panel">

    <div class="events-info-container">
        <div class="info-container-header">
            <span>Event Details</span>

        </div>
        <div class="body">
            @for (event of selectedOrderDeliveryGroup?.eventsMetaData; track event.id) {
            <div class="event-metadata-label" tooltipPosition="top">
                <div class="events-types-title">
                    {{event.label}}

                </div>
                <div class="events-types-subheader">
                    <p>Condition: {{event.condition}}</p>
                    <p>Duration: {{event.interval+ ' minutes'}}</p>
                </div>
            </div>
            }
        </div>


    </div>
</p-overlayPanel>


<p-overlayPanel #eventTableFilterPanel styleClass="event-table-filter-panel">
    <div class="dialog-filters">
        <span class="p-float-label">
            <p-multiSelect [showClear]="true" id="float-label-events-subtypes-filter" [options]="subTypes()"
                (ngModelChange)="applyEventSubTypesFilter($event)" [(ngModel)]="selectedSubType"
                placeholder="Select Event" />
            <label for="float-label-events-subtypes-filter">Select Type</label>
        </span>
        <span class="p-float-label">
            <p-dropdown [options]="onRoadLogisticsOptions" id="float-label-events-filter"
                [(ngModel)]="selectedOnRoadLogistic" (ngModelChange)="filterByOnRoadLogistics()" optionLabel="name"
                optionValue="value" placeholder="Select Logistic">
                <ng-template let-logistic pTemplate="item">
                    <div class="drop-down-item-custom">
                        <div class="drop-down-item-custom-header">{{ logistic.name }}</div>
                        @if(logistic.value!==onRoadLogisticsOptions[0].value){
                        <div class="duration">
                            <span>Start: {{onRoadLogisticsData()?.[logistic.value]?.startDateLabel || ''}}</span>
                            <span>End: {{onRoadLogisticsData()?.[logistic.value]?.endDateLabel || ''}}</span>
                        </div>
                        }@else{
                        <div class="duration">
                            <span>Start: {{onRoadLogisticsData()?.startDateLabel || ''}}</span>
                            <span>End: {{onRoadLogisticsData()?.endDateLabel || ''}}</span>
                        </div>
                        }

                    </div>
                </ng-template>
            </p-dropdown>
            <label for="float-label-events-filter">Select Logistic</label>
        </span>
    </div>

</p-overlayPanel>