import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, filter, map, mergeMap, switchMap, take, tap, withLatestFrom} from 'rxjs/operators';
import {combineLatest, concat, from, of} from 'rxjs';
import {AssetsActions} from './index';
import {Store} from '@ngrx/store';
import {AssetsService} from './assets.service';
import {CONSTANT} from '../../config/constant';
import {IAllAssignedCarriersData} from './interfaces/IAllAssignedCarriers.model';
import {IAllDeliveriesCount, IAllDeliveriesData, IAllDeliveryData} from './interfaces/IAllDeliveries.model';
import {getAllSettingsForSelectedModuleFromSelector} from '../Settings/settings.selector';
import {IRootAssetCountsByTypes, IRootAssetTypes} from './interfaces/IAsset.types.modal';
import {Group} from './interfaces/Assets.modal';
import {
  assetConfigSelector,
  getCurrentPageData,
  individualLinkedAssetsSelector,
  locationsSelector,
  mmdCitiesSelector,
  routePlanCountSelector,
  routePlansSelector,
  selectConflictingSchedules,
  selectedAssetLinkedGroupsSelector,
  selectedAssetsTypeSelector,
  vehiclesSelector,
} from './assets.selector';
import {MessageService} from 'primeng/api';
import {checkIfFIncludeDoNotHaveShowInCardField, convertToConstantCase} from "../Assets/reducer.helper.service";
import {IRoutePlan} from './interfaces/I-RoutePlan.table.header';
import {AssetService as MainAssetsService} from '../../services/asset.service';
// import { getKioskSettings } from '../Kiosk/kiosk.selector';
import { Ability } from '@casl/ability';
import { Actions as ruleActions,Subject } from '../../auth/rules';
import { IOrderAssets } from './interfaces/IAsset.order.modal';
import { ScheduleService } from '../../app/delivery-managenment-system/services/schedule.service';

const INITIAL_ASSET_LOAD_COUNT = CONSTANT.ASSET.INITIAL_ASSET_LOAD_COUNT;
const CURRENT_LIMIT_PER_PAGE = CONSTANT.ASSET.CURRENT_LIMIT_PER_PAGE;
const GET_ALL_DATA_FOR_THESE_ASSETS = CONSTANT.ASSET.INCLUDED_TO_GET_ALL_DATA

@Injectable()
export class AssetsEffects {
  loadAssetCountByType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetsActions.loadAssetCountByType),
      mergeMap((action) => {
        return from(
          this.service.effectAssetCountByType(action.assetType)
        ).pipe(
          switchMap((res) => {
            return [AssetsActions.setAssetCountInConfig({assetType: action.assetType, count: res.count}),
              AssetsActions.loadEachAssetByType({ assetType: action.assetType, limit: res.count, archived: false })]
          })
        )
      }), catchError((error) => of(AssetsActions.failure({error: error})))
    )
  )
  loadEachAssetByType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetsActions.loadEachAssetByType),
      withLatestFrom(this.store.select(getCurrentPageData), this.store.select(assetConfigSelector)),
      mergeMap((observablesMainData) => {
        const assetsToGetAllData = GET_ALL_DATA_FOR_THESE_ASSETS;
        const isIncluded = assetsToGetAllData.includes(observablesMainData?.[0]?.assetType);

        let limit = observablesMainData?.[0]?.limit;

        if (!isIncluded && limit && limit > INITIAL_ASSET_LOAD_COUNT) {
          limit = INITIAL_ASSET_LOAD_COUNT;
        }

        const constantAssetType = convertToConstantCase(observablesMainData?.[0]?.assetType)
        const fI = CONSTANT.ASSET_PRIMARY_INCLUDED_FIELDS?.[constantAssetType]
        

        let additionalFInc;
        if(fI){
          if(observablesMainData?.[0]?.configuration){
            additionalFInc = checkIfFIncludeDoNotHaveShowInCardField(observablesMainData?.[0], fI)
          }else{
            const assetConfigArr = observablesMainData?.[2] || [];
            const selectedConfig = assetConfigArr.find(config => config?.type === observablesMainData?.[0]?.assetType)
            observablesMainData[0] = {...observablesMainData?.[0],configuration:selectedConfig?.configuration}
            additionalFInc = checkIfFIncludeDoNotHaveShowInCardField(observablesMainData?.[0], fI)
          }
        }

        if (additionalFInc)
        additionalFInc = additionalFInc + ',updated,assetsLinked'
        const pageData = {
          skip : observablesMainData?.[1]?.skip,
          limit : observablesMainData?.[1]?.limit
        }
        if(observablesMainData[1]?.assetType != observablesMainData[0]?.assetType){
          pageData['skip'] = 0;
          pageData['limit'] = null
        }
        return from(
          this.service.effectAssetByType(
            observablesMainData?.[0]?.assetType, 0, limit, null, additionalFInc ?? null, null,
          ).pipe(
            withLatestFrom(this.store.select(selectedAssetsTypeSelector)),
            switchMap((observablesData) => {  // returns both effects and selector data as array of values
              let isDataReceivedForSelAssetType = false;
              const sAssetType = observablesData?.[1];
              if (sAssetType && sAssetType === observablesMainData?.[0]?.assetType) {
                isDataReceivedForSelAssetType = true;
              }
              if (isDataReceivedForSelAssetType) {
                return [AssetsActions.loadAssetByTypeSuccess({assetType: observablesMainData?.[0]?.assetType, data: observablesData?.[0]}),
                  AssetsActions.loadAssetsFromStateInitially({skip:pageData?.['skip'],limit:pageData?.['limit']})
                  ];
              } else {
                return [AssetsActions.loadAssetByTypeSuccess({
                  assetType: observablesMainData?.[0]?.assetType,
                  data: observablesData?.[0]
                })];
              }
            })
          ));
        }
      ), catchError((error) => of(AssetsActions.failure({error: error})))
    )
  );
  loadAssetsByType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetsActions.loadAssetsByType),
      switchMap((action) => {
        const arr = [];
        const r: IRootAssetTypes[] = action.data;
        for (let i = 0; i < r.length; i++) {
          const assetType = r[i].type;
          const aCount = r[i].count;
          const config = r[i].configuration;
          const isArchived = r[i].archived ?? false;
          arr.push(AssetsActions.loadEachAssetByType({ assetType: assetType, limit: aCount, configuration: config, archived: isArchived }))
        }
        return arr
      })
    )
  );
  loadAssetsFromEffects$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetsActions.loadAssetsFromEffects),
      switchMap((action) => {
        return from(
          this.service.effectAssetByType(action.assetType, action.skip, action.limit)
        ).pipe(
          map((assets) => {
            return AssetsActions.loadAssetsFromEffectsSuccess({assetType: action.assetType, data: assets})
          })
        )
      })
    )
  )
  loadAllAssetsCount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetsActions.loadAllAssetsCount),
      switchMap(() => {
        return from(this.service.effectAllAssetCountByType()).pipe(
          switchMap((res: IRootAssetCountsByTypes) => {
            const r: IRootAssetTypes[] = res.count;
            const countHolder = {};
            for (let i = 0; i < r.length; i++) {
              const assetType = r[i].type;
              const aCount = r[i].count;
              const cLimit = CURRENT_LIMIT_PER_PAGE;
              countHolder[assetType] = { count: aCount, currentLimit: cLimit, archived: false };
            }
            return [AssetsActions.loadAssetCountByTypeSuccess({data: countHolder}),
              AssetsActions.loadAssetsConfigSuccess({data: res.count}),
              AssetsActions.loadAssetsByType({data: res.count})
            ]
          }),
          catchError((error) => of(AssetsActions.failure({error: error})))
        );
      })
    )
  );
  loadLPLinkedCities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetsActions.getLogisticProviderLinkedCities),
      mergeMap((action) => {
        return from(
          this.service.effectsAssetLinkedByAssetType(action.lpId, action.cityAssetType, action.isCount, action.skip, action.limit)
        ).pipe(map((res) => {
            return AssetsActions.loadLogisticProviderLinkedCities({
              data: res,
              moduleUniqueId: action.moduleUniqueId,
              id: action.id
            });
          }),
          catchError((error) => of(AssetsActions.failure({error: error})))
        );
      })
    )
  );

  loadAllAssignedCarriersData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetsActions.loadAllAssignedCarriersData),
      switchMap((action) => {
        return from(
          this.service.effectsAllAssignedCarriers(action.moduleName, action.status, false, action.skip, action.limit, action.startDate, action.endDate, action.fInclude, action.fExclude, action.carrierIds, action.orderPickUpCityId, action.orderPickUpStoreId)
        ).pipe(
          map((res) => {
            return AssetsActions.loadAllAssignedCarriersDataSuccess({data: res as IAllAssignedCarriersData[]})
          }),
          catchError((error: Error) => {
            return of(AssetsActions.failure({error: error}))
          })
        )
      })
    )
  )
  loadAllAssignedCarriersCount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetsActions.loadAllAssignedCarriersCount),
      switchMap((action) => {
        return from(
          this.service.effectsAllAssignedCarriers(action.moduleName, action.status, true, action.skip, action.limit, action.startDate, action.endDate, action.fInclude, action.fExclude, action.carrierIds, action.orderPickUpCityId, action.orderPickUpStoreId)
        ).pipe(
          map((res) => {
            const countHolder = {};
            countHolder['carriersCount'] = {count: (res as IAllDeliveriesCount).count, currentLimit: action.limit};
            return AssetsActions.loadAllAssignedCarriersCountSuccess({data: countHolder})
          }),
          catchError((error: Error) => {
            return of(AssetsActions.failure({error: error}))
          })
        )
      })
    )
  )
  //NEED to add checks on res before CALLING SWITCHMAP IN PIPE
  // getAllDeliveries$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(AssetsActions.getAllDeliveries),
  //     switchMap((action) =>
  //       from(
  //         this.service.effectsAllDeliveryAssignments(action.moduleName, action.status, true, action.skip, action.limit, action.startDate, action.endDate, action.fInclude, action.fExclude, action.carrierIds, action.orderPickUpCityId, action.orderPickUpStoreId, action.isLinked)
  //       ).pipe(
  //         switchMap((res) => {
  //     console.log('0000000000000000000000000');
  //           const countHolder = {};
  //           countHolder['deliveriesCount'] = {count: (res as IAllDeliveriesCount).count, currentLimit: action.limit};
  //           if (res['count'] === 0) {
  //             return [AssetsActions.getAllDeliveriesCountSuccess({data: countHolder}),
  //               AssetsActions.getAllDeliveriesDataSuccess({data: [] as IAllDeliveriesData[]})];
  //           }
  //           return [
  //             AssetsActions.getAllDeliveriesData({
  //               moduleName: action.moduleName,
  //               status: action.status,
  //               isCount: false,
  //               skip: action.skip,
  //               limit: action.limit,
  //               startDate: action.startDate,
  //               endDate: action.endDate,
  //               fInclude: action.fInclude,
  //               fExclude: action.fExclude,
  //               carrierIds: action.carrierIds,
  //               orderPickUpCityId: action.orderPickUpCityId,
  //               orderPickUpStoreId: action.orderPickUpStoreId,
  //               isLinked: action.isLinked
  //             }),
  //             AssetsActions.getAllDeliveriesCountSuccess({data: countHolder})
  //           ]
  //         })
  //       )
  //     )
  //   )
  // )

  // ...
  
  getAllDeliveriesData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetsActions.getAllDeliveriesData),
      mergeMap((action) => 
        from(this.service.effectsAllDeliveryAssignments(action?.params)).pipe(
          mergeMap((res: IAllDeliveriesData) => {
            const countHolder = {
              deliveriesCount: { count: res?.pagination?.count ?? 0, currentLimit: action?.params?.limit , skip : res?.pagination?.skip}
            };
            const countAction = AssetsActions.getAllDeliveriesCountSuccess({ data: countHolder });
            const dataAction = AssetsActions.getAllDeliveriesDataSuccess({ data: res?.data ?? [] as IAllDeliveryData[] });
            return concat(of(countAction), of(dataAction));
          }),
          catchError((error: Error) => {
            return of(AssetsActions.failure({error: error}))
          })
        )
      )
    )
  );
  

  // getAllDeliveriesCount$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(AssetsActions.getAllDeliveriesCount),
  //     switchMap((action) =>
  //       from(
  //         this.service.effectsAllDeliveryAssignments(action.moduleName, action.status, true, action.skip, action.limit, action.startDate, action.endDate, action.fInclude, action.fExclude, action.carrierIds, action.orderPickUpCityId, action.orderPickUpStoreId, action.isLinked, action.searchText, action.orderRoutePlanIds, action.sortOrder)
  //       ).pipe(
  //         switchMap((res) => {
  //           const countHolder = {};
  //           countHolder['deliveriesCount'] = {count: (res as IAllDeliveriesCount).count, currentLimit: action.limit};
  //           if (res['count'] === 0) {
  //             return [AssetsActions.getAllDeliveriesCountSuccess({data: countHolder}),
  //               AssetsActions.getAllDeliveriesDataSuccess({data: [] as IAllDeliveriesData[]})];
  //           } else {
  //             return [AssetsActions.getAllDeliveriesCountSuccess({data: countHolder})]
  //           }
  //         })
  //       )
  //     )
  //   )
  // )
  getTableSeenColumnData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetsActions.loadOrderTableHeaderData),
      switchMap((action) =>
        from(
          this.store.select(getAllSettingsForSelectedModuleFromSelector({moduleType:'o2d'}))
        ).pipe(
          map((res) => {
            // checking for customer collabration casl
            let status = this.ability.can(ruleActions.CUSTOMER_COLLABORATION,Subject.O2D_MANAGEMENT)
            return AssetsActions.loadOrderTableHeaderDataSuccess({id: action.id, fields: res?.table,isCustomerCollabration:status})
          })
        )
      )
    )
  );
  getKioskTableHeaderData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetsActions.getKioskTableHeaderData),
      switchMap((action) =>
        from(
          // this.store.select(getKioskSettings)
          this.store.select(getAllSettingsForSelectedModuleFromSelector({moduleType:'kiosk'}))
        ).pipe(
          map((res) => {
            console.log(res,"fromasset")
            return AssetsActions.getKioskTableHeaderDataSuccess({id: action.id, fields: res?.table})
          })
        )
      )
    )
  )
  loadAssetDefaultImage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetsActions.loadAssetDefaultImage),
      mergeMap((action) => {
        return from(
          this.service.effectGetAssetDefaultImgUrl(action.assetType)
        ).pipe(
          map((config) => {
            const configHolder = {};
            configHolder[action.assetType] = config
            return AssetsActions.loadAssetDefaultImageSuccess({assetType: action.assetType, data: configHolder})
          })
        )
      })
    )
  )
  putCategorySequence$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetsActions.putCategorySequence),
      switchMap((action) =>
        from(
          this.service.effectPutCategorySequence(action.payload)
        ).pipe(
          tap(() => {
            this.messageService.add({
              key: 'global-notification',
              severity: 'success',
              summary: 'Updated',
              detail: 'Successfully Updated ' + 'Category Sequence'
            });

          }),
          catchError(() => of(this.messageService.add({
            key: 'global-notification',
            severity: 'error',
            summary: 'Error',
            detail: 'Failed to update ' + 'Category Sequence'
          })))
          ,
          switchMap(() => {
              return [AssetsActions.loadAssetCountByType({assetType: CONSTANT.ASSET_TYPES.CATEGORY}),
              ]
            }
          )
        )
      )
    )
  )
  loadAssetLinkedGroupsCount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetsActions.loadAssetLinkedGroupsCount),
      switchMap((action) => {
        return from(
          this.service.effectGetGroupLinkedCountByAssetType(action.assetType)
        ).pipe(
          withLatestFrom(this.store.select(selectedAssetLinkedGroupsSelector({assetType: action.assetType}))),
          map((res) => {
            const latestCount = res?.[0].count;
            if (latestCount === 0) {
              const groupHolder = {};
              groupHolder[action.assetType] = []
              return AssetsActions.loadAssetLinkedGroupsSuccess({data: groupHolder})
            } else {
              if (res?.[1]?.length === latestCount) {
                return AssetsActions.loadState();
              } else {
                return AssetsActions.loadAssetLinkedGroups({skip: 0, limit: latestCount, assetType: action.assetType})
              }
            }

          })
        )
      }), catchError((error) => of(AssetsActions.failure({error: error})))
    )
  )
  loadAssetLinkedGroups$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetsActions.loadAssetLinkedGroups),
      switchMap((action) => {
        return from(
          this.service.effectGetGroupsLinkedByAssetType(action.skip, action.limit, action.assetType)
        ).pipe(
          map((groups: Group[]) => {
            const groupHolder = {};
            groupHolder[action.assetType] = groups
            return AssetsActions.loadAssetLinkedGroupsSuccess({data: groupHolder})
          })
        )
      }), catchError((error) => of(AssetsActions.failure({error: error})))
    )
  )
  getLinkedAssetsByAssetId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetsActions.getAssetsLinkedById),
      withLatestFrom(this.store.select(individualLinkedAssetsSelector)), // withLatestFrom added here
      switchMap(([action, linkedAssets]) => {
        const id = action?.['id'];
        const cachedAssets = linkedAssets?.[id];
        if (cachedAssets) {
          // If the linked assets for the given ID are available in the store, return them directly.
          return of(AssetsActions.getAssetsLinkedByIdSuccess({assetsList: cachedAssets, id: id}));
        } else {
          // Otherwise, fetch the linked assets from the service.
          return this.service.getLinkedAssets(action?.['id']).pipe(
            map((linkedAssets: unknown[]) => {
              return AssetsActions.getAssetsLinkedByIdSuccess({assetsList: linkedAssets, id});
            }),
            catchError((error: Error) => {
              return of(AssetsActions.failure({error: error}))
            })
          );
        }
      })
    )
  );
  loadRoutePlanCount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetsActions.loadRoutePlanCount),
      switchMap((action) => {
        return from(
          this.service.effectGetAllRoutePlan(action.moduleName, true)
        ).pipe(
          withLatestFrom(this.store.select(routePlanCountSelector)),
          switchMap((res) => {
            if (res?.[1] === res?.[0].count) {
              return [AssetsActions.loadRoutePlansCountSuccess({count: res?.[0].count})]
            } else {
              return [AssetsActions.loadRoutePlansCountSuccess({count: res?.[0].count}),
                AssetsActions.loadRoutePlans({
                  moduleName: action.moduleName,
                  isCount: action.isCount,
                  skip: 0,
                  limit: res?.[0].count,
                  fI: action.fI,
                  pickUpCityIds: action.pickUpCityIds,
                  pickUpLocation: action.pickUpLocation,
                  search: action.search
                })]
            }
          }), catchError(() => {
            return []
          })
        )
      })
    )
  );
  loadAllRoutePlans$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetsActions.loadRoutePlans),
      switchMap((action) =>
        from(
          this.service.effectGetAllRoutePlan(action.moduleName, false, action.skip, action.limit, action.fI, action.pickUpCityIds, action.pickUpLocation, action.search)
        ).pipe(
          map((res) => AssetsActions.loadRoutePlansSuccess({data: res as IRoutePlan[]})),
          catchError(() => {return [] })
        )
      )
    )
  )
  getGeoFenceByAssetId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetsActions.getGeoFenceByAssetId),
      switchMap((action) =>
        from(
          this.mainAssetsService.geoFenceByAssetId(action.assetId)
        ).pipe(
          map((res) => AssetsActions.geoFenceByAssetIdSuccess({geofences: res}))
        )
      )
    )
  )
  updateGeoFenceRadius$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetsActions.updateGeoFenceRaduis),
      switchMap((action) =>
        from(
          this.mainAssetsService.updateGeoFenceRadius(action.assetId, action.payload)
        ).pipe(
          switchMap(() => {
            this.messageService.add({
              key: 'global-notification',
              severity: 'success',
              summary: 'Updated',
              detail: 'Successfully Updated'
            });
            return [AssetsActions.geoFenceRaduisSuccess(), AssetsActions.getGeoFenceByAssetId({assetId: action.assetId})]
          }), catchError(() => {
            return []
          })
        )
      )
    )
  )
  loadO2DOrders$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetsActions.loadO2DOrders),
      switchMap((action) => from(
        this.service.effectO2DOrders(action.options)
      ).pipe(
        switchMap((data: IOrderAssets) => {
          return [AssetsActions.loadO2DOrdersSuccess({data, key: action.key})]
        }),
        catchError((error: Error) => {
          return of(AssetsActions.failure({error: error}))
        })
      ))
    ))

  createMultipleAssets$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetsActions.createMultipleAssets),
      switchMap(({ assetData, assetType }) => {
        return from(
          this.service.saveMultipleAssetsData(assetData, assetType)
      ).pipe(
        switchMap(() => {
          this.messageService.add({
            key: 'global-notification',
            severity: 'success',
            summary: 'Success',
            detail: 'Successfully Created...!'
          });
          return [AssetsActions.createMultipleAssetsActionStatus({ status: 200 + Math.random() })]
        }),
        catchError(() => {
          this.messageService.add({
            key: 'global-notification',
            severity: 'error',
            summary: 'Error',
            detail: 'Error Occured...!'
          });
          return of(AssetsActions.createMultipleAssetsActionStatus({ status: 400 + Math.random() }))
        })
        )
      })
    ))

    getAssetCardsImageDisplayStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetsActions.getAssetCardsImageDisplayStatus),
      switchMap(() => from(
        this.service.getAssetCardsImageDisplayStatusData()
      ).pipe(
        switchMap((data) => {
          return [AssetsActions.getAssetCardsImageDisplayStatusSuccess({data})]
        }),
        catchError((error: Error) => {
          return of(AssetsActions.failure({error: error}))
        })
      ))
      ))
  

  getSchedulesData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetsActions.getSchedulesData),
      switchMap((action) => {
        const serviceCall$ = action.key === 'today'
          ? this.service.getDailySchedulesData(action.startDate, action.endDate, action.queries)
          : this.service.getAllSchedulesData(action.skip, action.limit, action.isActive, action.queries);
  
        return from(serviceCall$).pipe(
          // Combine service call result with selector values
          switchMap((schedulesData) => 
            combineLatest([
              this.store.select(mmdCitiesSelector),
              this.store.select(locationsSelector),
              this.store.select(routePlansSelector),
              this.store.select(vehiclesSelector)
            ]).pipe(
              // Wait until all arrays have values
              filter(([cities, locations, routeplans, vehicles]) =>
                cities.length > 0 && locations.length > 0 && routeplans.length > 0 && vehicles.length > 0
              ),
              take(1), // Proceed once all conditions are met
              map(([cities, locations, routeplans, vehicles]) => ({
                schedulesData,
                cities,
                locations,
                routeplans,
                vehicles
              }))
            )
          ),
          switchMap(({ schedulesData, cities, locations, routeplans, vehicles }) => {
            const { data, pagination } = schedulesData;
            const processedData = this.scheduleService.getSchedulsDateByType(
              data, action.key, cities, locations, routeplans, vehicles
            );
  
            return of(
              AssetsActions.getSchedulesDataSuccess({
                schedules: { data: processedData, pagination },
                key: action.key,
                conflictKey: action.conflictKey,
                conflictDurationData:{
                  startDate:action.startDate,
                  endDate:action.endDate,
                  queries:action.queries
                }
              })
            );
          }),
          catchError((error: Error) => 
            of(AssetsActions.failure({ error }))
          )
        );
      })
    )
  );
  
  

    pauseConflictingSchedules$ = createEffect(() =>
      this.actions$.pipe(
        ofType(AssetsActions.pauseConflictingSchedules),
        withLatestFrom(this.store.select(selectConflictingSchedules)),
        switchMap(([action,data]) => {
          const scheduleIds = data.withCarrier.data.map(({_id})=>_id).join(',');
          const dates:any = {}
          if(action.startDate){
            dates.startDate = action.startDate
          }if(action.endDate){
            dates.endDate =action.endDate
          }
  
          return from(this.scheduleService.pauseSchedule(scheduleIds, dates, action.pauseUpcoming || false)).pipe(
            switchMap(() => {
              this.messageService.add({ key: 'global-notification', severity: 'success', summary: 'Success', detail: action.message });
              // const conflictDurationData = data['withCarrier_duration'];
              return [
                // action.isSkip ? 
                AssetsActions.getSchedulesDataSuccess
                ({
                  schedules:
                  {
                    data: [],
                  }
                  ,
                  key: 'today',
                  conflictKey: 'withCarrier'
                }) 
                // : AssetsActions.getSchedulesData({
                //   startDate: conflictDurationData.startDate,
                //   endDate: conflictDurationData.endDate,
                //   queries: conflictDurationData.queries,
                //   key: 'today',
                //   conflictKey: 'withCarrier'
                // })
              ]
            }),
            catchError((error: Error) => {
              this.messageService.add({ key: 'global-notification', severity: 'error', summary: 'Error', detail: 'Error Occured, Try Again' });
              return of(AssetsActions.failure({ error: error }))
            })
            );
        })
      ))
  

  constructor(
    private actions$: Actions,
    private service: AssetsService,
    private store: Store,
    private messageService: MessageService,
    private ability:Ability,
    private mainAssetsService: MainAssetsService,
    private scheduleService : ScheduleService) {
  }

}
