import { CommonModule } from '@angular/common';
import { Component, input } from '@angular/core';

@Component({
  selector: 'app-header-subheader-view-in-table',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './header-subheader-view-in-table.component.html',
  styleUrl: './header-subheader-view-in-table.component.scss'
})
export class HeaderSubheaderViewInTableComponent {

  header = input<string>('');
  subheader = input<string>('');
  color = input<string>('');
}
