import { CommonModule } from '@angular/common';
import { Component, effect, input, signal } from '@angular/core';

@Component({
  selector: 'app-relative-data-view-in-table',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './relative-data-view-in-table.component.html',
  styleUrl: './relative-data-view-in-table.component.scss'
})

export class RelativeDataViewInTableComponent {

  value = input<string>('');
  data = signal<Array<any>>([]);

  sessionData = sessionStorage.getItem('table_parent_data_colors')

  constructor() {
    effect(() => {

      this.value()?.length && this.data.set(this.extractData(this.value()))
    }, { allowSignalWrites: true });

  }

  colors = signal<Record<string, string>>({
    'intracity': '#023047',
    'intracity+cp': '#ffb703',
    'intercity': '#f19409',
    'cp': '#264653',
    'rm': '#577590',
    'defualt': '#386641'
  })


  extractData(input: string) {
    const sections = input.split(";");
    const output = sections.map(section => {
      const [key, values] = section.split(":");
      const children = values?.split(",").map(value => ({ label: value.trim() })) || [];

      const label = key.trim()

      return label ? {
        label,
        children,
        color: this.colors()[label.toLocaleLowerCase()] || this.colors()['defualt']
      } : null;
    }).filter(d=>!!d);
    return output;
  }
}
