import { createSelector } from "@ngrx/store";
import { AppState } from "../app.state";

const orderDocuments = (state: AppState) => state.orderDocuments

export const selectOrderDocuments = createSelector(orderDocuments,(od) => od?.documents)

export const selectOrderDocActionStatus = createSelector(orderDocuments,(od) => od?.uploadStatus)

export const selectStatusConfig= createSelector(orderDocuments,(od) => od?.statusConfig)
