import {createReducer, on} from "@ngrx/store";
import {LoginActions} from ".";

export interface LoginResponseState {
  token: '',
  roles: any,
  rolesInfo: any,
  account: Account,
  language: string,
}

export interface AccountLicenceIfoState {
  isAccountHasLicensing: boolean,
  accountLicenseExpiryAlert: boolean,
  accountLicenseExpiryDate: any;
  licenceExpiryMsg: string;
}

export interface Account {
  validity?: any;
  _id: string
  title: string
  name: string
  organization: string
  address: string
  assetTypes: string[]
  timezone: string
  language: string
  show: boolean
  updated: string
  created: string
  __v: number
  images: Images
  roles: string[]
  label?: string
  roleId: string

  [key: string]: unknown
}

export interface Images {
  logo: Logo
}

export interface Logo {
  data: string
  contentType: string
  razorpayDisplayUrl?: string
}

export interface IKioskSubscriptionData {
  message: string,
  status: string,
  qrCodeImageUrl: string,
  amount:number,
  isPaymentDue:boolean
}

export interface LoginState {
  status: string,
  error: any,
  resetPasswordError: string;
  token: string,
  res: any,
  isUserAuthenticated: boolean,
  accountLicenceInfoState: AccountLicenceIfoState,
  showSessionLogoutMsg: string,
  showToast: string
  account: Account,
  iotZenServerVersion:string,
  kioskSubscriptionData:IKioskSubscriptionData;
  isBannerVisibleForNoInternet:boolean;
  bannerDataForPrinterError:{status:string,message:string},
  resetPasswordLinkMsg: string
}

export const initialState:LoginState = {
    status:'Pending',
    error:null,
    resetPasswordError:null,
    token:'',
    res:null,
    isUserAuthenticated:false,
    accountLicenceInfoState:  {
        isAccountHasLicensing: false,
        accountLicenseExpiryAlert: false,
        accountLicenseExpiryDate: '',
        licenceExpiryMsg: ''
    },
    showSessionLogoutMsg : '',
    showToast : '',
    account: null,
    iotZenServerVersion:'',
    kioskSubscriptionData:null,
    isBannerVisibleForNoInternet:false,
    bannerDataForPrinterError:{status:null,message:null},
    resetPasswordLinkMsg: null
}

export const LoginReducer = createReducer(
  initialState,
  on(LoginActions.loginSuccess, (state, response) => {
    let accHasLicensing = false;
    let licenseInfo: any = null;
    let licenceExpiryMsg: any = ''
    const accountDetails: Account = response?.response?.['account'] ?? null;
    let accountLicenseExpiryAlert = false;
    if (response.response['accountLicenseInfo']) {
      licenseInfo = response.response['accountLicenseInfo'];
      if (licenseInfo && licenseInfo.isAccountHasLicensing) {
        accHasLicensing = licenseInfo.isAccountHasLicensing;
        licenceExpiryMsg = licenseInfo.message;
        accountLicenseExpiryAlert = !!licenseInfo.accountLicenseExpiryAlert
      }
    }

    const accountLicenseInfoObj: AccountLicenceIfoState = {
      isAccountHasLicensing: accHasLicensing,
      accountLicenseExpiryAlert: (licenseInfo && accHasLicensing && accountLicenseExpiryAlert),
      accountLicenseExpiryDate: licenseInfo.accountLicenseExpiryDate ? licenseInfo.accountLicenseExpiryDate : '',
      licenceExpiryMsg: licenceExpiryMsg ? licenceExpiryMsg : ''
    }

    return {
      ...state,
      status: 'Success',
      error: '',
      res: response.response,
      accountLicenceInfoState: accountLicenseInfoObj,
      account: accountDetails
    }
  }),

  on(LoginActions.loginFailure, (state, error) => {
    return {
      ...state,
      error: error.err,
      status: 'Error'
    }
  }),
  on(LoginActions.setToken, (state, {token}) => {
    return {
      ...state,
      token: token
    }
  }),
  on(LoginActions.setAccount, (state, {accountDetails}) => {
    return {
      ...state,
      account: accountDetails
    }
  }),
  on(LoginActions.userLoggedOff, (state) => {

    return {
      ...state,
      token: '',
      isUserAuthenticated: false,
      isAccountHasLicensing: false,
      accountLicenseExpiryAlert: false,
      accountLicenseExpiryDate: null
    }
  }),
  on(LoginActions.userAuthenticated, (state) => {

    return {
      ...state,
      isUserAuthenticated: true
    }
  }),
  on(LoginActions.setAccountLicenceAlertInfo, (state, {accountLicenceInfoState}) => {
    return {
      ...state,
      accountLicenceInfoState: accountLicenceInfoState
    }

    }),
    on(LoginActions.resetPreviousLoginResult,(state) => {
        return {
            ...state,
            res:null
        }
    }),
    on(LoginActions.getSessionTimeoutTimeSuccess,(state) =>{
        return {
            ...state,
            showSessionLogoutMsg : state.showSessionLogoutMsg,
            showToast : sessionStorage.getItem('toastMsg')
        }
    }),

    on(LoginActions.OnSuccessGetiotZenServerVersion, (state, {res}) => {
        return {
          ...state,
          iotZenServerVersion: res?.version
        };
      }),
    on(LoginActions.OnSuccessCheckKioskSubscription, (state, {response}) => {
      return {
        ...state,kioskSubscriptionData :{...(state.kioskSubscriptionData || {}),...response}
      };
    }),

    on(LoginActions.isBannerVisibleForInternetConfig,(state,{value})=>{
      return{
        ...state,
        isBannerVisibleForNoInternet:value
      }
    }),

    on(LoginActions.manageBannerVisibleForPrinterError,(state,{data})=>{
      return{
        ...state,
        bannerDataForPrinterError:data
      }
    }),

    on(LoginActions.resetPasswordLinkSuccess,(state,{response})=>{
      return{
        ...state,
        resetPasswordLinkMsg: response?.msg
      }
    }),

    on(LoginActions.resetPasswordLinkFailure,(state,{error})=>{
      return{
        ...state,
        resetPasswordError: error?.error?.msg
      }
    })
)
