import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, from, interval } from 'rxjs';
import { switchMap, map, catchError, tap } from 'rxjs/operators';
import { LoginActions } from '.';
import { ApiService } from '../../services/api.service';
import { MessageService } from 'primeng/api';
import { Subject } from '../../auth/rules';
import { getAssetCardsImageDisplayStatus } from '../Assets/assets.action';

@Injectable()
export class LoginEffects {
  constructor(
    private actions$: Actions,
    private API: ApiService,
    private messageService: MessageService
  ) {}

  // Run this code when a validateLogin action is dispatched
  validateLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoginActions.validateLogin),
      switchMap((action) => {
        // Call the stores method, convert it to an observable
       return from(this.API.verifyUserDetails({username:action.username,password:action.password,language:action.language})).pipe(
          // Take the returned value and return a new success action containing the store list
          switchMap((res) => {
            let isKioskModule:boolean
              res?.['roleInfo']?.ability?.forEach(role=>{
              if(role.includes(Subject.KIOSK_MANAGEMENT)){
                isKioskModule=true
                return;
              }
            })
            if(isKioskModule){
              return [LoginActions.loginSuccess({response:res}), LoginActions.userAuthenticated(),LoginActions.getiotZenServerVersion(),getAssetCardsImageDisplayStatus(),LoginActions.checkKioskSubscription()]
            }else{
              return [LoginActions.loginSuccess({response:res}), LoginActions.userAuthenticated(),LoginActions.getiotZenServerVersion(),getAssetCardsImageDisplayStatus()]
            }

          }),
          // Or... if it errors return a new failure action containing the error
          catchError((error) => of(LoginActions.loginFailure({ err:error })))
        )
}
      )
    )
  ); 

  getSessionTimeoutTime$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoginActions.getSessionTimeoutTime),
      switchMap(() => {
        const numbers = interval(60000);
        return numbers.pipe(
          tap(() => {
            sessionStorage.setItem('jwtExpiryTime', JSON.stringify(JSON.parse(sessionStorage.getItem('jwtExpiryTime')) - 1))
            if ((JSON.parse(sessionStorage.getItem('jwtExpiryTime')) == 10)) {
              sessionStorage.setItem('toastMsg', 'Your session will log out within next 10 minutes');
              this.messageService.add({ key: 'global-notification', severity: 'warn', summary: 'Warn', detail: 'Your session will log out within next 10 minutes' });
            }
          }),
          map(x => LoginActions.getSessionTimeoutTimeSuccess({ x }))
        );
      })
    )
  );

  getiotZenServerVersion$ = createEffect(() =>
  this.actions$.pipe(
    ofType(LoginActions.getiotZenServerVersion),
    switchMap(() => {
     return from(this.API.getiotZenServerVersion()).pipe(
        map((res:any) => LoginActions.OnSuccessGetiotZenServerVersion({res})),
        catchError((error:string) => of(LoginActions.OnFailureGetiotZenServerVersion({ error })))
      )
   }
    )
  )
);

    checkKioskSubscription$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoginActions.checkKioskSubscription),
      switchMap(() => {
       return from(this.API.checkKioskSubscription()).pipe(
          map((response) => LoginActions.OnSuccessCheckKioskSubscription({response})),
          catchError((error) => of(error))
        )
     })
    ));

    getResetPasswordLink$ = createEffect(() =>
      this.actions$.pipe(
        ofType(LoginActions.getResetPasswordLink),
        switchMap((action) => {
         return from(this.API.getResetPasswordLink({username: action.username})).pipe(
            map((response) => LoginActions.resetPasswordLinkSuccess({response})),
            catchError((error:string) => of(LoginActions.resetPasswordLinkFailure({ error })))
          )
       })
      ));

}