import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http:HttpClient,private configService:ConfigService) { }

  verifyUserDetails(userDetails) {
    return this.http.post(this.configService.appConfig.loginBaseURL + '/authentication/login', userDetails);
  }

  getUserProfile() {
    return this.http.get(this.configService.appConfig.appBaseUrl + 'user/self/profile');
  }

  getUserLanguage() {
    return this.http.get(this.configService.appConfig.appBaseUrl + 'user/self/language');
  }

  getAllLanguages() {
    return this.http.get(this.configService.appConfig.appBaseUrl + 'languageMeta');
  }

  setNewPassword(userId,newPassword) {
    let URL = this.configService.appConfig.appBaseUrl;
    let payload = { ...newPassword };
    if (userId) {
      payload = { ...newPassword, userId };
      URL += "user/other/profile/password";
    } else {
      URL += "user/self/profile/password";
    }
    return this.http.put(URL, payload);
  }

  updateUserDetails(userDetails) {
    return this.http.put(this.configService.appConfig.appBaseUrl + 'user/self/profile', userDetails);
  }

  getiotZenServerVersion() {
    return this.http.get(this.configService.appConfig.appBaseUrl + 'release/version');
  }

  getKioskSubQrCode(){
    return this.http.get(`${this.configService.appConfig.appBaseUrl}sokSubscription/qrCodePayment`)
  }

  checkKioskSubscription() {
    return this.http.get(`${this.configService.appConfig.appBaseUrl}sokSubscription/status`);
  }

  handleGenericEventHandlerForSettings(url){
    return this.http.get(`${this.configService.appConfig.appBaseUrl}${url}`);
  }

  getResetPasswordLink(username){
    return this.http.post(this.configService.appConfig.appBaseUrl + 'user/self/profile/resetPassword', username);
  }

  resetPassword(token, newPassword){
    return this.http.put(this.configService.appConfig.appBaseUrl + 'user/self/profile/password?token=' + token, {newPassword} );
  }

}
