<div class="grid m-0 mx-0 full-width justify-content-end">
  <!-- main wrapper for left and right sections of the page -->
  <div
    class="wrap-login100 main-wrapper flex justify-content-baseline align-items-stretch"
  >
    <section class="left-content">
      <div class="logo-container">
        <!-- Iotzen updted logo -->
        <img [ngSrc]="iotZenLogoLight" alt="zen-logo" class="zen-logo" height="35" width="100"/>
      </div>
      <div class="bullet-points">
        <ul>
          @for (item of bulletPointsArray; track item) {
            <li class="flex align-items-center mb-5">
              <img alt="bullet-points" height="24" ngSrc="assets/bullet_points_icon.svg" width="24"/>
              <span class="bullet-point-text m-0">{{ item }}</span>
            </li>
          }
        </ul>
      </div>
      <div class="ZD-main-logo">
        <img [ngSrc]="ZDMainLogo" alt="main-logo" height="50" width="10"/><span
        style="display: inline-block; padding-left: 1rem"
      >ZenDynamix</span
      >
      </div>
      <div class="powerByZen">
        <a href="https://www.zendynamix.com/" pRipple target="_blank">© Copyrights - ZenDynamix Software Pvt Ltd.</a>
      </div>
    </section>

    <!-- login section -->
    <section class="right-content login-section center">
      <div class="logo-container-right flex justify-content-center">
        <img
          id="ZenLogoDark"
          class="right-logo zen-logo-dark"
          [ngSrc]="iotZenLogoDark"
          height="34" width="200"
          alt="logo"/>
      </div>

      <h2 class="sign-in">{{'Change your password' | translate}}</h2>

      <div class="form-container flex justify-content-center">
        <form class="form-grp" [formGroup]="resetForm">
          <div class="validate-input mb-2 field"> 
            <span class="px-2"><i class="pi pi-id-card"></i></span>
            <label for="password">{{'New password' | translate}}</label>
          <input 
            type="password" 
            id="password"
            formControlName="password"
            class="text-base text-color surface-overlay p-3 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
            >
            @if (resetForm.get('password').errors?.required && resetForm.get('password').touched) {
              <div class="errorMsg">
                {{'Password is required' | translate}}
              </div>
            }
            @if (resetForm.get('password').errors?.minlength && resetForm.get('password').touched) {
              <div class="errorMsg">{{ 'Minimum of 6 characters required' | translate }}</div>
            }
          </div>

          <div class="validate-input field">
            <span class="px-2"><i class="pi pi-id-card"></i></span>
            <label class="login-label">{{'Confirm new password' | translate}}</label>
            <span class="btn-show-pass" (click)="togglePassword()">
              @if (typeOfPwd === 'text') {
                <i class="pi pi-eye-slash"></i>
              }
            @if (typeOfPwd === 'password') {
              <i class="pi pi-eye"></i>
            }
            </span>
            <input 
              type="{{typeOfPwd}}" 
              id="confirmPassword"
              formControlName="confirmPassword"
              class="text-base text-color surface-overlay p-3 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
              />
              @if (resetForm.get('confirmPassword').errors?.required && resetForm.get('confirmPassword').touched) {
                <div class="errorMsg">
                  {{'Confirm password is required' | translate}}
                </div>
              }
              @if (resetForm.get('confirmPassword').errors?.mismatch && resetForm.get('confirmPassword').touched) {
                <div class="errorMsg">
                  {{'Passwords do not match' | translate}}
                </div>
              }
          </div>

          <button 
      [disabled]="!resetForm.valid" (click)="onSubmit()" label="{{'Change your password' | translate}}"
      class="p-button-lg full-width mt-2" pButton pRipple
      type="button"></button>
        </form>
      </div>

      <div class="ZD-main-logo zd-main-right">
        <img [ngSrc]="ZDMainLogo" alt="" height="35" width="24"/><span
        style="display: inline-block; padding-left: 1rem"
      >ZenDynamix</span>
      </div>
      <div class="powerByZen">
        <a href="https://www.zendynamix.com/" pRipple target="_blank">© Copyrights - ZenDynamix Software Pvt Ltd.</a>
      </div>
    </section>

  </div>

</div>