import {createReducer, on} from '@ngrx/store';
import {SocketActions} from '.';
import {IAssetStatusSocketData} from './interfaces/custome.interfaces';


export interface SocketState {
  gps: {
    res: any,
    err: any
  },
  lmdLiveStatus: {
    res: any,
    err: any,
  },
  socketData: IAssetStatusSocketData[],
  connectedNameSpaces:Array<string>,
  error?: Error
}

export const initialState: SocketState = {
  gps: {
    res: null,
    err: null,
  },
  lmdLiveStatus: {
    res: null,
    err: null,
  },
  socketData: [],
  error: undefined,
  connectedNameSpaces:[]
};

export const SocketReducer = createReducer(
  initialState,
  on(SocketActions.gpsDataSuccess, (state, {gps}) => {
    return {
      ...state,
      gps: {
        err: '',
        res: gps
      }
    };
  }),
  on(SocketActions.failure, (state, {error}) => {
    return {
      ...state,
      error: error
    };
  }),
  on(SocketActions.lmdDeviceLiveStatusSuccess, (state, {liveStatus}) => {
    return {
      ...state,
      lmdLiveStatus: {
        err: null,
        res: liveStatus
      }
    };
  }),
  on(SocketActions.assetStatusChange, (state, {res}) => {
    return {
      ...state,
      socketData: [...state.socketData, res]
    };
  }),
  on(SocketActions.updateSocketDataRequired, (state, {nameSpace}) => {
    return {
      ...state,
      connectedNameSpaces:[...(state.connectedNameSpaces || []),nameSpace]
    };
  })
);
